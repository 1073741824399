import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { environment } from '../../environments/environment';
import {v4 as uuidv4} from 'uuid';
import { MobileDataService } from '../service/mobile-data.service';

const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExportService {
  
  constructor(
    private mobileDataService: MobileDataService,
  ) { 
    
    }
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    // console.log('worksheet', worksheet);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ['data']
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(
      data,
      fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
    );
  }
  public exportToPDF(elementId): void {
    // console.log('ElementID: ', elementId)

    if (elementId !== '') {
      var element = document.getElementById(elementId)
      html2canvas(element).then((canvas) => {
        var imgData = canvas.toDataURL('image/jpeg', 1.0)
        var fileName = 'INQUEST Report - ' + new Date().getTime() + '.pdf'
        // Set the canvas size and position the image [Denver Naidoo - 21 Jan 2022]
        var doc = new jsPDF('p','mm')
        var imgWidth = 208
        var pageHeight = 295
        var imgHeight = canvas.height * imgWidth / canvas.width
        var heightLeft = imgHeight
        var positionY = 0
        var positionX = 10
        var ySpacer = 0
        doc.addImage(imgData, 'PNG', positionX, positionY, imgWidth, imgHeight)
        heightLeft -= pageHeight
        while (heightLeft >= 0) {
          positionY = (heightLeft - imgHeight) + ySpacer
          doc.addPage();
          doc.addImage(imgData, 'PNG', positionX, positionY, imgWidth, imgHeight)
          heightLeft -= pageHeight
        }
        doc.save(fileName)
      })
    }
  }
  //------------------------------------------------------------------------------------
  // Marwyk - Print the detailed view to PDF - pk = pk of testChain/testSuite, sk = sk of testChain/testSuite
  //------------------------------------------------------------------------------------
  public exportToPDF_New(pk, sk): void {

    try
    {
      if(!environment.production)
      {
        console.log('starting signed url get')
      }
      //----------------------------------------------------
      //get signed URL from fargo here
      let signedUrl;

      //create fake "<a>" with signed url as src
      var a = document.createElement("a");
      a.href = signedUrl;
      //trigger auto download of signed url
      a.download = 'INQUEST Report - ' + new Date().getTime() + '_' + uuidv4() + '.html';
      a.click();

      //----------------------------------------------------
      if(!environment.production)
      {
        console.log('end signed url get')
      }
    }
    catch(exception)
    {
      if(!environment.production)
      {
        console.log('#EXCEPTION::ExportService.Service::exportToPDF()::getSignedURL_Section', exception)
      }
    }

    return;
  }

}
