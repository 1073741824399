declare var require: any

import { Component, OnInit, OnDestroy, OnChanges, ViewEncapsulation, SimpleChanges, HostListener, ViewChild } from '@angular/core';
import { MenuItems } from '../core/menu/menu-items/menu-items';
import { HorizontalMenuItems } from '../core/menu/menu-items/horizontal-menu-items';
import { PageTitleService } from '../core/page-title/page-title.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MediaObserver } from "@angular/flex-layout";
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { SessionService } from '../service/session-service.service';
import { MasterService } from '../service/master-service.service';
import { environment } from '../../environments/environment'
import { ExportService } from '../service/export-service.service';
import { IMySQLError, IReturnCode, Ipr_AddUser, Ipr_AddSession } from '../interface/i-mobile-test';
import { MobileDataService } from '../service/mobile-data.service';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { IAppStore, IEventHandler, ISessionLite, ISessionLive } from '../interface/i-responses';
import { ISessionLogin } from '../interface/i-dynamo';
import { IActiveTestRun } from '../interface/i-application';
// const screenfull = require('screenfull');
import {v4 as uuidv4} from 'uuid';
import { MatSidenavContainer } from '@angular/material/sidenav';

@Component({
    selector: 'inquest-layout',
  	templateUrl:'./main-material.html',
  	styleUrls: ['./main-material.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MainComponent implements OnInit, OnDestroy, OnChanges{

    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';  
    
    private _router: Subscription;
    private _routerEventsSubscription: Subscription;
    private _mediaSubscription: Subscription;

    iMySQLError: IMySQLError[];
    errorMessage: string;
    validationMessage: string = '';
    userId: number = -1;

    header: string = 'INQUEST - Enterprise Test Automation';
    currentLang = 'en';
    url: string;
    showSettings = false;
    dark: boolean;
    boxed: boolean;
    horizontalMenu : boolean = true;
    compactSidebar: boolean;
    customizerIn: boolean = false;
    headerGreen: boolean = false;
    headerRed: boolean = false;
    headerYellow: boolean = false;
    root = 'ltr';
    myroot = 'ltr';
    collapsedClass: any = 'side-panel-closed';
    chatpanelOpen: boolean = false;
    // Set the Default Theme - [Denver Naidoo 17 Oct 2019]
    themeHeaderSkinColor: any = "header-default"; 
    themeSidebarSkinColor: any = "sidebar-blue";

    //Breakpoint Cusomisations -----------------------------------------------------------------------------------------/
    //DEVELOPMENT authenticatorLink -----------------------------------------------------------------------------------------/
    authenticatorLink: string = 'https://inquest.auth.ap-southeast-1.amazoncognito.com/login?response_type=token&client_id=1uk4qb4h4792qp6pv1jc4npl99&redirect_uri=http://localhost:4200/dashboard';
   
    authenticator: string;
    showConfigLink: boolean = false;

    // themeHeaderSkinColor: any = "header-red";
    // themeSidebarSkinColor: any = "sidebar-red";
    collapseSidebar: boolean = false;
    isFullscreen: boolean = true;

    //session variables
    loginDisplay: string = 'Login';
    nickName: string = '';
    email: string = '';
    isLoggedIn: boolean = false;
    sessionIsSideMenu: string = 'rootDiv';
    menuSeperator = ' | '

    sidenavOpen: boolean = false;
    sidenavMode: string = 'side';
    isMobile: boolean = false;
    iSessionLive: ISessionLive;

    // TODO: Sidenav Redo
    // [ ] Reorganise Functions
    // [ ] Remove Old Variables
    // [ ] Test Responsivness at ALL breakpoints
    // [x] Only allow navigation on login
    
    // Updated Varaibles
    isMenuOpen: boolean = false;

    inquestTitle: string = 'inquest';
    inquestSubtitle: string = 'enterprise test automation';
    inquestPageHeader: string = 'Loading...';
    
    public innerWidth: any;
 
    // Update on Resize
    @HostListener('window:resize', ['$event'])
    onResize(event) {
    this.innerWidth = window.innerWidth;
    }

    toggleIsMenuOpen(): void {
        this.isMenuOpen = !this.isMenuOpen;
    }

   public config: PerfectScrollbarConfigInterface = {};
	constructor(
        public menuItems: MenuItems, 
        public horizontalMenuItems: HorizontalMenuItems, 
        private pageTitleService: PageTitleService, 
        public translate: TranslateService, 
        private router: Router, 
        private media: MediaObserver,
        private sessionService: SessionService,
        private masterService: MasterService,
        private exportService: ExportService,
        private mobileDataService: MobileDataService,
        private _snackBar: MatSnackBar,
        private activatedRoute: ActivatedRoute
        ) {
        const browserLang: string = translate.getBrowserLang();
        translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');

    }
    
    //---------------------------------------------------------------------------------------------
    // Core Messaging [Denver Naidoo - 30 Sep 2021]
    //---------------------------------------------------------------------------------------------
    iEventHandler: IEventHandler;
    getEventHandler() {
        try {
        this.pageTitleService.iEventHandler.subscribe((iEventHandler: IEventHandler) => {
            if(!environment.production){console.log({...iEventHandler});}            
            this.iEventHandler = iEventHandler;
            if (iEventHandler) {
            if (iEventHandler.Event) {
                let events = iEventHandler.Event.split(',')
                for (let action in events)  {
                    if (events[action] === 'GENERAL_NOTIFICATION') 
                    {
                        this.openSnackBar(iEventHandler.Payload);
                    }
                    //---------------------------------------------------------------------------------------------------------
                    //Marwyk - export websocket payload to dev console
                    //---------------------------------------------------------------------------------------------------------
                    if(!environment.production)
                    {
                        console.log('#WEBSOCKET::Payload received::' + events[action], [...events]);
                    }

                    //---------------------------------------------------------------------------------------------------------
                    //Marwyk - handle websocket events when receiving S3 signed urls
                    //---------------------------------------------------------------------------------------------------------
                    if (events[action].split('#')[0] === 'SIGNED_URL_RECEIVED') 
                    {
                        if (events[action].split('#')[1] === 'DOWNLOAD')
                        {
                            //this.openSnackBar('Report download ready : ' + iEventHandler.Payload);
                            //create fake "<a>" with signed url as src
                            var a = document.createElement("a");
                            a.href = iEventHandler.Payload;
                            a.target = "_blank";
                            //trigger auto download of signed url
                            a.download = 'INQUEST Report - ' + new Date().getTime() + '_' + uuidv4() + '.pdf';
                            a.click();
                        }
                        if (events[action].split('#')[1] === 'UPLOAD')
                        {
                            if(!environment.production)
                            {
                                this.openSnackBar('File Upload Complete : ' + iEventHandler.Payload);
                            }
                        }
                    }
                    //---------------------------------------------------------------------------------------------------------
                    //Marwyk - handle websocket events when receiving async status updates
                    //---------------------------------------------------------------------------------------------------------
                    if (events[action].split('#')[0] === 'ASYNC_STATUS_UPDATE') 
                    {
                        if (events[action].split('#')[1] === 'PROGRESS')
                        {
                            if(!environment.production)
                            {
                                    this.openSnackBar('Async Progress Update : ' + iEventHandler.Payload);
                            }
                        }
                        if (events[action].split('#')[1] === 'COMPLETED')
                        {
                            if(!environment.production)
                            {
                                this.openSnackBar('Async Operation Complete : ' + iEventHandler.Payload);
                            }
                        }
                    }

                    //---------------------------------------------------------------------------------------------------------
                    //Marwyk - handle websocket events when receiving async delete messages
                    //---------------------------------------------------------------------------------------------------------
                    if (events[action].split('#')[0] === 'ASYNC_STATUS_TESTCASE_DELETE') 
                    {
                        if(!environment.production)
                        {
                            console.log("WEBSOCKET STATUS :: TESTCASE DELETE :: ");
                            console.log(events);
                        }
                    }

                    //---------------------------------------------------------------------------------------------------------
                    //Marwyk - handle websocket events when receiving async messages for SIMPOOLS
                    //---------------------------------------------------------------------------------------------------------
                    if (events[action].split('#')[0] === 'ASYNC_STATUS_SIMPOOL') 
                    {
                        if(!environment.production)
                        {
                            console.log("WEBSOCKET STATUS :: SIM Pool Event :: ");
                            console.log(events);
                        }
                    }
                }  
            }
            }
        });      
        }
        catch (exception) {
        if (!environment.production) {
            console.log('#EXCEPTION::TestRunResultsComponent::getEventHandler ', exception);
            }
        }
    }

    //---------------------------------------------------------------------------------------------
    // Core Messaging [Denver Naidoo - 30 Sep 2021]
    //---------------------------------------------------------------------------------------------
    openSnackBar(snackMessage, snackAction='Dismiss...', delay=5000) {
        //# Suppress popups for the Dashboards [Denver Naidoo - 15 Jan 2022 18:29]

        if (snackMessage)
        this._snackBar.open(snackMessage, snackAction, {
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
          duration: delay
        });
      }

    //---------------------------------------------------------------------------------------------
    // Initialise the AppStore [Denver Naidoo - 15 Dec 2021]
    //---------------------------------------------------------------------------------------------
    iAppStore: IAppStore = {}
    initAppStore(){
    try {
        let iActiveTestRun: IActiveTestRun = {
        IsActiveTestRun: false,
        ShowMasterTab: false,
        ShowHistoricResults: false
        }
        let iAppStore: IAppStore = this.iAppStore?this.iAppStore:{};
        iAppStore.iActiveTestRun = iActiveTestRun;
        iAppStore.ShowRealtimeResults = false;
        iAppStore.CumulativeTokens = '';
        this.pageTitleService.setAppStore(iAppStore);
        }
        catch (exception) {
            if (!environment.production) {
            console.log('#EXCEPTION::TestCaseComponent::initAppStore() ', exception);
            }
        }
    }

    //---------------------------------------------------------------------------------------------
    // Logout [Denver Naidoo 28 Sep 2021]
    //---------------------------------------------------------------------------------------------
    logOff() {
        try {
            this.mobileDataService.pr_LogOff().subscribe({
                next: (responseData) => 
                  {
                  }
              });            
            sessionStorage.clear();
            this.openSnackBar('Successfully Logged off', 'Dismiss...');
            // Log off the Session Live [Denver Naidoo - 9 Dec 2021]
            let iSessionLive: ISessionLive
            this.pageTitleService.setSessionLive(iSessionLive);
            this.sessionService.setIsLoggedIn(false);
            let iAppStore: IAppStore = {};
            this.pageTitleService.setAppStore(iAppStore);
            this.router.navigateByUrl('/landing-page');

            // Close Menu on Logoff
            this.isMenuOpen = false;
        }
        catch (exception) {
            if(!environment.production){
              console.log('#EXCEPTION::main::logOff() => ', exception);
            }
          }
    }

    //-----------------------------------------------------------------------------------------
    // Marwyk - Implement OnChanges Lifecycle hook to prevent NG100 error message
    //-----------------------------------------------------------------------------------------
    //Liam - onChanges was not getting called since iSessionLive is not an @Input() and as suc doesnt trigger change detection
    ngOnChanges(changes: SimpleChanges): void {
        // this.pageTitleService.title.subscribe((val: string) => {setTimeout(()=>this.header = val);});
        // this.pageTitleService.sessionLive.subscribe((iSessionLive: ISessionLive) => {this.iSessionLive = iSessionLive;});
        console.log('menu',this.horizontalMenuItems.getAll(this.isLoggedIn));
    }

    //---------------------------------------------------------------------------------------------
    // ngOninit [Denver Naidoo - 18 Dec 2021]
    //---------------------------------------------------------------------------------------------
    ngOnInit() {
    try {
        // Liam - Responsive Design Overhaul
        this.innerWidth = window.innerWidth;

        //-----------------------------------------------------------------------------------------
        //Marwyk - these two lines should be moved to ngOnChange() to prevent NG100: ExpressionChangedAfterItHasBeenCheckedError
        this.pageTitleService.title.subscribe((val: string) => {setTimeout(()=>this.inquestPageHeader = val);});
        this.pageTitleService.sessionLive.subscribe((iSessionLive: ISessionLive) => {setTimeout(()=>this.iSessionLive = iSessionLive);});
        //-----------------------------------------------------------------------------------------
        this.getEventHandler();
        // make decisions based on PRODUCTION or DEV Environment
        if (environment.production) {
            //PRODUCTION authenticatorLink -----------------------------------------------------------------------------------------/
            this.authenticatorLink = 'https://breakpoint-group.auth.ap-southeast-1.amazoncognito.com/login?response_type=token&client_id=3uv6f6t5lnrkidbi5p6gt0andu&redirect_uri=https://breakpoint-group.io/dashboard';
            this.showConfigLink = false;
        } 

        this.setupSession();
        
         this._router = this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
         ).subscribe((event: NavigationEnd) => {
            this.url = event.url;
         });
        
        if (this.url != '/session/login' && this.url != '/session/register' && this.url != '/session/forgot-password' && this.url != '/session/lockscreen') {
            const elemSidebar = <HTMLElement>document.querySelector('.sidebar-container');
           
            // if (window.matchMedia(`(min-width: 960px)`).matches) {
            //     // Ps.initialize(elemSidebar, { wheelSpeed: 2, suppressScrollX: true });
            //     const ps = new PerfectScrollbar(elemSidebar, {
            //                   wheelSpeed: 2,
            //                   suppressScrollX: true
            //                 });
            // }
        }
        // if (this.media.isActive('xs') || this.media.isActive('sm')){
        //     this.sidenavMode = 'over';
        //     this.sidenavOpen = false;
       
        // }
        // this._mediaSubscription = this.media.media$.subscribe((change: MediaChange) => {
        //     let isMobile = (change.mqAlias == 'xs') || (change.mqAlias == 'sm');

        //     this.isMobile = isMobile;
        //     this.sidenavMode = (isMobile) ? 'over' : 'side';
        //     this.sidenavOpen = false;
        // });

        // this._routerEventsSubscription = this.router.events.subscribe((event) => {
        //   if (event instanceof NavigationEnd && this.isMobile) {
        //     this.sidenav.close();
        //   }
        // });

        //Log the User t o the Database
        this.addUser(
            sessionStorage.getItem('WingNut'),
            sessionStorage.getItem('Bilbo'),
            sessionStorage.getItem('Aragorn'),
            sessionStorage.getItem('Baggins'),
            sessionStorage.getItem('Saruman'),
            sessionStorage.getItem('Pippin')
        );

        //Log the Session
        this.addSession(sessionStorage.getItem('Tolkien'));        
        this.initAppStore();
        //setup connection id
        //this.mobileDataService.getCurrentWebSocketConnectionId(true);
    }
    catch (exception) {
        if(!environment.production){console.log('#EXCEPTION::main::ngOnInit() => ', exception);}}
    }

    //---------------------------------------------------------------------------------------------
    // Setup Session [Denver Naidoo - 18 Dec 2021]
    //---------------------------------------------------------------------------------------------
    setupSession() {
        this.sessionService.topicNickName.subscribe((val: string) => {
            setTimeout(()=>this.nickName = val);
        });

        this.sessionService.topicEmail.subscribe((val: string) => {
            setTimeout(()=>this.email = val);
        });

        this.sessionService.topicIsLoggedIn.subscribe((val: boolean) => {
            setTimeout(()=>this.isLoggedIn = val);
            this.setupSessionDisplay();
        });

        this.sessionService.topicCollapseSideMenu.subscribe((val: string) =>  {
            setTimeout(()=>this.sessionIsSideMenu = val);
            // console.log('this.collapsedClass: ', this.collapsedClass, '==>', this.sessionIsSideMenu),
            //this.printToPDF();
        });

    }

    printToPDF() {
        if (this.sessionIsSideMenu !== '') {
            if (this.collapsedClass === 'side-panel-opened') {
                this.menuToggleFunc()
            }
            setTimeout(() => { 
                this.exportService.exportToPDF(this.sessionIsSideMenu), 
                this.menuToggleFunc(); 
            }, 2000);
        }
    }


    //---------------------------------------------------------------------------------------------
    // Log the User to the DB in the key [Denver Naidoo 17 Nov 2020]
    //---------------------------------------------------------------------------------------------
    pr_AddUser: Ipr_AddUser = {} as any;
    pr_AddUserAlias: string = 'AddUser';
    ipr_AddUser_ReturnCode: IReturnCode[];

    addUser(clientId, userName, givenName, familyName, nickname, email) {
      this.pr_AddUser.ApiAlias = this.pr_AddUserAlias;
      this.pr_AddUser.ClientId = clientId;
      this.pr_AddUser.UserName = userName;
      this.pr_AddUser.GivenName = givenName;
      this.pr_AddUser.FamilyName = familyName;
      this.pr_AddUser.Nickname = nickname;
      this.pr_AddUser.Email = email;

      this.masterService.postInterface(this.pr_AddUser).subscribe({
          next: responseData => { 
            this.ipr_AddUser_ReturnCode = responseData;
            sessionStorage.setItem('Tolkien', this.ipr_AddUser_ReturnCode[0].ReturnCode.toString());
            this.iMySQLError = responseData;
            },
          error: err => this.errorMessage = err
          });
    }

    //---------------------------------------------------------------------------------------------
    // Log the Session [Denver Naidoo 24 Nov 2020]
    //---------------------------------------------------------------------------------------------
    pr_AddSession: Ipr_AddSession = {} as any;
    pr_AddSessionAlias: string = 'AddSession';
    ipr_AddSession_ReturnCode: IReturnCode[];

    addSession(userId) {
        try
        {    
            this.pr_AddSession.ApiAlias = this.pr_AddSessionAlias;
            this.pr_AddSession.UserId = userId;
            this.pr_AddSession.Language = navigator.language;
            this.pr_AddSession.Platform = navigator.platform;
            this.pr_AddSession.UserAgent = navigator.userAgent;
            this.pr_AddSession.StartTime = null;
            this.pr_AddSession.EndTime = null;

            this.masterService.postInterface(this.pr_AddSession).subscribe({
                next: responseData => { 
                if (responseData) {
                    this.ipr_AddSession_ReturnCode = responseData;
                    sessionStorage.setItem('MountDoom', this.ipr_AddSession_ReturnCode[0].ReturnCode.toString()); //MountDoom = SessionId
                    this.iMySQLError = responseData;
                }
                },
                error: err => this.errorMessage = err
                });        
        }
        catch (exception) {
            if(!environment.production){
              console.log('#EXCEPTION::MainComponent::addSession() ', exception);
            }
          }
    }

    addSesssionDynamo() {
        try {
            // Log the Sessoin to Dynamo [Denver Naidoo - 30 Nov 2021]
            if (this.iSessionLive) {
              let iSessionLite: ISessionLite = {
                'SessionId': this.iSessionLive.SessionId,
                'UserId': this.iSessionLive.UserId,
                'FirstName': this.iSessionLive.FirstName,
                'LastName': this.iSessionLive.LastName,
                'Email': this.iSessionLive.Email,
                'ClientId': this.iSessionLive.ClientId,
                'UserName': this.iSessionLive.UserName,
                'IsLoggedIn': this.iSessionLive.IsLoggedIn,
                'NickName': this.iSessionLive.NickName
              }
                let iSessionLogin: ISessionLogin = {
                    'pk': `${this.iSessionLive.ClientId}#SESSION`,
                    'sk': `${this.iSessionLive.SessionId}`,  
                    'SessionLive': iSessionLite             
                }
                this.mobileDataService.fargo_dynamo('update_dynamo', iSessionLogin).subscribe((response) => {});
            }
        }
        catch (exception) {
            if(!environment.production){
              console.log('#EXCEPTION::DashboardComponent::addSesssionDynamo() ', exception);
            }
          }
    }

    setupSessionDisplay() {
        if (this.isLoggedIn) {            
            this.loginDisplay = 'Log Off';
            this.authenticator = '#';
            this.menuSeperator = ' | ';         
            this.openSnackBar('Successfully Logged in', 'Dismiss...');
            //make decisions based on UserGroups
            // if (this.sessionService.loginHasPermissions('USSDDemo', true)) {
            //     this.themeHeaderSkinColor = "header-yellow"; 
            //     this.themeSidebarSkinColor = "sidebar-yellow";
            // } else if (this.sessionService.loginHasPermissions('Fraud', true)) {
            //     this.themeHeaderSkinColor = "header-default"; 
            //     this.themeSidebarSkinColor = "sidebar-blue";
            // } else {
            //     this.themeHeaderSkinColor = "header-red"; 
            //     this.themeSidebarSkinColor = "sidebar-red";
            // }
        }
        else {
            this.authenticator = this.authenticatorLink;
            this.loginDisplay = 'Log In';
        }

    }


    ngOnDestroy() {
        this._router.unsubscribe();
        this._mediaSubscription.unsubscribe();
    }

	
    
    // menuMouseOver(): void {
    //     if (window.matchMedia(`(min-width: 960px)`).matches && this.collapseSidebar) {
    //         this.sidenav.mode = 'over';
    //     }
    // }

    // menuMouseOut(): void {
    //     if (window.matchMedia(`(min-width: 960px)`).matches && this.collapseSidebar) {
    //         this.sidenav.mode = 'side';
    //     }
    // }

	// toggleFullscreen() {
    // 	if (screenfull.enabled) {
    // 		screenfull.toggle();
    //   		this.isFullscreen = !this.isFullscreen;
    // 	}
    //   }
    
    customizerFunction() {
        this.customizerIn = !this.customizerIn;
    }
    headerSkinColorFunction(color) {
        this.themeHeaderSkinColor = color;
    }
    sidebarSkinColorFunction(color) {
        this.themeSidebarSkinColor = color;
    }
    
    menuToggleFunc()
    {
        // this.sidenav.toggle();
        this.collapsedClass = 'side-panel-closed';
        // Removing the side menu functionality [Denver Naidoo - 23 Jan 2022 18:05]
        // if(this.collapsedClass == 'side-panel-opened')
        // {
        //     this.collapsedClass = 'side-panel-closed';
        // }
        // else
        // {
        //     this.collapsedClass= 'side-panel-opened';
        // }
    }

    addMenuItem(): void {
        this.menuItems.add({
            state: 'mobile-test-report',
            name: 'Reports',
            type: 'sub',
            icon: 'perm_media',
            children: [
                {state: 'report-summary', name: 'Summary Report'},
                {state: 'report-24hr',  name: 'Past 24 Hours'},
                {state: 'edit-service', name: 'Test Details'},
                {state: 'view-service', name: 'Failed Tests'},
            ]
            });

        var UserGroups = sessionStorage.getItem('Gollum');
        
    }
    
    onActivate(e, scrollContainer) {
        scrollContainer.scrollTop = 0;
    }
}
