import {Component, Optional, ViewEncapsulation} from '@angular/core';
import { Router } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import { CoreDataService } from './service/core-data.service';
import { FilterPipe } from './filter.pipe';
@Component({
   selector: 'inquest-app',
   template:'<router-outlet></router-outlet>',
   providers: [CoreDataService],
   encapsulation: ViewEncapsulation.None
})
export class inQuestAppComponent {

   constructor(
      private router:Router, 
      public translate: TranslateService,
      public coreDataService: CoreDataService,
      ) {
      translate.addLangs(['en', 'fr']);
      translate.setDefaultLang('en');

      const browserLang: string = translate.getBrowserLang();
      translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');

   }

   ngOnInit() { 
      // this.coreDataService.initialise();
   }
    

}
