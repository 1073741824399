import { EventEmitter, Injectable, OnInit } from '@angular/core';
import { MasterService } from  './master-service.service';
import { Ipr_GetNewTestBatch, Ipr_GetTestCases, IGetClientTimeZone,Ipr_GetClientTimeZone, IReturnCode, IMySQLError } from '../interface/i-mobile-test';
import { 
  Ipr_iq_GetDeviceList, 
  Ipr_GetTestRunByBatchId, 
  Ipr_GetTestTypeForRecording, 
  IFargoRequest, 
  Ipr_GetTestTemplateByTestCaseId, 
  Ipr_AddTestCaseQueue, 
  Ipr_GetATCommandById, 
  Ipr_GetTestRunDeltaByBatchId, 
  Ipr_GetTestRunByTestCaseQueueId, 
  Ipr_GetTestCaseQueueByTestCaseQueueId, 
  Ipr_AddTestTemplate, 
  Ipr_GetSMSEmulatorByDeviceId, 
  Ipr_AddTestRun, 
  Ipr_GetSMSEmulatorBySMSEmulatorId, 
  Ipr_GetKeyValuePairBySessionId, 
  Ipr_GetSessionKVP,
  Ipr_LogOff,
  Ipr_GetTestCasesByTestTypeId,
  Ipr_GetTestChainV2,
  Ipr_GetTestCasesByTestChainId,
  Ipr_AddTestChainQueue,
  Ipr_GetTestSuiteV2,
  Ipr_AddTestSuiteQueue,
  Ipr_GetTestSuiteHeader,
  Ipr_GetMNONames,
  Ipr_AddMNO,
  Ipr_GetDeviceListDetailed,
  Ipr_GetDeviceLog,
  Ipr_GetDeviceListDetailedV2,
  Ipr_GetReportTestRunByTestTypeId,
  Ipr_AbortTestCaseQueue,
  Ipr_AddCalculation,
  Ipr_GetCalculationByTestTemplateId,
  Ipr_GetTokenisedRequestResponseByTestTypeId,
  Ipr_bi_get_SuccessRate_PositiveTestCase,
  Ipr_bi_get_QuickReportTestRun
} from '../interface/i-requests';
import { Observable } from 'rxjs';
import { Ipr_AddRecording } from '../interface/i-requests';
import { environment } from '../../environments/environment'
import { ISessionLive } from '../interface/i-responses';
import { PageTitleService } from '../core/page-title/page-title.service';
import * as dayjs from 'dayjs'
var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

@Injectable()
export class MobileDataService {

    constructor(
      private masterService: MasterService,
      private pageTitleService: PageTitleService
      ) {
      }
    errorMessage: string;
    iMySQLError: IMySQLError[];

  //---------------------------------------------------------------------------------------------
  // pr_LogOff
  //---------------------------------------------------------------------------------------------
  pr_LogOff(): Observable<any> {
    try {
      let pr_LogOff: Ipr_LogOff;
      pr_LogOff = {
        ApiAlias: 'LogOff',
        SessionId: 0
      };
      return(this.masterService.postInterface(pr_LogOff));
    }
    catch (exception) {
      if (!environment.production) {
        console.log('#EXCEPTION:: MobileDataService:: pr_LogOff() => ', exception);
      }
    }
  }

  //pr_GetKeyValuePairBySessionId
  pr_GetSessionKVP(keyName: string): Observable<any> {
    try {
      let pr_GetSessionKVP: Ipr_GetSessionKVP;
      pr_GetSessionKVP = {
        ApiAlias: 'GetSessionKVP',
        KeyName: keyName,
        SessionId: 0
      };
      return(this.masterService.postInterface(pr_GetSessionKVP));
    }
    catch (exception) {
      if (!environment.production) {
        console.log('#EXCEPTION: ', exception);
      }
    }
  }

  //pr_GetKeyValuePairBySessionId
  pr_GetKeyValuePairBySessionId(keyName: string, typeId: number): Observable<any> {
    try {
      let pr_GetKeyValuePairBySessionId: Ipr_GetKeyValuePairBySessionId;
      pr_GetKeyValuePairBySessionId = {
        ApiAlias: 'GetKeyValuePairBySessionId',
        KeyName: keyName,
        TypeId: typeId,
        SessionId: 0
      };
      return(this.masterService.postInterface(pr_GetKeyValuePairBySessionId));
    }
    catch (exception) {
      if (!environment.production) {
        console.log('#EXCEPTION: ', exception);
      }
    }
  }

  //Get SMS by DeviceId 
  pr_GetSMSEmulatorBySMSEmulatorId(smsEmulatorId: number): Observable<any> {
    try {
      let pr_GetSMSEmulatorBySMSEmulatorId: Ipr_GetSMSEmulatorBySMSEmulatorId;
      pr_GetSMSEmulatorBySMSEmulatorId = {
        ApiAlias: 'GetSMSEmulatorBySMSEmulatorId',
        SMSEmulatorId: smsEmulatorId,
        SessionId: 0
      };
      return(this.masterService.postInterface(pr_GetSMSEmulatorBySMSEmulatorId));
    }
    catch (exception) {
      if (!environment.production) {
        console.log('#EXCEPTION: ', exception);
      }
    }
  }

  //pr_AddTestRun
  pr_AddTestRun(testRunId: number,
		aTCommandId: number,
		deviceRequest: string,
		deviceResponse: string,
		requestStartTime: string,
		requestEndTime: string,
		deviceId: number,
		testTemplateId: number,
		testBatchId: number,
		testRunStatusId: number): Observable<any> {
    try {
      let ipr_AddTestRun: Ipr_AddTestRun = {
        ApiAlias: 'AddTestRun',   
        TestRunId: testRunId,
        ATCommandId: aTCommandId,
        DeviceRequest: deviceRequest,
        DeviceResponse: deviceResponse,
        RequestStartTime: requestStartTime,
        RequestEndTime: requestEndTime,
        DeviceId: deviceId,
        TestTemplateId: testTemplateId,
        TestBatchId: testBatchId,
        TestRunStatusId: testRunStatusId,
        IsDeleted: 0,
        SessionId: 0
      }
      return(this.masterService.postInterface(ipr_AddTestRun));
    }
    catch (exception) {
      if (!environment.production) {
        console.log('#EXCEPTION: ', exception);
      }
    }
  }

  //Get SMS by DeviceId 
  pr_GetSMSEmulatorByDeviceId(deviceId: number): Observable<any> {
    try {
      let pr_GetSMSEmulatorByDeviceId: Ipr_GetSMSEmulatorByDeviceId;
      pr_GetSMSEmulatorByDeviceId = {
        ApiAlias: 'GetSMSEmulatorByDeviceId',
        DeviceId: deviceId,
        SessionId: 0
      };
      return(this.masterService.postInterface(pr_GetSMSEmulatorByDeviceId));
    }
    catch (exception) {
      if (!environment.production) {
        console.log('#EXCEPTION: ', exception);
      }
    }
  }

  //pr_AddTestTemplate
  pr_AddTestTemplate(TestTemplateId: number, DeviceRequest: string, TokenisedResponse: string): Observable<any> {
    try {
      if (!DeviceRequest) {
        DeviceRequest = null;
      }
      if (!TokenisedResponse) {
        TokenisedResponse = null;
      }
      let ipr_AddTestTemplate: Ipr_AddTestTemplate = {
        ApiAlias: 'AddTestTemplate',
        TestTemplateId: TestTemplateId,
        TestCaseId: null,
        Sequence: null, 
        DeviceId: null,
        TemplateName: null,
        DeviceRequest: DeviceRequest,
        TokenisedResponse: TokenisedResponse,
        AccountSubTypeId: null,
        SessionId: 0
      }
      return(this.masterService.postInterface(ipr_AddTestTemplate));
    }
    catch (exception) {
      if (!environment.production) {
        console.log('#EXCEPTION: ', exception);
      }
    }
  }

  //Get TestRun By TestCaseQueueId
  pr_GetTestCaseQueueByTestCaseQueueId(TestCaseQueueId: number): Observable<any> {
    try {
      let pr_GetTestCaseQueueByTestCaseQueueId: Ipr_GetTestCaseQueueByTestCaseQueueId;
      pr_GetTestCaseQueueByTestCaseQueueId = {
        ApiAlias: 'GetTestCaseQueueByTestCaseQueueId',
        TestCaseQueueId: TestCaseQueueId,
        SessionId: 0
      };
      return(this.masterService.postInterface(pr_GetTestCaseQueueByTestCaseQueueId));
    }
    catch (exception) {
      if (!environment.production) {
        console.log('#EXCEPTION::mobileDataService::pr_GetTestRunByTestCaseQueueId() ', exception);
      }
    }
  }

  //Get TestRun By TestCaseQueueId
  pr_GetTestRunByTestCaseQueueId(TestCaseQueueId: number, TestBatchId: number): Observable<any> {
    try {
      let pr_GetTestRunByTestCaseQueueId: Ipr_GetTestRunByTestCaseQueueId;
      pr_GetTestRunByTestCaseQueueId = {
        ApiAlias: 'GetTestRunByTestCaseQueueId',
        TestCaseQueueId: TestCaseQueueId,
        TestBatchId: TestBatchId,
        SessionId: 0
      };
      return(this.masterService.postInterface(pr_GetTestRunByTestCaseQueueId));
    }
    catch (exception) {
      if (!environment.production) {
        console.log('#EXCEPTION::mobileDataService::pr_GetTestRunByTestCaseQueueId() ', exception);
      }
    }
  }

  //Get Test Run by Test Batch Id 
  pr_GetNewTestBatch(): Observable<any> {
    try {
      let pr_GetNewTestBatch: Ipr_GetNewTestBatch;
      pr_GetNewTestBatch = {
        ApiAlias: 'GetNewTestBatch',
        SessionId: 0
      };
      return(this.masterService.postInterface(pr_GetNewTestBatch));
    }
    catch (exception) {
      if (!environment.production) {
        console.log('#EXCEPTION: ', exception);
      }
    }
  }

  //Get Test Run by Test Batch Id 
  pr_GetTestRunDeltaByBatchId(testBatchId, testRunId): Observable<any> {
    try {
      let pr_GetTestRunDeltaByBatchId: Ipr_GetTestRunDeltaByBatchId;
      pr_GetTestRunDeltaByBatchId = {
        ApiAlias: 'GetTestRunDeltaByBatchId',
        TestBatchId: testBatchId,
        TestRunId: testRunId,
        SessionId: 0
      };
      return(this.masterService.postInterface(pr_GetTestRunDeltaByBatchId));
    }
    catch (exception) {
      if (!environment.production) {
        console.log('#EXCEPTION: ', exception);
      }
    }
  }

    //pr_GetATCommandById
    pr_GetATCommandById(ATCommandId: number): Observable<any> {
      try {
        let pr_GetATCommandById: Ipr_GetATCommandById;
        pr_GetATCommandById = {
          ApiAlias: 'GetATCommandById',
          ATCommandId: ATCommandId,
          SessionId: 0
      };
        return(this.masterService.postInterface(pr_GetATCommandById));
      }
      catch (exception) {
        if (!environment.production) {
          console.log('#EXCEPTION: ', exception);
        }
      }
    }

    //pr_AddRecording
    pr_GetTestTemplateByTestCaseId(TestCaseId: number): Observable<any> {
      try {
        let pr_GetTestTemplateByTestCaseId: Ipr_GetTestTemplateByTestCaseId;
        pr_GetTestTemplateByTestCaseId = {
          ApiAlias: 'GetTestTemplateByTestCaseId',
          TestCaseId: TestCaseId,
          SessionId: 0
      };
        return(this.masterService.postInterface(pr_GetTestTemplateByTestCaseId));
      }
      catch (exception) {
        if (!environment.production) {
          console.log('#EXCEPTION: ', exception);
        }
      }
    }

    //pr_AddTestCaseQueue
    pr_AddTestCaseQueue(TestCaseId: number): Observable<any> {
      try {
        let ipr_AddTestCaseQueue: Ipr_AddTestCaseQueue = {
          ApiAlias: 'AddTestCaseQueue',
          TestCaseQueueId: null,
          TestCaseId: TestCaseId,
          TestRunStatusId: null,
          StartTime: null,
          EndTime: null,
          SessionId: 0
        }

        ipr_AddTestCaseQueue.ApiAlias = 'AddTestCaseQueue';
        return(this.masterService.postInterface(ipr_AddTestCaseQueue));
      }
      catch (exception) {
        if (!environment.production) {
          console.log('#EXCEPTION: ', exception);
        }
      }
    }

     //pr_AddRecording
     pr_AddRecording(ipr_AddRecording: Ipr_AddRecording): Observable<any> {
      try {
        ipr_AddRecording.ApiAlias = 'AddRecording';
        return(this.masterService.postInterface(ipr_AddRecording));
      }
      catch (exception) {
        if (!environment.production) {
          console.log('#EXCEPTION: ', exception);
        }
      }
    }

    //Get Test Type
    pr_GetTestTypeForRecording(IsVisibleInRecording: boolean=true): Observable<any> {
      try {
        let pr_GetTestTypeForRecording: Ipr_GetTestTypeForRecording;
        pr_GetTestTypeForRecording = {
          ApiAlias: 'GetTestTypeForRecording',
          IsVisibleInRecording: IsVisibleInRecording,
          SessionId: 0
      };
        return(this.masterService.postInterface(pr_GetTestTypeForRecording));
      }
      catch (exception) {
        if (!environment.production) {
          console.log('#EXCEPTION: ', exception);
        }
      }
    }

    //Get Test Cases
    getTestCases(): Observable<any> {
    let pr_GetTestCases: Ipr_GetTestCases;
    pr_GetTestCases = {
        ApiAlias: 'GetTestCases',
        SessionId: 0
    };
    return(this.masterService.postInterface(pr_GetTestCases));
  }

  //Get Device List
  getDeviceList(): Observable<any> {
    let pr_iq_GetDeviceList: Ipr_iq_GetDeviceList;
    pr_iq_GetDeviceList = {
        ApiAlias: 'iq_GetDeviceList',
        SessionId: 0
    };
    return(this.masterService.postInterface(pr_iq_GetDeviceList));
  }

  //Get Test Run by Test Batch Id 
  getTestRunByBatchId(testBatchId, testRunId): Observable<any> {
    let pr_GetTestRunByBatchId: Ipr_GetTestRunByBatchId;
    pr_GetTestRunByBatchId = {
      ApiAlias: 'GetTestRunByBatchId',
      TestBatchId: testBatchId,
      TestRunId: testRunId,
      SessionId: 0
    };
    if (!environment.production) {
      console.log('*** pr_GetTestRunByBatchId', pr_GetTestRunByBatchId);
    }
    return(this.masterService.postInterface(pr_GetTestRunByBatchId));
  }

   // fargo
   fargo(fargoMethod: string, fargoParams: string) {
    try {
      const urlPostFix: string = 'fargo'
      let methodName: string = fargoMethod
      let methodParams: string = `${fargoParams}`
      let iFargoRequest: IFargoRequest = {
        MethodName: methodName,
        MethodParams: methodParams
      }
      return(this.masterService.postInterface(iFargoRequest, urlPostFix));
    }
    catch (exception) {
      if (!environment.production) {
        console.log('#EXCEPTION: ', exception);
      }
    }
  }

   //Send request to device and get a response
   fargo_express(methodName: string, methodParams: any): Observable<any> {
    try {
      const urlPostFix: string = 'fargo'
      let iFargoRequest: IFargoRequest = {
        MethodName: methodName,
        MethodParams: methodParams
      }
      return(this.masterService.postInterface(iFargoRequest, urlPostFix));
    }
    catch (exception) {
      if (!environment.production) {
        console.log('#EXCEPTION::MobileDeviceService::sendRequestToDevice() ', exception);
      }
    }
  }

  //---------------------------------------------------------------------------------------------
  // pr_GetTestCasesByTestTypeId
  //---------------------------------------------------------------------------------------------
  pr_GetTestCasesByTestTypeId(testTypeId): Observable<any> {
    try {
      let pr_GetTestCasesByTestTypeId: Ipr_GetTestCasesByTestTypeId;
      pr_GetTestCasesByTestTypeId = {
        ApiAlias: 'GetTestCasesByTestTypeId',
        TestTypeId: testTypeId,
        SessionId: 0
      };
      return(this.masterService.postInterface(pr_GetTestCasesByTestTypeId));
    }
    catch (exception) {
      if (!environment.production) {
        console.log('#EXCEPTION:: MobileDataService:: pr_LogOff() => ', exception);
      }
    }
  }

  //---------------------------------------------------------------------------------------------
  // pr_GetTestChainV2
  //---------------------------------------------------------------------------------------------
  pr_GetTestChainV2(testChainId): Observable<any> {
    try {
      let pr_GetTestChainV2: Ipr_GetTestChainV2;
      pr_GetTestChainV2 = {
        ApiAlias: 'GetTestChainV2',
        TestChainId: testChainId,
        SessionId: 0
      };
      return(this.masterService.postInterface(pr_GetTestChainV2));
    }
    catch (exception) {
      if (!environment.production) {
        console.log('#EXCEPTION:: MobileDataService:: pr_LogOff() => ', exception);
      }
    }
  }

  //---------------------------------------------------------------------------------------------
  // pr_GetTestCasesByTestChainId
  //---------------------------------------------------------------------------------------------
  pr_GetTestCasesByTestChainId(testChainId): Observable<any> {
    try {
      let pr_GetTestCasesByTestChainId: Ipr_GetTestCasesByTestChainId;
      pr_GetTestCasesByTestChainId = {
        ApiAlias: 'GetTestCasesByTestChainId',
        TestChainId: testChainId,
        SessionId: 0
      };
      return(this.masterService.postInterface(pr_GetTestCasesByTestChainId));
    }
    catch (exception) {
      if (!environment.production) {
        console.log('#EXCEPTION:: MobileDataService:: pr_LogOff() => ', exception);
      }
    }
  }

  //---------------------------------------------------------------------------------------------
  // pr_AddTestChainQueue
  //---------------------------------------------------------------------------------------------
  pr_AddTestChainQueue(TestChainId: number): Observable<any> {
    try {
      let pr_AddTestChainQueue: Ipr_AddTestChainQueue = {
        ApiAlias: 'AddTestChainQueue',
        TestChainId: TestChainId,
        SessionId: 0
      };
      return(this.masterService.postInterface(pr_AddTestChainQueue));
    }
    catch (exception) {
      if (!environment.production) {
        console.log('#EXCEPTION: ', exception);
      }
    }
  }

  //---------------------------------------------------------------------------------------------
  // pr_GetTestChainV2
  //---------------------------------------------------------------------------------------------
  pr_GetTestSuiteV2(testSuiteId): Observable<any> {
    try {
      let pr_GetTestSuiteV2: Ipr_GetTestSuiteV2;
      pr_GetTestSuiteV2 = {
        ApiAlias: 'GetTestSuiteV2',
        TestSuiteId: testSuiteId,
        SessionId: 0
      };
      return(this.masterService.postInterface(pr_GetTestSuiteV2));
    }
    catch (exception) {
      if (!environment.production) {
        console.log('#EXCEPTION:: MobileDataService:: pr_LogOff() => ', exception);
      }
    }
  }

  //---------------------------------------------------------------------------------------------
  // pr_AddTestSuiteQueue
  //---------------------------------------------------------------------------------------------
  pr_AddTestSuiteQueue(testSuiteId: number): Observable<any> {
    try {
      let pr_AddTestSuiteQueue: Ipr_AddTestSuiteQueue = {
        ApiAlias: 'AddTestSuiteQueue',
        TestSuiteId: testSuiteId,
        SessionId: 0
      };
      return(this.masterService.postInterface(pr_AddTestSuiteQueue));
    }
    catch (exception) {
      if (!environment.production) {
        console.log('#EXCEPTION: ', exception);
      }
    }
  }

  //---------------------------------------------------------------------------------------------
  // pr_AddTestSuiteQueue
  //---------------------------------------------------------------------------------------------
  pr_GetTestSuiteHeader(testSuiteId: number): Observable<any> {
    try {
      let pr_GetTestSuiteHeader: Ipr_GetTestSuiteHeader = {
        ApiAlias: 'GetTestSuiteHeader',
        TestSuiteId: testSuiteId,
        SessionId: 0
      };
      return(this.masterService.postInterface(pr_GetTestSuiteHeader));
    }
    catch (exception) {
      if (!environment.production) {
        console.log('#EXCEPTION: ', exception);
      }
    }
  }

  //---------------------------------------------------------------------------------------------
  // pr_GetMNONames
  //---------------------------------------------------------------------------------------------
  pr_GetMNONames(): Observable<any> {
    try {
      let pr_GetMNONames: Ipr_GetMNONames = {
        ApiAlias: 'GetMNONames',
        SessionId: 0
      };
      return(this.masterService.postInterface(pr_GetMNONames));
    }
    catch (exception) {
      if (!environment.production) {
        console.log('#EXCEPTION: ', exception);
      }
    }
  }

  //---------------------------------------------------------------------------------------------
  // pr_GetDeviceListDetailedV2
  //---------------------------------------------------------------------------------------------
  pr_GetDeviceListDetailedV2(deviceId:number): Observable<any> {
    try {
      let pr_GetDeviceListDetailedV2: Ipr_GetDeviceListDetailedV2 = {
        ApiAlias: 'GetDeviceListDetailedV2',
        DeviceId: deviceId,
        SessionId: 0
      };
      return(this.masterService.postInterface(pr_GetDeviceListDetailedV2));
    }
    catch (exception) {
      if (!environment.production) {
        console.log('#EXCEPTION: ', exception);
      }
    }
  }

  //---------------------------------------------------------------------------------------------
  // pr_AddMNO
  //---------------------------------------------------------------------------------------------
  pr_AddMNO(mnoId:number, mnoName:string): Observable<any> {
    try {
      let pr_AddMNO: Ipr_AddMNO = {
        ApiAlias: 'AddMNO',
        MNOId:mnoId, 
        MNOName:mnoName,
        SessionId: 0
      };
      return(this.masterService.postInterface(pr_AddMNO));
    }
    catch (exception) {
      if (!environment.production) {
        console.log('#EXCEPTION: ', exception);
      }
    }
  }

  //---------------------------------------------------------------------------------------------
  // pr_GetDeviceListDetailed
  //---------------------------------------------------------------------------------------------
  pr_GetDeviceListDetailed(): Observable<any> {
    try {
      let pr_GetDeviceListDetailed: Ipr_GetDeviceListDetailed = {
        ApiAlias: 'GetDeviceListDetailed',
        SessionId: 0
      };
      return(this.masterService.postInterface(pr_GetDeviceListDetailed));
    }
    catch (exception) {
      if (!environment.production) {
        console.log('#EXCEPTION: ', exception);
      }
    }
  }

  //---------------------------------------------------------------------------------------------
  // Ipr_GetDeviceLog
  //---------------------------------------------------------------------------------------------
  pr_GetDeviceLog(deviceId: number): Observable<any> {
    try {
      let pr_GetDeviceLog: Ipr_GetDeviceLog = {
        ApiAlias: 'GetDeviceLog',
        DeviceId: deviceId,
        SessionId: 0
      };
      return(this.masterService.postInterface(pr_GetDeviceLog));
    }
    catch (exception) {
      if (!environment.production) {
        console.log('#EXCEPTION: ', exception);
      }
    }
  }

  //---------------------------------------------------------------------------------------------
  // pr_GetReportTestRunByTestTypeId [Denver Naidoo - 16 Oct 2021]
  //---------------------------------------------------------------------------------------------
  pr_GetReportTestRunByTestTypeId(testTypeId: number): Observable<any> {
    try {
      let pr_GetReportTestRunByTestTypeId: Ipr_GetReportTestRunByTestTypeId = {
        ApiAlias: 'GetReportTestRunByTestTypeId',
        TestTypeId: testTypeId,
        SessionId: 0
      };
      return(this.masterService.postInterface(pr_GetReportTestRunByTestTypeId));
    }
    catch (exception) {
      if (!environment.production) {
        console.log('#EXCEPTION: pr_GetReportTestRunByTestTypeId: ', exception);
      }
    }
  }

  //---------------------------------------------------------------------------------------------
  // pr_AbortTestCaseQueue [Denver Naidoo - 16 Oct 2021]
  //---------------------------------------------------------------------------------------------
  pr_AbortTestCaseQueue(testCaseQueueI: number): Observable<any> {
    try {
      let pr_AbortTestCaseQueue: Ipr_AbortTestCaseQueue = {
        ApiAlias: 'AbortTestCaseQueue',
        TestCaseQueueId: testCaseQueueI,
        SessionId: 0
      };
      return(this.masterService.postInterface(pr_AbortTestCaseQueue));
    }
    catch (exception) {
      if (!environment.production) {
        console.log('#EXCEPTION: pr_GetReportTestRunByTestTypeId: ', exception);
      }
    }
  }

  //---------------------------------------------------------------------------------------------
  // pr_AddCalculation [MCoetzee - 20211022]
  //---------------------------------------------------------------------------------------------
  pr_AddCalculation(calculationId: number,testTemplateId: number, sequence: number,calculation: string, isCalculation : boolean): Observable<any> {
    try {
      let pr_AddCalculation: Ipr_AddCalculation = {
        ApiAlias: 'AddCalculation',
        CalculationId: calculationId,
        TestTemplateId: testTemplateId,
        Sequence: sequence,
        Calculation: calculation,
        IsCalculation: isCalculation,
        SessionId: 0
      };
      return(this.masterService.postInterface(pr_AddCalculation));
    }
    catch (exception) {
      if (!environment.production) {
        console.log('#EXCEPTION: pr_AddCalculation: ', exception);
      }
    }
  }

  //---------------------------------------------------------------------------------------------
  // pr_GetCalculationByTestTemplateId [MCoetzee - 20211022]
  //---------------------------------------------------------------------------------------------
  pr_GetCalculationByTestTemplateId(testTemplateId: number): Observable<any> {
    try {
      let pr_GetCalculationByTestTemplateId: Ipr_GetCalculationByTestTemplateId = {
        ApiAlias: 'GetCalculationByTestTemplateId',
        TestTemplateId: testTemplateId,
        SessionId: 0
      };
      return(this.masterService.postInterface(pr_GetCalculationByTestTemplateId));
    }
    catch (exception) {
      if (!environment.production) {
        console.log('#EXCEPTION: pr_GetCalculationByTestTemplateId: ', exception);
      }
    }
  }

  //---------------------------------------------------------------------------------------------
  // pr_GetTokenisedRequestResponseByTestTypeId [MCoetzee - 20211026]
  //---------------------------------------------------------------------------------------------
  pr_GetTokenisedRequestResponseByTestTypeId(polymorphicTestCaseId: number, testTemplateId: number, testTypeId: number): Observable<any> {
    try {
      let pr_GetTokenisedRequestResponseByTestTypeId: Ipr_GetTokenisedRequestResponseByTestTypeId = {
        ApiAlias: 'GetTokenisedRequestResponseByTestTypeId',
        PolymorphicTestCaseId: polymorphicTestCaseId,
        TestTemplateId: testTemplateId,
        TestTypeId:testTypeId,
        SessionId: 0
      };
      return(this.masterService.postInterface(pr_GetTokenisedRequestResponseByTestTypeId));
    }
    catch (exception) {
      if (!environment.production) {
        console.log('#EXCEPTION: pr_GetTokenisedRequestResponseByTestTypeId: ', exception);
      }
    }
  }

  //-----------------------------------------------------------------------------------------------
  iSessionLive: ISessionLive;
  getSessionLive() {
    try {
      this.pageTitleService.sessionLive.subscribe((iSessionLive: ISessionLive) => {
        this.iSessionLive = iSessionLive;
      });
    }
    catch (exception) {
      if(!environment.production){
        console.log('#EXCEPTION::DeviceListComponent::getSessionLive() ', exception);
      }
    }
  }

  fargo_dynamo(methodName: string, iPayload: any): Observable<any> {
    try {
      // this.getSessionLive();
      methodName = 'i_inquest.' + methodName;
      const urlPostFix: string = 'fargo'
      // iPayload.pk = this.iSessionLive.ClientId + '#' + iPayload.pk;
      let iFargoRequest: IFargoRequest = {
        MethodName: methodName,
        MethodParams: iPayload
      }
      return(this.masterService.postInterface(iFargoRequest, urlPostFix));
    }
    catch (exception) {
      if (!environment.production) {
        console.log('#EXCEPTION::MobileDeviceService::sendRequestToDevice() ', exception);
      }
    }
  }

  //---------------------------------------------------------------------------------------------
  // Check if Role Exists in Cognito Group [Denver Naidoo - 12 Dec 2021]
  //---------------------------------------------------------------------------------------------
  isBreakpointSupport(cognitoGroups): boolean {
    try {
      const BREAKPOINT_SUPPORT = 'BreakpointSupport'
      if (cognitoGroups.includes(BREAKPOINT_SUPPORT)) {
        return true;
      }
      else {
        return false;
      }
    }
    catch (exception) {
      console.log('#EXCEPTION::MobileDeviceService::sendRequestToDevice() ', exception);
    }
  }

  //---------------------------------------------------------------------------------------------
  // pr_bi_get_SuccessRate_PositiveTestCase [Denver Naidoo - 19 Jan 2022 21:00]
  //---------------------------------------------------------------------------------------------
  pr_bi_get_SuccessRate_PositiveTestCase(): Observable<any> {
    try {
      let pr_bi_get_SuccessRate_PositiveTestCase: Ipr_bi_get_SuccessRate_PositiveTestCase = {
        ApiAlias: 'bi_get_SuccessRate_PositiveTestCase',
        StartDate: null,
        EndDate: null,
        SessionId: 0
      };
      return(this.masterService.postInterface(pr_bi_get_SuccessRate_PositiveTestCase));
    }
    catch (exception) {
      if (!environment.production) {
        console.log('#EXCEPTION: pr_bi_get_SuccessRate_PositiveTestCase: ', exception);
      }
    }
  }

    //---------------------------------------------------------------------------------------------
    // Get The ClientTimeZone - MCoetzee 2022/03/03
    //---------------------------------------------------------------------------------------------
    pr_GetClientTimeZone: Ipr_GetClientTimeZone = {} as any;
    pr_GetClientTimeZoneAlias: string = 'GetClientTimeZone';
    ipr_GetClientTimeZone_ReturnCode: IReturnCode[];
    iGetClientTimeZone: IGetClientTimeZone;

    getClientTimeZone() {
        try{
            this.pr_GetClientTimeZone.ApiAlias = this.pr_GetClientTimeZoneAlias;
            this.masterService.postInterface(this.pr_GetClientTimeZone).subscribe({
                next: responseData => { 
                    this.iGetClientTimeZone = responseData;
                    sessionStorage.setItem('ClientTZ', this.iGetClientTimeZone[0].TimeZone.toString()); 
                    this.iMySQLError = responseData;
                },
                error: err => this.errorMessage = err
            });
        }    
        catch (exception) {
            if (!environment.production) {
                console.log('#EXCEPTION: pr_GetClientTimeZone: ', exception);
            }
        }
    }  

    //---------------------------------------------------------------------------------------------
    // pr_bi_get_SuccessRate_PositiveTestCase [Denver Naidoo - 19 Jan 2022 21:00]
    //---------------------------------------------------------------------------------------------
    pr_bi_get_QuickReportTestRun(inTestTypeId, in_StartTime = null, in_EndTime = null): Observable<any> {
      try {

        let startTime = in_StartTime;
        let endTime = in_EndTime;

        if(!startTime)
        {
          startTime = dayjs().subtract(2, 'days');
        }
        else
        {
          startTime = dayjs(in_StartTime);
        }

        if(!endTime)
        {
          endTime = dayjs().add(1, 'days');
        }
        else
        {
            endTime = dayjs(in_EndTime);
        }

        let pr_bi_get_QuickReportTestRun: Ipr_bi_get_QuickReportTestRun = {
          ApiAlias: 'bi_get_QuickReportTestRun',
          TestTypeId: inTestTypeId,
          StartTime: startTime.utc().format(),
          EndTime: endTime.utc().format(),
          SessionId: 0
        };
        return(this.masterService.postInterface(pr_bi_get_QuickReportTestRun));
      }
      catch (exception) {
        if (!environment.production) {
          console.log('#EXCEPTION: pr_bi_get_QuickReportTestRun: ', exception);
        }
      }
    }

    //------------------------------------------------------------------------------------------------
    // Marwyk - export to pdf api call
    //------------------------------------------------------------------------------------------------
    fargo_websocket(methodName: string, iPayload: any, action:string = null): Observable<any> {
      try {
        //methodName = 'i_inquest.' + methodName;
        this.getSessionLive();
        iPayload.SessionLive = this.iSessionLive;
        if(action){iPayload.action = action}
        const urlPostFix: string = 'fargo'
        let iFargoRequest: IFargoRequest = {
          MethodName: methodName,
          MethodParams: iPayload
        }
        return(this.masterService.postInterface(iFargoRequest, urlPostFix));
      }
      catch (exception) {
        if (!environment.production) {
          console.log('#EXCEPTION::MobileDeviceService::fargo_websocket() ', exception);
        }
      }
    }

    //------------------------------------------------------------------------------------------------
    // Marwyk - export to pdf api call
    //------------------------------------------------------------------------------------------------
    async generatePDFwithSignedURL(pk:any, sk:any): Promise<Observable<any>> {
      try {
        //let conId = await this.getCurrentWebSocketConnectionId();
        let sessionId = sessionStorage.getItem('MountDoom');
        let timeZoneString = sessionStorage.getItem('SessionTimeZone');
        let clientId = sessionStorage.getItem('WingNut');
        const urlPostFix: string = 'pittsburg'
        let apiReqBody = {
          pk:pk,
          sk:sk,
          MountDoom: sessionId,
          tzDatabaseName:timeZoneString,
          WingNut:clientId,
        }
        let result = await this.masterService.postInterface(apiReqBody, urlPostFix).toPromise();
        return result;
      }
      catch (exception) {
        if (!environment.production) {
          console.log('#EXCEPTION::MobileDeviceService::sendRequestToDevice() ', exception);
        }
      }
    }

    //------------------------------------------------------------------------------------------------
    // Marwyk - websocket connection id
    //------------------------------------------------------------------------------------------------
    // currentSocketConnectionId:string;
    // getCurrentWebSocketConnectionId(init:boolean = false): string 
    // {
    //   try 
    //   {
    //     if(init)
    //     {
    //       this.currentSocketConnectionId = null;
    //     }
    //     // get the current connection id
    //     if(this.currentSocketConnectionId)
    //     {
    //       return this.currentSocketConnectionId;
    //     }
    //     else
    //     {
    //       let keyName = 'CORE_WS_CONNECTION_ID';
    //       this.pr_GetSessionKVP(keyName).subscribe(response => {
    //         if(response[0].KeyValue)
    //         {
    //           this.currentSocketConnectionId = response[0].KeyValue;
    //           return this.currentSocketConnectionId;
    //         }
    //         else
    //         {
    //           setTimeout(this.getCurrentWebSocketConnectionId(), 2000);
    //         }
    //       });
    //     }
    //   }
    //   catch (exception) 
    //   {
    //     if (!environment.production) {
    //       console.log('#EXCEPTION::MobileDeviceService::getCurrentWebSocketConnectionId() ', exception);
    //     }
    //     setTimeout(this.getCurrentWebSocketConnectionId(), 1000);
    //   }
    // }

}