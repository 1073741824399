import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { environment } from '../../environments/environment';
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc';
import * as timezone from 'dayjs/plugin/timezone';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import { PageTitleService } from '../core/page-title/page-title.service';
import { ISessionLive, IEventHandler } from '../interface/i-responses';



@Injectable()
export class FrontEndService implements OnInit{

    public initializedDateObject;
    public initializedLocalDateObject;
    public currentTimeZone;
    public defaultDateFormat;

    CurrentlyRunningTestCase:BehaviorSubject<any> = new BehaviorSubject<any>({RunningAsync:false, RunningSync:false, TestType:"CASE"});
    CurrentlyRunningTestChain:BehaviorSubject<any> = new BehaviorSubject<any>({RunningAsync:false, RunningSync:false, TestType:"CHAIN"});
    CurrentlyRunningTestSuite:BehaviorSubject<any> = new BehaviorSubject<any>({RunningAsync:false, RunningSync:false, TestType:"SUITE"});
    
    constructor
    (
        private pageTitleService: PageTitleService,
    )
    {
        dayjs.extend(utc);
        dayjs.extend(timezone);
        dayjs.extend(customParseFormat);
    }

    initialize()
    {
        try
        {
            this.initializedDateObject = dayjs().utc();
            if(sessionStorage.getItem("SessionTimeZone"))
            {
                this.currentTimeZone = sessionStorage.getItem("SessionTimeZone")
            }
            else
            {   
                sessionStorage.setItem("SessionTimeZone",'Africa/Windhoek');
            }
            dayjs.tz.setDefault(this.currentTimeZone);
            this.initializedLocalDateObject = dayjs();
            this.defaultDateFormat = "DD MMM YYYY, HH:mm:ss";
            this.getEventHandler();
        }
        catch(error)
        {
            if (!environment.production) 
            {
                console.log('#EXCEPTION::FrontEndService::initialize ', error);
            }
        }
        
    }

    ngOnInit() {
        try 
        {
            this.initialize();
        }
        catch (exception) {
          if (!environment.production) {
            console.log('#EXCEPTION: FrontEndService: ngOnInit()', exception);
          }
        }
      }


    setDefaultDateFormat(formatString)
    {
        this.defaultDateFormat = formatString || "DD MMM YYYY, HH:mm:ss";
    }
    
    setNewTimeZone(timeZoneString)
    {
        dayjs.tz.setDefault(timeZoneString);
        this.currentTimeZone = timeZoneString;
        sessionStorage.setItem("SessionTimeZone", timeZoneString);
    }

    getNowUtc(formatString = this.defaultDateFormat)
    {
        return dayjs().utc().format(formatString);
    }

    getNowLocal(formatString = this.defaultDateFormat)
    {
        return dayjs().tz(this.currentTimeZone).format(formatString);
    }

    parseStringToUtc(timeStampString, formatString = "", fromUtc = true)
    {
        if(fromUtc)
        {
            if(dayjs.utc(timeStampString).isUTC())
            {
                return dayjs.utc(timeStampString).format(formatString);
            }
            else
            {
                return "Invalid date format";
            }
        }
        else
        {
            if(dayjs(timeStampString).utc().isUTC())
            {
                return dayjs(timeStampString).utc().format(formatString);
            }
            else
            {
                return "Invalid date format";
            }
        }
        
    }

    parseString(timeStampString, formatString = this.defaultDateFormat, fromUtc = true)
    {
        if(fromUtc)
        {
            if(dayjs.utc(timeStampString).isUTC())
            {
                return dayjs.utc(timeStampString).tz(this.currentTimeZone).format(formatString);
            }
            else
            {
                return "Invalid date format";
            }
        }
        else
        {
            if(dayjs(timeStampString).utc().isUTC())
            {
                return dayjs(timeStampString).utc().tz(this.currentTimeZone).format(formatString);
            }
            else
            {
                return "Invalid date format";
            }
        }
    }

    normalizeTimezone(timeStampString)
    {
        return dayjs(timeStampString).tz(this.currentTimeZone, true);
    }


    //---------------------------------------------------------------------------------------------
    // Marwyk - Handle subscriptions on ngDestroy
    //---------------------------------------------------------------------------------------------
    iEventHandlerSubscription:any;
    ngOnDestroy(){
        if(this.iEventHandlerSubscription)
        {
            this.iEventHandlerSubscription.unsubscribe();
        }
    }

    //---------------------------------------------------------------------------------------------
    // Marwyk - subscribe to events on the websocket for async test updates
    //---------------------------------------------------------------------------------------------
    iEventHandler: IEventHandler;
    getEventHandler() {
        try {
            this.iEventHandlerSubscription = this.pageTitleService.iEventHandler.subscribe((iEventHandler: IEventHandler) => {
                if (iEventHandler) 
                {
                    if (iEventHandler.Event) 
                    {
                        let events = iEventHandler.Event.split(',')
                        for (let action in events)  
                        {
                            if (events[action] == 'REAL_TIME_TEST_RUN')
                            {
                                
                            }
                            //test case events
                            if (events[action] == 'TEST_CASE_EVENT') 
                            {
                                if(events[0] == "TEST_CASE_COMPLETE")
                                {
                                    //test case has completed in context of a test chain
                                    this.CurrentlyRunningTestCase.next({RunningAsync:false, RUnningSync:false, TestType:"CASE"});
                                }
                            }
                            //test chain events
                            if (events[action] == 'TEST_CHAIN_EVENT') 
                            {
                                if(events[0] == "TEST_CASE_COMPLETE")
                                {
                                    //test case has completed in context of a test chain
                                    this.CurrentlyRunningTestCase.next({RunningAsync:false, RUnningSync:false, TestType:"CASE"});
                                }
                                if(events[0] == "TEST_CHAIN_COMPLETE")
                                {
                                    //test chain has completed its test run
                                    this.CurrentlyRunningTestChain.next({RunningAsync:false, RUnningSync:false, TestType:"CHAIN"});
                                }
                            }
                            //Test Suite events
                            if (events[action] == 'TEST_SUITE_EVENT') 
                            {
                                if(events[0] == "TEST_CASE_COMPLETE")
                                {
                                    //test case has completed in context of a test suite
                                    this.CurrentlyRunningTestCase.next({RunningAsync:false, RUnningSync:false, TestType:"CASE"});
                                }
                                if(events[0] == "TEST_CHAIN_COMPLETE")
                                {
                                    //test chain has completed in context of test suite
                                    this.CurrentlyRunningTestChain.next({RunningAsync:false, RUnningSync:false, TestType:"CHAIN"});
                                }
                                if(events[0] == "TEST_SUITE_COMPLETE")
                                {
                                    //test suite has completed its test run
                                    this.CurrentlyRunningTestSuite.next({RunningAsync:false, RUnningSync:false, TestType:"SUITE"});
                                }
                            }
                            

                        }              
                    }
                }
            });      
        }
        catch (exception) 
        {
            if (!environment.production) 
            {
                console.log('#EXCEPTION::FrontEndService::getEventHandler ', exception);
            }
        }
    }

}