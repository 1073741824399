import { EventEmitter, Injectable, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from '../../environments/environment'
import { 
  ISessionLive, 
  IGetKeyValuePairBySessionId, 
  Iiq_GetDeviceList, 
  IGetSessionKVP,
  IEventHandler,
  ISessionLite
} from '../interface/i-responses';
import { MobileDataService } from './mobile-data.service';
import { interval } from 'rxjs'; 
import { PageTitleService } from '../core/page-title/page-title.service';
import { ICoreSocketPayload } from '../interface/i-requests';
import { IReturnCode } from '../interface/i-mobile-test';
import { IApogeeConfig, IApogeeDashboard, IFullClientConfig, IInquestConfigClient, ISessionLogin } from '../interface/i-dynamo';
import {v4 as uuidv4} from 'uuid';

@Injectable()
export class  CoreDataService implements OnInit {

  constructor(
    private mobileDataService: MobileDataService,
    private pageTitleService: PageTitleService,
    ) {
    }
  errorMessage: string;
  core_iiq_GetDeviceList = new EventEmitter<Iiq_GetDeviceList[]>();
  private reloadSubscription: Subscription;
  reloadInterval: number = 290000; 
  iGetKeyValuePairBySessionId: IGetKeyValuePairBySessionId[];
  iGetSessionKVP: IGetSessionKVP[];
  public coreSocket: WebSocket;
  public isCoreSocketOpen: boolean = false;
  iSessionLive: ISessionLive;
  iCoreSocketPayload: ICoreSocketPayload;
  iReturnCode: IReturnCode[];
  beenHere: boolean = false
  iEventHandler: IEventHandler = {
    Event: '',
    Payload: ''
  }

   /*----------------------------------------------------------------------------------------------
   Send a heartbeat to Websocket to keep it alive
   [Denver Naidoo - 21 Sep 2021]
   ----------------------------------------------------------------------------------------------*/
   coreSocketHeartBeat() {
    try {
      this.iCoreSocketPayload = {
        "event": "i_inquest.pr_add_session_kvp",
        "params": "'CORE_WS_HEART_BEAT', 'utc_timestamp()', '" + this.iSessionLive.SessionId + "'"
      }
      this.sendMessage(this.iCoreSocketPayload);
      if (this.iSessionLive.SessionId > 0) {
        if (!this.checkedIn) {
          this.addSesssionDynamo();
        }
      }
    }
    catch (exception) {
      if (!environment.production) {
        console.log('#EXCEPTION: CoreDataService: initialise()', exception);
      }
    }
  }

  checkedIn: boolean = false;
  addSesssionDynamo() {
    try {
        // Log the Sessoin to Dynamo [Denver Naidoo - 30 Nov 2021]
        if (this.iSessionLive) {
          let iSessionLite: ISessionLite = {
            'SessionId': this.iSessionLive.SessionId,
            'UserId': this.iSessionLive.UserId,
            'FirstName': this.iSessionLive.FirstName,
            'LastName': this.iSessionLive.LastName,
            'Email': this.iSessionLive.Email,
            'ClientId': this.iSessionLive.ClientId,
            'UserName': this.iSessionLive.UserName,
            'IsLoggedIn': this.iSessionLive.IsLoggedIn,
            'NickName': this.iSessionLive.NickName
          }
            let iSessionLogin: ISessionLogin = {
                'pk': `${this.iSessionLive.ClientId}#SESSION`,
                'sk': `${this.iSessionLive.SessionId}`,  
                'SessionLive': iSessionLite             
            }
            this.mobileDataService.fargo_dynamo('update_dynamo', iSessionLogin).subscribe((response) => {
              this.checkedIn = true;
            });
        }
    }
    catch (exception) {
        if(!environment.production){
          console.log('#EXCEPTION::DashboardComponent::addSesssionDynamo() ', exception);
        }
      }
}

  // ----------------------------------------------------------------------------------------------
  ngOnInit() {
    try {      
      this.initialise();
    }
    catch (exception) {
      if (!environment.production) {
        console.log('#EXCEPTION: CoreDataService: ngOnInit()', exception);
      }
    }
  }

  // ----------------------------------------------------------------------------------------------
  initialise() {
    try {
      this.getSessionLive();
      this.getCoreSocketPayload();
      this.coreSocketHeartBeat();
      this.reloadSubscription = interval(this.reloadInterval).subscribe(() => this.coreSocketHeartBeat());
      //this.mobileDataService.getCurrentWebSocketConnectionId(true);
    }
    catch (exception) {
      if (!environment.production) {
        console.log('#EXCEPTION: CoreDataService: initialise()', exception);
      }
    }
  }

  // ----------------------------------------------------------------------------------------------
  getSessionLive() {
    try {
      this.pageTitleService.sessionLive.subscribe((iSessionLive: ISessionLive) => {        
        if (iSessionLive) {
          this.iSessionLive = iSessionLive;
          if (iSessionLive.SessionId && !this.beenHere) {
            this.setCoreSocketPayloadSession(iSessionLive.SessionId);
            this.setCoreSocketServiceURL();
            this.beenHere = true;
          }
        }
      });      
    }
    catch (exception) {
      if (!environment.production) {
        console.log('#EXCEPTION: getSessionLive()', exception);
      }
    }
  }

  // ----------------------------------------------------------------------------------------------
  setCoreSocketPayload(iCoreSocketPayload: ICoreSocketPayload){
    try {
       this.pageTitleService.setCoreSocketPayload(iCoreSocketPayload);
    }
    catch (exception) {
      if (!environment.production) {
        console.log('#setEventHandler: ', exception);
      }
    }
  }

  // ----------------------------------------------------------------------------------------------
   setCoreSocketPayloadSession(sessionId) {
    try {
      if (sessionId > 0) {
        this.iCoreSocketPayload = {
          "event": "ADD_CORE_WS_CONNECTION_ID",
          "params": sessionId
        }
      }
    }
    catch (exception) {
      if (!environment.production) {
        console.log('#EXCEPTION: CoreDataService: addWebsocketSession()', exception);
      }
    }
  }  

  // ----------------------------------------------------------------------------------------------
  setCoreSocketServiceURL() {
    try {
      let keyName: string = 'CORE_SOCKET_SERVICE';
      let typeId: number = 1;
       this.mobileDataService.pr_GetKeyValuePairBySessionId(keyName, typeId).subscribe({
        next: (iGetKeyValuePairBySessionId: IGetKeyValuePairBySessionId[]) => 
          {
            if (iGetKeyValuePairBySessionId) {
              this.iGetKeyValuePairBySessionId = iGetKeyValuePairBySessionId;
              if (iGetKeyValuePairBySessionId[0].KeyValue) {
                this.openLiveSocket(iGetKeyValuePairBySessionId[0].KeyValue);
              }
            }
          }        
      });
    }
    catch (exception) {
      if (!environment.production) {
        console.log('#EXCEPTION: CoreSocketService: getCoreSocketURL()', exception);
      }
    }
  }

  // ----------------------------------------------------------------------------------------------
  openLiveSocket(socketURL) {
    try {
      if (!this.isCoreSocketOpen) {
        this.coreSocket = new WebSocket(socketURL);

        this.coreSocket.addEventListener('open', e => {
          this.isCoreSocketOpen = true;
          this.sendMessage(this.iCoreSocketPayload);
          this.coreSocketHeartBeat();
        });

        // socket is closed
        this.coreSocket.addEventListener('close', e => {          
          this.isCoreSocketOpen = false;
          this.setCoreSocketServiceURL();
          
        });

        this.coreSocket.addEventListener('error', e => {
          
        });

        // Response on Socket
        this.coreSocket.addEventListener('message', e => {
          if (!environment.production) {
            // console.log('Response from WS: ', JSON.parse(e.data).message)
            // console.log('eJason: ', JSON.parse(e.data));
          }
          if (JSON.parse(e.data).event) {
            var event = JSON.parse(e.data).event;
            this.iEventHandler.Event = event;
            this.iEventHandler.Payload = JSON.parse(e.data).message;
            this.setEventHandler(this.iEventHandler);
            // var params = ''
            // this[methodName](params);
            }
          });        
      }
    }
    catch (exception) {
      if (!environment.production) {
        console.log('#EXCEPTION::SingleDeviceComponent::sendRequestToDevice() ', exception);
      }
    }
  }  

  // ----------------------------------------------------------------------------------------------
  setEventHandler(iEventHandler: IEventHandler){
    try {
       this.pageTitleService.setEventHandler(iEventHandler);
    }
    catch (exception) {
      if (!environment.production) {
        console.log('#setEventHandler: ', exception);
      }
    }
  }

  // ----------------------------------------------------------------------------------------------
  // send message to websocket
  sendMessage(message_payload) {
    const payload = {
      action: 'message',
      payload: message_payload
    }
    if (this.isCoreSocketOpen) {
      this.coreSocket.send(JSON.stringify(payload));
    }
  }

  // ----------------------------------------------------------------------------------------------
  pr_iq_GetDeviceList() {
    try {      
      this.mobileDataService.getDeviceList().subscribe({
        next: (responseData: Iiq_GetDeviceList[]) => 
          {
            if (responseData) {              
              this.core_iiq_GetDeviceList.emit(responseData);
            }
          }        
      });
    }
    catch (exception) {
      if (!environment.production) {
        console.log('#EXCEPTION: CoreDataService: pr_iq_GetDeviceList()', exception);
      }
    }
  }

  //-----------------------------------------------------------------------------------------------
  getCoreSocketPayload() {
    try {
      this.pageTitleService.iCoreSocketPayload.subscribe((iCoreSocketPayload: ICoreSocketPayload) => {
        this.iCoreSocketPayload = iCoreSocketPayload;
        if (iCoreSocketPayload) {
          this.sendMessage(this.iCoreSocketPayload);
        }
      });      
    }
    catch (exception) {
      if (!environment.production) {
        console.log('#EXCEPTION: ', exception);
      }
    }
  }

  // ----------------------------------------------------------------------------------------------
  ngOnDestroy(){
    this.reloadSubscription.unsubscribe();
  }


  fullClientConfig:IFullClientConfig = {};
  async getClientConfiguration(){
    try {
      let InquestClientId = this.iSessionLive.ClientId;
      let clientConfigPK = {pk: `${InquestClientId}#INQUEST_CONFIG`};
      let apogeeDynamoKey = {
          pk:`${InquestClientId}#APOGEE_DASHBOARDS`,
          sk:'APOGEE_DASHBOARDS'
      }

      this.fullClientConfig.INQUEST_CONFIG = await this.mobileDataService.fargo_dynamo('get_dynamo_by_pk', clientConfigPK).toPromise();
      this.fullClientConfig.APOGEE_DASHBOARDS = await this.mobileDataService.fargo_dynamo('get_dynamo_by_pk_sk', apogeeDynamoKey).toPromise();
      return this.fullClientConfig;
      
    }
    catch (exception) {
      if (!environment.production) {
        console.log('#EXCEPTION: ', exception);
      }
    }
  }

}