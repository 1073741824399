import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { ICoreSocketPayload } from '../../interface/i-requests';
import { environment } from '../../../environments/environment';
import { Iiq_GetDeviceList, ITestRun, ISessionTestRun, ISessionLive, IAppStore, IEventHandler } from '../../interface/i-responses';
import { Subject } from 'rxjs';

@Injectable()
export class PageTitleService {
    public title: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public sessionDeviceList: BehaviorSubject<Iiq_GetDeviceList> = new BehaviorSubject<Iiq_GetDeviceList>(null)
    public freeFormDeviceId: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public freeFormTestBatchId: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public freeFormATCommandId: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public testRun: BehaviorSubject<ITestRun> = new BehaviorSubject<ITestRun>(null);
    public sessionTestRun: BehaviorSubject<ISessionTestRun> = new BehaviorSubject<ISessionTestRun>(null);
    public sessionLive: BehaviorSubject<ISessionLive> = new BehaviorSubject<ISessionLive>(null);
    public appStore: BehaviorSubject<IAppStore> = new BehaviorSubject<IAppStore>(null);    
    public iAppStore: IAppStore;
    public iEventHandler: Subject<IEventHandler> = new Subject<IEventHandler>();
    public iCoreSocketPayload: BehaviorSubject<ICoreSocketPayload> = new BehaviorSubject<ICoreSocketPayload>(null);
    public iReusableBag: BehaviorSubject<[]> = new BehaviorSubject<[]>(null);    

    setReusableBag(value: []) {
        this.iReusableBag.next(value);
    }

    setCoreSocketPayload(iCoreSocketPayload: ICoreSocketPayload) {
        try {
            this.iCoreSocketPayload.next(iCoreSocketPayload);
        }
        catch (exception) {
            if (!environment.production) {
                console.log('#EXCEPTION::PageTitleService::setCoreSocketPayload ', exception);
              }
        }
    }

    setEventHandler(iEventHandler: IEventHandler) {
        try {            
            this.iEventHandler.next(iEventHandler);
        }
        catch (exception) {
            if (!environment.production) {
                console.log('#EXCEPTION::PageTitleService::setEventHandler ', exception);
              }
        }
    }

    setAppStore(value: IAppStore) {
        try {            
            this.appStore.next(value);
        }
        catch (exception) {
            if (!environment.production) {
                console.log('#EXCEPTION::PageTitleService::setAppStore ', exception);
              }
          }
    }

    setSessionLive(value: ISessionLive) {
        this.sessionLive.next(value);
    }

    setSessionTestRun(value: ISessionTestRun) {
        this.sessionTestRun.next(value);
    }

    setTestRun(value: ITestRun) {
        this.testRun.next(value);
    }

    setTitle(value: string) {
        this.title.next(value);
    }

    setFreeFormDeviceId(value: string) {
        this.freeFormDeviceId.next(value);
    }

    setFreeFormTestBatchId(value: string) {
        this.freeFormTestBatchId.next(value);
    }

    setFreeFormATCommandId(value: string) {
        this.freeFormDeviceId.next(value);
    }

    setSelectedDevice(iiq_GetDeviceList: Iiq_GetDeviceList) {
        this.sessionDeviceList.next(iiq_GetDeviceList);
    }
}