import { Injectable } from '@angular/core';

export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
  subchildren? : SuperChildrenItems[];
}

export interface SuperChildrenItems {
  state: string;
  name: string;
  type?: string;
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  children?: ChildrenItems[];
}

let HORIZONTALMENUITEMS : Menu[] = [
  {
    state: 'landing-page',
    name: 'HOME',
    type: 'link',
    icon: 'home'
  },
  /*
  {
    state: 'features',
    name: 'FEATURES',
    type: 'sub',
    icon: 'library_books',
    children: [
      {state: 'features/colorpicker', name: 'COLOR PICKER'},
      {state: 'features/cropper', name: 'CROPPER'},
      {state: 'features/dragula', name: 'DRAGULA'},
      {state: 'features/sortable', name: 'SORTABLE'},
      { state: 'icons', 
        name: 'ICONS', 
        type: "super-sub",     
        subchildren: [
          {state: 'icons/linea', name: 'LINEA'},
          {state: 'icons/font-awesome', name: 'FONT AWESOME'},
          {state: 'icons/simple-line-icons', name: 'SIMPLE LINE ICONS'},
          {state: 'icons/material-icons', name: 'MATERIAL ICONS'},
        ] 
      },
      { state: 'editor', 
        name: 'EDITOR', 
        type: "super-sub",     
        subchildren: [
          {state: 'editor/ace-editor', name: 'ACE EDITOR'},
          {state: 'editor/ckeditor', name: 'CKEDITOR'},
          {state: 'editor/wysiwyg', name: 'WYSIWYG EDITOR'}
        ] 
      }
    ]
  },
  {
    state: 'components',
    name: 'COMPONENTS',
    type: 'sub',
    icon: 'local_activity',
    children: [
      {state: 'components/accordion', name: 'ACCORDION'},
      {state: 'components/alerts', name: 'ALERTS'},
      {state: 'components/buttons', name: 'BUTTONS'},
      {state: 'components/carousel', name: 'CAROUSEL'},
      {state: 'components/collapse', name: 'COLLAPSE'},
      {state: 'components/datepicker', name: 'DATEPICKER'},
      {state: 'components/dropdown', name: 'DROPDOWN'},
      {state: 'components/modal', name: 'MODAL'},
      {state: 'components/pagination', name: 'PAGINATION'},
      {state: 'components/popover', name: 'POPOVER'},
      {state: 'components/progressbar', name: 'PROGRESS BAR'},
      {state: 'components/rating', name: 'RATING'},
      {state: 'components/tabs', name: 'TABS'},
      {state: 'components/timepicker', name: 'TIMEPICKER'},
      { state: 'cards', 
        name: 'CARDS', 
        type: "super-sub",     
        subchildren: [
          {state: 'cards/basic-cards', name: 'BASIC CARDS'},
          {state: 'cards/advance-cards', name: 'ADVANCE CARDS'}
        ] 
      }

    ]
  },
  {
    state: 'forms',
    name: 'FORMS',
    type: 'sub',
    icon: 'format_align_justify',
    children: [
      {state: 'forms/form-wizard', name: 'FORM CONTROLS'},
      {state: 'forms/form-validation', name: 'FORM VALIDATION'},
      {state: 'forms/form-upload', name: 'UPLOAD'},
      {state: 'forms/form-tree', name: 'TREE'}
    ]
  },
  {
    state: 'tables',
    name: 'TABLES',
    type: 'sub',
    icon: 'grid_on',
    children: [
      {state: 'tables/basic-tables', name: 'BASIC TABLES'},
      {state: 'tables/responsive-tables', name: 'RESPONSIVE TABLES'},
      { state: 'data-tables', 
        name: 'DATA TABLES', 
        type: "super-sub",     
        subchildren: [
          {state: 'data-tables/selection', name: 'SELECTION'},
          {state: 'data-tables/pinning', name: 'PINNING'},
          {state: 'data-tables/sorting', name: 'SORTING'},
          {state: 'data-tables/paging', name: 'PAGING'},
          {state: 'data-tables/editing', name: 'EDITING'},
          {state: 'data-tables/filter', name: 'FILTER'}
        ] 
      }
    ]
  },
  {
    state: 'chart',
    name: 'CHARTS',
    type: 'sub',
    icon: 'insert_chart',
    children: [
      {state: 'chart/bar-charts', name: 'BAR'},
      {state: 'chart/pie-charts', name: 'PIE'},
      {state: 'chart/line-charts', name: 'LINE'},
      {state: 'chart/mixed-charts', name: 'MIXED'},
      {state: 'chart/ng2-charts', name: 'NG2 CHARTS'},
    ]
  },
    {
    state: 'maps',
    name: 'MAPS',
    type: 'sub',
    icon: 'map',
    children: [
      {state: 'maps/googlemap', name: 'GOOGLE MAP'},
      {state: 'maps/leafletmap', name: 'LEAFLET MAP'}
    ]
  },
    {
    state: 'pages',
    name: 'PAGES',
    type: 'sub',
    icon: 'web',
    children: [
      {state: 'emails', name: 'Emails'},
      {state: 'chat', name: 'CHAT'},
      {state: 'calendar', name: 'CALENDAR'},
      {state: 'pages/about', name: 'ABOUT'},
      {state: 'pages/contact', name: 'CONTACT'},
      {state: 'pages/timeline', name: 'TIMELINE'},
      {state: 'pages/pricing', name: 'PRICING'},
      {state: 'pages/blank', name: 'BLANK'},
      { state: 'user-pages', 
        name: 'USERS', 
        type: "super-sub",     
        subchildren: [
          {state: 'user-pages/userlist', name: 'USER LIST'},
          {state: 'user-pages/userprofile', name: 'USER PROFILE'}
        ] 
      },
      { state: 'media', 
        name: 'MEDIA', 
        type: "super-sub",     
        subchildren: [
          {state: 'media/grid', name: 'GRID'},
          {state: 'media/list', name: 'LIST'}
        ] 
      }
    ]
  },
  {
    state: 'authentication',
    name: 'AUTHENTICATION',
    type: 'sub',
    icon: 'security',
    children: [
      {state: 'authentication/login', name: 'LOGIN'},
      {state: 'authentication/register', name: 'REGISTER'},
      {state: 'authentication/forgot-password', name: 'FORGOT'},
      {state: 'authentication/lockscreen', name: 'LOCKSCREEN'},
      { state: 'error', 
        name: 'ERROR', 
        type: "super-sub",     
        subchildren: [
          {state: 'error/404', name: '404'},
          {state: 'error/503', name: '503'}
        ] 
      }
    ]
  }
  */

];


import { IGroupPolicy, IPrimaryGroupPolicy, GroupPolicyLookupMap, GroupPolicyMenuMap, GroupPolicyRibbonMap, AcceptableGroupPolicies } from '../../../interface/i-application';
import { environment } from '../../../../environments/environment';
import { IApogeeDashboard, IFullClientConfig } from '../../../interface/i-dynamo';

@Injectable()
export class HorizontalMenuItems {
  // getAll(): Menu[] {
  //   return HORIZONTALMENUITEMS;
  // }


  getAll(isLoggedIn: boolean=false): Menu[] {
    if (isLoggedIn) {
      return HORIZONTALMENUITEMS;
    }
    else {
      return
    }
  }

  add(menu: Menu) {
    HORIZONTALMENUITEMS.push(menu);
  }

  purgeMenu(){
    HORIZONTALMENUITEMS = [
      {
        state: 'landing-page',
        name: 'HOME',
        type: 'link',
        icon: 'home'
      },];
  }

  isGodModeActive:boolean = false;
  loginHasPermissions(userPermissions: string): boolean {
    userPermissions = 'GodMode,' + userPermissions;

    var userGroups = sessionStorage.getItem('Gollum');
    var userGroupsList = userGroups.split(',');
    var userPermissionsList = userPermissions.split(',');
    var returnValue: boolean = false;

    userPermissionsList.forEach(userPermission => {
        userGroupsList.forEach(userGroup => {
            if(userGroup == "GodMode")
            {
              this.isGodModeActive = true;
              returnValue = true;
            }
            if (userPermission === userGroup) {
                returnValue = true;
            }
        });
    });
    return returnValue;
  }


  // -------------------------------------------------------------------------------------------------
  //  Marwyk permissions verification
  // -------------------------------------------------------------------------------------------------
  iPrimaryGroupPolicy:IPrimaryGroupPolicy;
  iGroupPolicies:IGroupPolicy[];
  fillGroupPolicies()
  {
    try
    {
      let sessionGPO:IPrimaryGroupPolicy = JSON.parse(atob(sessionStorage.getItem("GollumPrimary")));
      this.iPrimaryGroupPolicy = sessionGPO;
      //get all GPOs from Primary groups
      let gpoList:IGroupPolicy[] = [];

      let groupMenuMap = {...GroupPolicyMenuMap};
      let groupLookupMap = {...GroupPolicyLookupMap}
      let groupRibbonMap = {...GroupPolicyRibbonMap};

      sessionGPO.Group.forEach(group => {
          if(AcceptableGroupPolicies.includes(group))
          {
            let newGroup:IGroupPolicy = {};
            newGroup.Group = group;
            newGroup.Menus = groupMenuMap[group]
            newGroup.Lookups = groupLookupMap[group];
            newGroup.Ribbons = groupRibbonMap[group];
            gpoList.push(newGroup);
          }
      });

      this.iGroupPolicies = gpoList;
    }
    catch (exception) {
      if(!environment.production){
        console.log('#EXCEPTION::HorizontalMenuItems::fillGroupPolicies() ', exception);
      }
    }
  }

  



  /***************************************************************************************/
  /********************** START : menuItem_InQuest_USSD Sub Components **********************/
  /***************************************************************************************/ 
  // mobile_device_config(userPermissions: string) {
  //   if (this.loginHasPermissions(userPermissions)){ 
  //     let child: ChildrenItems = 
  //     { 
  //       state: 'landing-page',
  //       name: 'Configure Devices',
  //       type: 'super-sub',
  //       subchildren: [
  //         {state: 'mobile-device/mobile-device-config', name: 'Configure Devices'},
  //             //{state: 'view-service', name: 'View Service'},
  //       ]
  //     };
  //     return child;
  //   }
  //   else {
  //     return(this.locked_child_menu())
  //   }
  // }

  mobile_test_collection(allowedMenus:string[] = []) {
      try
      {
        let child: ChildrenItems = 
        {
          state: 'landing-page',
          name: 'Test Collection',
          type: 'super-sub',
          subchildren: [
            //{state: 'mobile-test-ussd/mobile-test-free-form-testing',  name: 'Functional Testing'},
            //{state: 'mobile-test-ussd/mobile-test-test-case',  name: 'Positive Test Cases'},
            //{state: 'mobile-test-ussd/mobile-test-negative-test-case',  name: 'Negative Test Cases'},
            //{state: 'mobile-test-ussd/mobile-test-test-chain',  name: 'Test Chains'},
            //{state: 'mobile-test-ussd/mobile-test-test-suite',  name: 'Test Suites'},
          ]
        };

        (this.isGodModeActive || allowedMenus.includes("FunctionalTesting")) && child.subchildren.push({state: 'mobile-test-ussd/mobile-test-free-form-testing',  name: 'Functional Testing'});
        (this.isGodModeActive || allowedMenus.includes("PositiveTestCase")) && child.subchildren.push({state: 'mobile-test-ussd/mobile-test-test-case',  name: 'Positive Test Cases'});
        (this.isGodModeActive || allowedMenus.includes("NegativeTestCase")) && child.subchildren.push({state: 'mobile-test-ussd/mobile-test-negative-test-case',  name: 'Negative Test Cases'});
        (this.isGodModeActive || allowedMenus.includes("TestChains")) && child.subchildren.push({state: 'mobile-test-ussd/mobile-test-test-chain',  name: 'Test Chains'});
        (this.isGodModeActive || allowedMenus.includes("TestSuites")) && child.subchildren.push({state: 'mobile-test-ussd/mobile-test-test-suite',  name: 'Test Suites'});
        (this.isGodModeActive || allowedMenus.includes("TestSchedules")) && child.subchildren.push({state: 'mobile-test-ussd/mobile-test-test-schedule',  name: 'Test Schedules'});

        return child;
      }
      catch (exception) {
        if(!environment.production){
          console.log('#EXCEPTION::HorizontalMenuItems::mobile_test_collection() ', exception);
        }
      }
  }

  locked_child_menu() {
    let child: ChildrenItems = 
    {
      state: 'landing-page',
      name: 'LOCKED',
      type: 'super-sub',
      subchildren: [
        {state: '',name: 'LOCKED'}
      ]
    };  
    return child;
  }

  mobile_test_schedule(allowedMenus:string[] = []) {
      try
      {
        let child: ChildrenItems = 
        {
          state: 'landing-page',
          name: 'Test Schedule',
          type: 'super-sub',
          subchildren: [
            //{state: 'mobile-test-schedule/mobile-test-schedule-create', name: 'Create Test Schedule'}
          ]
        };
        (this.isGodModeActive || allowedMenus.includes("CreateTestSchedule")) && child.subchildren.push({state: 'mobile-test-schedule/mobile-test-schedule-create', name: 'Create Test Schedule'});
        return child;
      }
      catch (exception) {
        if(!environment.production){
          console.log('#EXCEPTION::HorizontalMenuItems::mobile_test_collection() ', exception);
        }
      }
  }

  mobile_test_reports(allowedMenus:string[] = []) {
    try
    {
      let child: ChildrenItems = 
      {
        state: 'landing-page',
        name: 'Reports',
        type: 'super-sub',
        subchildren: [
          //{state: 'mobile-test-report/report-24hr',                   name: 'Past 24 Hours'},
          //{state: 'mobile-test-report/report-userInvokedUSSD',        name: 'User Invoked USSD'},
          //{state: 'mobile-test-report/report-simpletransactiontest',  name: 'Test Cases'},
          //{state: 'mobile-test-report/report-servicetest',            name: 'Negative Test Cases'},
          //{state: 'mobile-test-report/report-complextest',            name: 'Test Chains'},
          //{state: 'mobile-test-report/report-groupedtest',            name: 'Test Suites'},
          // {state: 'mobile-test-report/dashboard-live',                name: 'Live Dashboard'},
          // {state: 'mobile-test-report/dashboard-account-balance',     name: 'Latest Balances'},
          //{state: 'mobile-test-report/report-currentexecutions',      name: 'Current and Pending Tests'},
        ]
      };
      (this.isGodModeActive || allowedMenus.includes("Past24Hours")) && child.subchildren.push({state: 'mobile-test-report/report-24hr',                   name: 'Past 24 Hours'});
      (this.isGodModeActive || allowedMenus.includes("UserInvokceUSSD")) && child.subchildren.push({state: 'mobile-test-report/report-userInvokedUSSD',        name: 'User Invoked USSD'});
      (this.isGodModeActive || allowedMenus.includes("TestCases")) && child.subchildren.push({state: 'mobile-test-report/report-simpletransactiontest',  name: 'Test Cases'});
      (this.isGodModeActive || allowedMenus.includes("NegativeTestCases")) && child.subchildren.push({state: 'mobile-test-report/report-servicetest',            name: 'Negative Test Cases'});
      (this.isGodModeActive || allowedMenus.includes("TestChains")) && child.subchildren.push({state: 'mobile-test-report/report-complextest',            name: 'Test Chains'});
      (this.isGodModeActive || allowedMenus.includes("TestSuites")) && child.subchildren.push({state: 'mobile-test-report/report-groupedtest',            name: 'Test Suites'});
      (this.isGodModeActive || allowedMenus.includes("CurrentAndPendingTests")) && child.subchildren.push({state: 'mobile-test-report/report-currentexecutions',      name: 'Current and Pending Tests'});
      return child;
    }
    catch (exception) {
      if(!environment.production){
        console.log('#EXCEPTION::HorizontalMenuItems::mobile_test_collection() ', exception);
      }
    }
  }

  mobile_dashboards(allowedMenus:string[] = []) {
    try
    {
      let child: ChildrenItems = 
      {
        state: 'landing-page',
        name: 'Dashboards',
        type: 'super-sub',
        subchildren: [
          //{state: 'mobile-dashboards/dashboard-mobile-test-positive-case-success-rate',  name: 'Positive Case - Success Rates'},
          //{state: 'mobile-dashboards/dashboard-mobile-test-positive-case-execution-time',  name: 'Positive Case - Execution Times'},
          // {state: 'mobile-dashboards/dashboard-mobile-test-negative-case-success-rate',  name: 'Negative Test - Success Rates'},
          // {state: 'mobile-dashboards/dashboard-mobile-test-negative-case-execution-time',  name: 'Negative Test - Execution Times'},
          // {state: 'mobile-dashboards/dashboard-mobile-test-test-chain-success-rate',  name: 'Test Chain - Success Rates'},
          // {state: 'mobile-dashboards/dashboard-mobile-test-test-chain-execution-time',  name: 'Test Chain - Execution Times'},
          // {state: 'mobile-dashboards/dashboard-mobile-test-test-suite-success-rate',  name: 'Test Suite - Success Rates'},
          // {state: 'mobile-dashboards/dashboard-mobile-test-test-suite-execution-time',  name: 'Test Suite - Execution Times'},
          // {state: 'mobile-dashboards/dashboard-mobile-wallet-balances',  name: 'Latest Wallet Balances'},

          //SANDBOX
          // {state: 'mobile-dashboards/dashboard-sandbox',              name: 'SANDBOX'},

        ]
      };
      (this.isGodModeActive || allowedMenus.includes("PositiveCaseSuccessRates")) && child.subchildren.push({state: 'mobile-dashboards/dashboard-mobile-test-positive-case-success-rate',  name: 'Positive Case - Success Rates'});
      (this.isGodModeActive || allowedMenus.includes("PositiveCaseExecutionTimes")) && child.subchildren.push({state: 'mobile-dashboards/dashboard-mobile-test-positive-case-execution-time',  name: 'Positive Case - Execution Times'});
      return child;
    }
    catch (exception) {
      if(!environment.production){
        console.log('#EXCEPTION::HorizontalMenuItems::mobile_test_collection() ', exception);
      }
    }
  }
  /***************************************************************************************/
  /********************** END : menuItem_InQuest_USSD Sub Components **********************/
  /***************************************************************************************/ 





  /***************************************************************************************/
  /********************** START : menuItem_BigData Sub Components **********************/
  /***************************************************************************************/ 
  big_data_architecture(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions)){ 
      let child: ChildrenItems = 
      {
        state: 'landing-page',
        name: 'Big Data Architecture',
        type: 'super-sub',
        subchildren: [
          {state: 'big-data-hld/requirements-analysis', name: 'Requirements'},
          {state: 'big-data-hld/source-data-schema', name: 'Data Schema'},
          {state: 'big-data-hld/high-level-design', name: 'High Level Design'},
          {state: 'big-data-hld/low-level-design', name: 'Low Level Design'},
          {state: 'big-data-hld/ingestion-relational-data', name: 'Ingest Relational Data'},
          {state: 'big-data-hld/bulk-ingest-data', name: 'Bulk Ingest Data'},
          {state: 'big-data-hld/hdfs-storage', name: 'HDFS Storage'},
          {state: 'big-data-tech-talk/tech-talk', name: 'Tech Talk'}
        ]
      };
      return child;
    }
    else {
      return(this.locked_child_menu())
    }
  }

  master_machine_learning(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions)){ 
      let child: ChildrenItems = 
      {
        state: 'landing-page',
        name: 'Machine Learning',
        type: 'super-sub',
        subchildren: [
          {state: 'big-data-ml/ml-item-1', name: 'Data Versioning'},
          {state: 'big-data-ml/ml-item-2', name: 'ML Pipelines'},
          {state: 'big-data-ml/ml-item-3', name: 'ML Feedback'},
        ]
      };
      return child;
    }
    else {
      return(this.locked_child_menu())
    }
  }

  master_data_management(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions)){ 
      let child: ChildrenItems = 
      {
        state: 'landing-page',
        name: 'Master Data Management',
        type: 'super-sub',
        subchildren: [
          {state: 'big-data-mdm/mdm-item-1', name: 'MDM Objectives'},
          {state: 'big-data-mdm/mdm-item-2', name: 'Classification'},
          {state: 'big-data-mdm/mdm-item-3', name: 'Maturity Model'},
          {state: 'big-data-mdm/mdm-item-4', name: 'MDM Building Blocks'}
        ]
      };
      return child;
    }
    else {
      return(this.locked_child_menu())
    }
  }

  information_systems_architecture(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions)){ 
      let child: ChildrenItems = 
      {
        state:'landing-page',
        name: 'Information Systems Architecture',
        type: 'super-sub',
        subchildren: [
          {state: 'big-data-is-architecture/item-1', name: 'Information Systems Architecture'},
          {state: 'big-data-is-architecture/item-2', name: 'Frameworks and Design'}
        ]
      };
      return child;
    }
    else {
      return(this.locked_child_menu())
    }
  }

  lambda_architecture(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions)){ 
      let child: ChildrenItems = 
      { 
        state:'landing-page', 
        name: 'Lambda Architecture',
        type: "super-sub",
        subchildren: [
          {state: 'big-data-lambda/item-1', name: 'Lambda Architecture'},
          // {state: 'big-data-lambda/item-2', name: 'Batch Layer'},
          // {state: 'big-data-lambda/item-3', name: 'Speed Layer'},
          // {state: 'big-data-lambda/item-4', name: 'Serving Layer'}
        ]
      };
      return child;
    }
    else {
      return(this.locked_child_menu())
    }
  }

  data_processing(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions)){ 
      let child: ChildrenItems = 
      { 
        state:'landing-page',
        name: 'Data Processing',
        type: 'super-sub',
        subchildren: [
          {state: 'big-data-data-processing/data-processing-patterns', name: 'Data Processing Patterns'},
          {state: 'big-data-data-processing/avro', name: 'Avro'},
          {state: 'big-data-data-processing/processing-overview', name: 'Processing Overview'},

        ]
      };
      return child;
    }
    else {
      return(this.locked_child_menu())
    }
  }

  data_analytics(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions)){ 
      let child: ChildrenItems = 
      { 
        state:'landing-page',
        name: 'Data Analytics',
        type: 'super-sub',
        subchildren: [
          {state: 'big-data-analytics/analytics-and-query', name: 'Analytics and Query'},
          {state: 'big-data-analytics/hive-tuning', name: 'Hive Tuning'},
        ]
      };
      return child;
    }
    else {
      return(this.locked_child_menu())
    }
  }

  oracle_to_grafana_s(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions)){ 
      let child: ChildrenItems = 
      { 
        state:'landing-page',
        name: 'Oracle to Grafana (S)',
        type: 'super-sub',
        subchildren: [
          {state: 'big-data-oracle-to-grafana-s/system-viewpoint', name: 'System Viewpoint'},
          // {state: 'oracle-to-grafana-s/technology-viewpoint', name: 'Technology Viewpoint'},
          // {state: 'oracle-to-grafana-s/building-blocks', name: 'Architecture Blocks'},
          // {state: 'oracle-to-grafana-s/block-1', name: 'Building Block 1'},
          // {state: 'oracle-to-grafana-s/block-2', name: 'Building Block 2'},
          // {state: 'oracle-to-grafana-s/block-3', name: 'Building Block 3'},
          // {state: 'oracle-to-grafana-s/block-4', name: 'Building Block 4'},
          // {state: 'oracle-to-grafana-s/block-5', name: 'Building Block 5'},
          // {state: 'oracle-to-grafana-s/block-6', name: 'Building Block 6'},
          // {state: 'oracle-to-grafana-s/block-7', name: 'Building Block 7'},
          // {state: 'oracle-to-grafana-s/block-8', name: 'Building Block 8'},
          // {state: 'oracle-to-grafana-s/block-9', name: 'Building Block 9'},
          // {state: 'oracle-to-grafana-s/block-10', name: 'Building Block 10'},
          // {state: 'oracle-to-grafana-s/block-11', name: 'Building Block 11'},
          // {state: 'oracle-to-grafana-s/block-12', name: 'Building Block 12'},
          // {state: 'oracle-to-grafana-s/block-13', name: 'Building Block 13'},
          // {state: 'oracle-to-grafana-s/hl-dfd', name: 'High Level DFD'},
          // {state: 'oracle-to-grafana-s/dfd-1', name: 'DFD - Level 1'},
          // {state: 'oracle-to-grafana-s/dfd-2', name: 'DFD - Level 2'},
          // {state: 'oracle-to-grafana-s/dfd-3', name: 'DFD - Level 3'},
          // {state: 'oracle-to-grafana-s/detailed-data-source', name: 'Detailed Data Source'}
        ]
      };
      return child;
    }
    else {
      return(this.locked_child_menu())
    }
  }

  oracle_to_hdfs_s(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions)){ 
      let child: ChildrenItems = 
      {
        state:'landing-page',
        name: 'Oracle to HDFS (S)',
        type: 'super-sub',
        subchildren: [
          {state: 'big-data-relational-ingestion-hdfs-s/system-viewpoint', name: 'System Viewpoint'},
          // {state: 'big-data-relational-ingestion-hdfs-s/technology-viewpoint', name: 'Technology Viewpoint'},
          // {state: 'big-data-relational-ingestion-hdfs-s/building-blocks', name: 'Architecture Blocks'},
          // {state: 'big-data-relational-ingestion-hdfs-s/block-1', name: 'Building Block 1'},
          // {state: 'big-data-relational-ingestion-hdfs-s/block-2', name: 'Building Block 2'},
          // {state: 'big-data-relational-ingestion-hdfs-s/block-3', name: 'Building Block 3'},
          // {state: 'big-data-relational-ingestion-hdfs-s/block-4', name: 'Building Block 4'},
          // {state: 'big-data-relational-ingestion-hdfs-s/block-5', name: 'Building Block 5'},
          // {state: 'big-data-relational-ingestion-hdfs-s/block-6', name: 'Building Block 6'},
          // {state: 'big-data-relational-ingestion-hdfs-s/block-7', name: 'Building Block 7'},
          // {state: 'big-data-relational-ingestion-hdfs-s/block-8', name: 'Building Block 8'},
          // {state: 'big-data-relational-ingestion-hdfs-s/block-9', name: 'Building Block 9'},
          // {state: 'big-data-relational-ingestion-hdfs-s/hl-dfd', name: 'High Level DFD'},
          // {state: 'big-data-relational-ingestion-hdfs-s/dfd-1', name: 'DFD - Level 1'},
          // {state: 'big-data-relational-ingestion-hdfs-s/dfd-2', name: 'DFD - Level 2'},
          // {state: 'big-data-relational-ingestion-hdfs-s/dfd-3', name: 'DFD - Level 3'},
          // {state: 'big-data-relational-ingestion-hdfs-s/detailed-data-source', name: 'Detailed Data Source'}
        ]
      };
      return child;
    }
    else {
      return(this.locked_child_menu())
    }
  }

  oracle_to_hive_b(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions)){ 
      let child: ChildrenItems = 
      {
        state:'landing-page',
        name: 'Oracle to Hive2 (B)',
        type: 'super-sub',
        subchildren: [
          {state: 'big-data-oracle-to-hive-b/system-viewpoint', name: 'System Viewpoint'},
          // {state: 'oracle-to-hive-b/technology-viewpoint', name: 'Technology Viewpoint'},
          // {state: 'oracle-to-hive-b/building-blocks', name: 'Architecture Blocks'},
          // {state: 'oracle-to-hive-b/block-1', name: 'Building Block 1'},
          // {state: 'oracle-to-hive-b/block-2', name: 'Building Block 2'},
          // {state: 'oracle-to-hive-b/block-3', name: 'Building Block 3'},
          // {state: 'oracle-to-hive-b/block-4', name: 'Building Block 4'},
          // {state: 'oracle-to-hive-b/block-5', name: 'Building Block 5'},
          // {state: 'oracle-to-hive-b/block-6', name: 'Building Block 6'},
          // {state: 'oracle-to-hive-b/hl-dfd', name: 'High Level DFD'},
          // {state: 'oracle-to-hive-b/dfd-1', name: 'DFD - Level 1'},
          // {state: 'oracle-to-hive-b/dfd-2', name: 'DFD - Level 2'},
          // {state: 'oracle-to-hive-b/dfd-3', name: 'DFD - Level 3'},
          // {state: 'oracle-to-hive-b/detailed-data-source', name: 'Detailed Data Source'}
        ]
      };
      return child;
    }
    else {
      return(this.locked_child_menu())
    }
  }

  oracle_to_influx_s(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions)){ 
      let child: ChildrenItems = 
      {
        state:'landing-page',
        name: 'Oracle to Influx (S)',
        type: 'super-sub',
        subchildren: [
          {state: 'big-data-oracle-to-influxdb-s/system-viewpoint', name: 'System Viewpoint'},
          // {state: 'oracle-to-influxdb-s/technology-viewpoint', name: 'Technology Viewpoint'},
          // {state: 'oracle-to-influxdb-s/building-blocks', name: 'Architecture Blocks'},
          // {state: 'oracle-to-influxdb-s/block-1', name: 'Building Block 1'},
          // {state: 'oracle-to-influxdb-s/block-2', name: 'Building Block 2'},
          // {state: 'oracle-to-influxdb-s/block-3', name: 'Building Block 3'},
          // {state: 'oracle-to-influxdb-s/block-4', name: 'Building Block 4'},
          // {state: 'oracle-to-influxdb-s/block-5', name: 'Building Block 5'},
          // {state: 'oracle-to-influxdb-s/block-6', name: 'Building Block 6'},
          // {state: 'oracle-to-influxdb-s/block-7', name: 'Building Block 7'},
          // {state: 'oracle-to-influxdb-s/block-8', name: 'Building Block 8'},
          // {state: 'oracle-to-influxdb-s/block-9', name: 'Building Block 9'},
          // {state: 'oracle-to-influxdb-s/block-10', name: 'Building Block 10'},
          // {state: 'oracle-to-influxdb-s/hl-dfd', name: 'High Level DFD'},
          // {state: 'oracle-to-influxdb-s/dfd-1', name: 'DFD - Level 1'},
          // {state: 'oracle-to-influxdb-s/dfd-2', name: 'DFD - Level 2'},
          // {state: 'oracle-to-influxdb-s/dfd-3', name: 'DFD - Level 3'},
          // {state: 'oracle-to-influxdb-s/detailed-data-source', name: 'Detailed Data Source'}
        ]
      };
      return child;
    }
    else {
      return(this.locked_child_menu())
    }
  }

  oracle_to_neo4j_s(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions)){ 
      let child: ChildrenItems = 
      {
        state:'landing-page',
        name: 'Oracle to Neo4j (S)',
        type: 'super-sub',
        subchildren: [
          {state: 'big-data-oracle-to-neo4j-s/system-viewpoint', name: 'System Viewpoint'},
          // {state: 'oracle-to-neo4j-s/technology-viewpoint', name: 'Technology Viewpoint'},
          // {state: 'oracle-to-neo4j-s/building-blocks', name: 'Architecture Blocks'},
          // {state: 'oracle-to-neo4j-s/block-1', name: 'Building Block 1'},
          // {state: 'oracle-to-neo4j-s/block-2', name: 'Building Block 2'},
          // {state: 'oracle-to-neo4j-s/block-3', name: 'Building Block 3'},
          // {state: 'oracle-to-neo4j-s/block-4', name: 'Building Block 4'},
          // {state: 'oracle-to-neo4j-s/block-5', name: 'Building Block 5'},
          // {state: 'oracle-to-neo4j-s/block-6', name: 'Building Block 6'},
          // {state: 'oracle-to-neo4j-s/block-7', name: 'Building Block 7'},
          // {state: 'oracle-to-neo4j-s/block-8', name: 'Building Block 8'},
          // {state: 'oracle-to-neo4j-s/block-9', name: 'Building Block 9'},
          // {state: 'oracle-to-neo4j-s/hl-dfd', name: 'High Level DFD'},
          // {state: 'oracle-to-neo4j-s/dfd-1', name: 'DFD - Level 1'},
          // {state: 'oracle-to-neo4j-s/dfd-2', name: 'DFD - Level 2'},
          // {state: 'oracle-to-neo4j-s/dfd-3', name: 'DFD - Level 3'},
          // {state: 'oracle-to-neo4j-s/detailed-data-source', name: 'Detailed Data Source'}
        ]
      };
      return child;
    }
    else {
      return(this.locked_child_menu())
    }
  }

  oracle_to_redis_s(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions)){ 
      let child: ChildrenItems = 
      {
        state:'landing-page',
        name: 'Oracle to Redis (S)',
        type: 'super-sub',
        subchildren: [
          {state: 'big-data-oracle-to-redis-s/system-viewpoint', name: 'System Viewpoint'},
          // {state: 'big-data-oracle-to-redis-s/technology-viewpoint', name: 'Technology Viewpoint'},
          // {state: 'big-data-oracle-to-redis-s/building-blocks', name: 'Architecture Blocks'},
          // {state: 'big-data-oracle-to-redis-s/block-1', name: 'Building Block 1'},
          // {state: 'big-data-oracle-to-redis-s/block-2', name: 'Building Block 2'},
          // {state: 'big-data-oracle-to-redis-s/block-3', name: 'Building Block 3'},
          // {state: 'big-data-oracle-to-redis-s/block-4', name: 'Building Block 4'},
          // {state: 'big-data-oracle-to-redis-s/block-5', name: 'Building Block 5'},
          // {state: 'big-data-oracle-to-redis-s/block-6', name: 'Building Block 6'},
          // {state: 'big-data-oracle-to-redis-s/block-7', name: 'Building Block 7'},
          // {state: 'big-data-oracle-to-redis-s/block-8', name: 'Building Block 8'},
          // {state: 'big-data-oracle-to-redis-s/block-9', name: 'Building Block 9'},
          // {state: 'big-data-oracle-to-redis-s/hl-dfd', name: 'High Level DFD'},
          // {state: 'big-data-oracle-to-redis-s/dfd-1', name: 'DFD - Level 1'},
          // {state: 'big-data-oracle-to-redis-s/dfd-2', name: 'DFD - Level 2'},
          // {state: 'big-data-oracle-to-redis-s/dfd-3', name: 'DFD - Level 3'},
          // {state: 'big-data-oracle-to-redis-s/detailed-data-source', name: 'Detailed Data Source'}
        ]
      };
      return child;
    }
    else {
      return(this.locked_child_menu())
    }
  }

  oracle_to_scylla_s(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions)){ 
      let child: ChildrenItems = 
      {
        state:'landing-page',
        name: 'Oracle to Scylla (S)',
        type: 'super-sub',
        subchildren: [
          {state: 'big-data-oracle-to-scylla-s/system-viewpoint', name: 'System Viewpoint'},
          // {state: 'big-data-oracle-to-scylla-s/technology-viewpoint', name: 'Technology Viewpoint'},
          // {state: 'big-data-oracle-to-scylla-s/building-blocks', name: 'Architecture Blocks'},
          // {state: 'big-data-oracle-to-scylla-s/block-1', name: 'Building Block 1'},
          // {state: 'big-data-oracle-to-scylla-s/block-2', name: 'Building Block 2'},
          // {state: 'big-data-oracle-to-scylla-s/block-3', name: 'Building Block 3'},
          // {state: 'big-data-oracle-to-scylla-s/block-4', name: 'Building Block 4'},
          // {state: 'big-data-oracle-to-scylla-s/block-5', name: 'Building Block 5'},
          // {state: 'big-data-oracle-to-scylla-s/block-6', name: 'Building Block 6'},
          // {state: 'big-data-oracle-to-scylla-s/block-7', name: 'Building Block 7'},
          // {state: 'big-data-oracle-to-scylla-s/block-8', name: 'Building Block 8'},
          // {state: 'big-data-oracle-to-scylla-s/block-9', name: 'Building Block 9'},
          // {state: 'big-data-oracle-to-scylla-s/hl-dfd', name: 'High Level DFD'},
          // {state: 'big-data-oracle-to-scylla-s/dfd-1', name: 'DFD - Level 1'},
          // {state: 'big-data-oracle-to-scylla-s/dfd-2', name: 'DFD - Level 2'},
          // {state: 'big-data-oracle-to-scylla-s/dfd-3', name: 'DFD - Level 3'},
          // {state: 'big-data-oracle-to-scylla-s/detailed-data-source', name: 'Detailed Data Source'}
        ]
      };
      return child;
    }
    else {
      return(this.locked_child_menu())
    }
  }

  streams_to_grafana_s(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions)){ 
      let child: ChildrenItems = 
      {
        state:'landing-page',
        name: 'Streams to Grafana (S)',
        type: 'super-sub',
        subchildren: [
          {state: 'big-data-streams-to-grafana-s/system-viewpoint', name: 'System Viewpoint'},
          // {state: 'big-data-streams-to-grafana-s/technology-viewpoint', name: 'Technology Viewpoint'},
          // {state: 'big-data-streams-to-grafana-s/building-blocks', name: 'Architecture Blocks'},
          // {state: 'big-data-streams-to-grafana-s/block-1', name: 'Building Block 1'},
          // {state: 'big-data-streams-to-grafana-s/block-2', name: 'Building Block 2'},
          // {state: 'big-data-streams-to-grafana-s/block-3', name: 'Building Block 3'},
          // {state: 'big-data-streams-to-grafana-s/block-4', name: 'Building Block 4'},
          // {state: 'big-data-streams-to-grafana-s/block-5', name: 'Building Block 5'},
          // {state: 'big-data-streams-to-grafana-s/block-6', name: 'Building Block 6'},
          // {state: 'big-data-streams-to-grafana-s/block-7', name: 'Building Block 7'},
          // {state: 'big-data-streams-to-grafana-s/block-8', name: 'Building Block 8'},
          // {state: 'big-data-streams-to-grafana-s/block-9', name: 'Building Block 9'},
          // {state: 'big-data-streams-to-grafana-s/block-10', name: 'Building Block 10'},
          // {state: 'big-data-streams-to-grafana-s/hl-dfd', name: 'High Level DFD'},
          // {state: 'big-data-streams-to-grafana-s/dfd-1', name: 'DFD - Level 1'},
          // {state: 'big-data-streams-to-grafana-s/dfd-2', name: 'DFD - Level 2'},
          // {state: 'big-data-streams-to-grafana-s/dfd-3', name: 'DFD - Level 3'},
          // {state: 'big-data-streams-to-grafana-s/detailed-data-source', name: 'Detailed Data Source'}
        ]
      };
      return child;
    }
    else {
      return(this.locked_child_menu())
    }
  }

  streams_to_hdfs_s(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions)){ 
      let child: ChildrenItems = 
      {
        state:'landing-page',
        name: 'Streams to HDFS (S)',
        type: 'super-sub',
        subchildren: [
          {state: 'big-data-streams-to-hdfs-s/system-viewpoint', name: 'System Viewpoint'},
          // {state: 'big-data-streams-to-hdfs-s/technology-viewpoint', name: 'Technology Viewpoint'},
          // {state: 'big-data-streams-to-hdfs-s/building-blocks', name: 'Architecture Blocks'},
          // {state: 'big-data-streams-to-hdfs-s/block-1', name: 'Building Block 1'},
          // {state: 'big-data-streams-to-hdfs-s/block-2', name: 'Building Block 2'},
          // {state: 'big-data-streams-to-hdfs-s/block-3', name: 'Building Block 3'},
          // {state: 'big-data-streams-to-hdfs-s/block-4', name: 'Building Block 4'},
          // {state: 'big-data-streams-to-hdfs-s/block-5', name: 'Building Block 5'},
          // {state: 'big-data-streams-to-hdfs-s/block-6', name: 'Building Block 6'},
          // {state: 'big-data-streams-to-hdfs-s/block-7', name: 'Building Block 7'},
          // {state: 'big-data-streams-to-hdfs-s/hl-dfd', name: 'High Level DFD'},
          // {state: 'big-data-streams-to-hdfs-s/dfd-1', name: 'DFD - Level 1'},
          // {state: 'big-data-streams-to-hdfs-s/dfd-2', name: 'DFD - Level 2'},
          // {state: 'big-data-streams-to-hdfs-s/dfd-3', name: 'DFD - Level 3'},
          // {state: 'big-data-streams-to-hdfs-s/detailed-data-source', name: 'Detailed Data Source'}
        ]
      };
      return child;
    }
    else {
      return(this.locked_child_menu())
    }
  }

  streams_to_influx_s(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions)){ 
      let child: ChildrenItems = 
      {
        state:'landing-page',
        name: 'Streams to Influx (S)',
        type: 'super-sub',
        subchildren: [
          {state: 'big-data-streams-to-influxdb-s/system-viewpoint', name: 'System Viewpoint'},
          // {state: 'streams-to-influxdb-s/technology-viewpoint', name: 'Technology Viewpoint'},
          // {state: 'streams-to-influxdb-s/building-blocks', name: 'Architecture Blocks'},
          // {state: 'streams-to-influxdb-s/block-1', name: 'Building Block 1'},
          // {state: 'streams-to-influxdb-s/block-2', name: 'Building Block 2'},
          // {state: 'streams-to-influxdb-s/block-3', name: 'Building Block 3'},
          // {state: 'streams-to-influxdb-s/block-4', name: 'Building Block 4'},
          // {state: 'streams-to-influxdb-s/block-5', name: 'Building Block 5'},
          // {state: 'streams-to-influxdb-s/block-6', name: 'Building Block 6'},
          // {state: 'streams-to-influxdb-s/block-7', name: 'Building Block 7'},
          // {state: 'streams-to-influxdb-s/hl-dfd', name: 'High Level DFD'},
          // {state: 'streams-to-influxdb-s/dfd-1', name: 'DFD - Level 1'},
          // {state: 'streams-to-influxdb-s/dfd-2', name: 'DFD - Level 2'},
          // {state: 'streams-to-influxdb-s/dfd-3', name: 'DFD - Level 3'},
          // {state: 'streams-to-influxdb-s/detailed-data-source', name: 'Detailed Data Source'}
        ]
      };
      return child;
    }
    else {
      return(this.locked_child_menu())
    }
  }

  streams_to_neo4j_s(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions)){ 
      let child: ChildrenItems = 
      {
        state:'landing-page',
        name: 'Streams to Neo4j (S)',
        type: 'super-sub',
        subchildren: [
          {state: 'big-data-streams-to-neo4j-s/system-viewpoint', name: 'System Viewpoint'},
          // {state: 'big-data-streams-to-neo4j-s/technology-viewpoint', name: 'Technology Viewpoint'},
          // {state: 'big-data-streams-to-neo4j-s/building-blocks', name: 'Architecture Blocks'},
          // {state: 'big-data-streams-to-neo4j-s/block-1', name: 'Building Block 1'},
          // {state: 'big-data-streams-to-neo4j-s/block-2', name: 'Building Block 2'},
          // {state: 'big-data-streams-to-neo4j-s/block-3', name: 'Building Block 3'},
          // {state: 'big-data-streams-to-neo4j-s/block-4', name: 'Building Block 4'},
          // {state: 'big-data-streams-to-neo4j-s/block-5', name: 'Building Block 5'},
          // {state: 'big-data-streams-to-neo4j-s/block-6', name: 'Building Block 6'},
          // {state: 'big-data-streams-to-neo4j-s/block-7', name: 'Building Block 7'},
          // // {state: 'big-data-streams-to-neo4j-s/block-8', name: 'Building Block 8'},
          // // {state: 'big-data-streams-to-neo4j-s/block-9', name: 'Building Block 9'},
          // {state: 'big-data-streams-to-neo4j-s/hl-dfd', name: 'High Level DFD'},
          // {state: 'big-data-streams-to-neo4j-s/dfd-1', name: 'DFD - Level 1'},
          // {state: 'big-data-streams-to-neo4j-s/dfd-2', name: 'DFD - Level 2'},
          // {state: 'big-data-streams-to-neo4j-s/dfd-3', name: 'DFD - Level 3'},
          // {state: 'big-data-streams-to-neo4j-s/detailed-data-source', name: 'Detailed Data Source'}
        ]
      };
      return child;
    }
    else {
      return(this.locked_child_menu())
    }
  }

  streams_to_redis_s(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions)){ 
      let child: ChildrenItems = 
      {
        state:'landing-page',
        name: 'Streams to Redis (S)',
        type: 'super-sub',
        subchildren: [
          {state: 'big-data-streams-to-redis-s/system-viewpoint', name: 'System Viewpoint'},
          // {state: 'big-data-streams-to-redis-s/technology-viewpoint', name: 'Technology Viewpoint'},
          // {state: 'big-data-streams-to-redis-s/building-blocks', name: 'Architecture Blocks'},
          // {state: 'big-data-streams-to-redis-s/block-1', name: 'Building Block 1'},
          // {state: 'big-data-streams-to-redis-s/block-2', name: 'Building Block 2'},
          // {state: 'big-data-streams-to-redis-s/block-3', name: 'Building Block 3'},
          // {state: 'big-data-streams-to-redis-s/block-4', name: 'Building Block 4'},
          // {state: 'big-data-streams-to-redis-s/block-5', name: 'Building Block 5'},
          // {state: 'big-data-streams-to-redis-s/block-6', name: 'Building Block 6'},
          // {state: 'big-data-streams-to-redis-s/block-7', name: 'Building Block 7'},
          // {state: 'big-data-streams-to-redis-s/hl-dfd', name: 'High Level DFD'},
          // {state: 'big-data-streams-to-redis-s/dfd-1', name: 'DFD - Level 1'},
          // {state: 'big-data-streams-to-redis-s/dfd-2', name: 'DFD - Level 2'},
          // {state: 'big-data-streams-to-redis-s/dfd-3', name: 'DFD - Level 3'},
          // {state: 'big-data-streams-to-redis-s/detailed-data-source', name: 'Detailed Data Source'}
        ]
      };
      return child;
    }
    else {
      return(this.locked_child_menu())
    }
  }

  streams_to_scylla_s(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions)){ 
      let child: ChildrenItems = 
      {
        state:'landing-page',
        name: 'Streams to Scylla (S)',
        type: 'super-sub',
        subchildren: [
          {state: 'big-data-streams-to-scylla-s/system-viewpoint', name: 'System Viewpoint'},
          // {state: 'big-data-streams-to-scylla-s/technology-viewpoint', name: 'Technology Viewpoint'},
          // {state: 'big-data-streams-to-scylla-s/building-blocks', name: 'Architecture Blocks'},
          // {state: 'big-data-streams-to-scylla-s/block-1', name: 'Building Block 1'},
          // {state: 'big-data-streams-to-scylla-s/block-2', name: 'Building Block 2'},
          // {state: 'big-data-streams-to-scylla-s/block-3', name: 'Building Block 3'},
          // {state: 'big-data-streams-to-scylla-s/block-4', name: 'Building Block 4'},
          // {state: 'big-data-streams-to-scylla-s/block-5', name: 'Building Block 5'},
          // {state: 'big-data-streams-to-scylla-s/block-6', name: 'Building Block 6'},
          // {state: 'big-data-streams-to-scylla-s/block-7', name: 'Building Block 7'},
          // {state: 'big-data-streams-to-scylla-s/hl-dfd', name: 'High Level DFD'},
          // {state: 'big-data-streams-to-scylla-s/dfd-1', name: 'DFD - Level 1'},
          // {state: 'big-data-streams-to-scylla-s/dfd-2', name: 'DFD - Level 2'},
          // {state: 'big-data-streams-to-scylla-s/dfd-3', name: 'DFD - Level 3'},
          // {state: 'big-data-streams-to-scylla-s/detailed-data-source', name: 'Detailed Data Source'}
        ]
      };
      return child;
    }
    else {
      return(this.locked_child_menu())
    }
  }

  oracle_to_hdfs_b(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions)){ 
      let child: ChildrenItems = 
      {
        state:'landing-page',
        name: 'Oracle to HDFS (B)',
        type: 'super-sub',
        subchildren: [
          {state: 'big-data-relational-ingestion-hdfs-b/system-viewpoint', name: 'System Viewpoint'},
          // {state: 'big-data-relational-ingestion-hdfs-b/technology-viewpoint', name: 'Technology Viewpoint'},
          // {state: 'big-data-relational-ingestion-hdfs-b/building-blocks', name: 'Architecture Blocks'},
          // {state: 'big-data-relational-ingestion-hdfs-b/block-1', name: 'Building Block 1'},
          // {state: 'big-data-relational-ingestion-hdfs-b/block-2', name: 'Building Block 2'},
          // {state: 'big-data-relational-ingestion-hdfs-b/block-3', name: 'Building Block 3'},
          // {state: 'big-data-relational-ingestion-hdfs-b/block-4', name: 'Building Block 4'},
          // {state: 'big-data-relational-ingestion-hdfs-b/hl-dfd', name: 'High Level DFD'},
          // {state: 'big-data-relational-ingestion-hdfs-b/dfd-1', name: 'DFD - Level 1'},
          // {state: 'big-data-relational-ingestion-hdfs-b/dfd-2', name: 'DFD - Level 2'},
          // {state: 'big-data-relational-ingestion-hdfs-b/dfd-3', name: 'DFD - Level 3'},
          // {state: 'big-data-relational-ingestion-hdfs-b/detailed-data-source', name: 'Detailed Data Source'}
        ]
      };
      return child;
    }
    else {
      return(this.locked_child_menu())
    }
  }

  files_to_hdfs_b(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions)){ 
      let child: ChildrenItems = 
      {
        state:'landing-page',
        name: 'Files to HDFS (B)',
        type: 'super-sub',
        subchildren: [
          {state: 'big-data-files-to-hdfs-b/system-viewpoint', name: 'System Viewpoint'},
          // {state: 'files-to-hdfs-b/technology-viewpoint', name: 'Technology Viewpoint'},
          // {state: 'files-to-hdfs-b/building-blocks', name: 'Architecture Blocks'},
          // {state: 'files-to-hdfs-b/block-1', name: 'Building Block 1'},
          // {state: 'files-to-hdfs-b/block-2', name: 'Building Block 2'},
          // {state: 'files-to-hdfs-b/block-3', name: 'Building Block 3'},
          // {state: 'files-to-hdfs-b/block-4', name: 'Building Block 4'},
          // {state: 'files-to-hdfs-b/hl-dfd', name: 'High Level DFD'},
          // {state: 'files-to-hdfs-b/dfd-1', name: 'DFD - Level 1'},
          // {state: 'files-to-hdfs-b/dfd-2', name: 'DFD - Level 2'},
          // {state: 'files-to-hdfs-b/dfd-3', name: 'DFD - Level 3'},
          // {state: 'files-to-hdfs-b/detailed-data-source', name: 'Detailed Data Source'}
        ]
      };
      return child;
    }
    else {
      return(this.locked_child_menu())
    }
  }

  logs_to_hdfs_b(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions)){ 
      let child: ChildrenItems = 
      {
        state:'landing-page',
        name: 'Logs to HDFS (B)',
        type: 'super-sub',
        subchildren: [
          {state: 'big-data-logs-to-hdfs-b/system-viewpoint', name: 'System Viewpoint'},
          // {state: 'big-data-logs-to-hdfs-b/technology-viewpoint', name: 'Technology Viewpoint'},
          // {state: 'big-data-logs-to-hdfs-b/building-blocks', name: 'Architecture Blocks'},
          // {state: 'big-data-logs-to-hdfs-b/block-1', name: 'Building Block 1'},
          // {state: 'big-data-logs-to-hdfs-b/block-2', name: 'Building Block 2'},
          // {state: 'big-data-logs-to-hdfs-b/block-3', name: 'Building Block 3'},
          // {state: 'big-data-logs-to-hdfs-b/block-4', name: 'Building Block 4'},
          // {state: 'big-data-logs-to-hdfs-b/hl-dfd', name: 'High Level DFD'},
          // {state: 'big-data-logs-to-hdfs-b/dfd-1', name: 'DFD - Level 1'},
          // {state: 'big-data-logs-to-hdfs-b/dfd-2', name: 'DFD - Level 2'},
          // {state: 'big-data-logs-to-hdfs-b/dfd-3', name: 'DFD - Level 3'},
          // {state: 'big-data-logs-to-hdfs-b/detailed-data-source', name: 'Detailed Data Source'}
        ]
      };
      return child;
    }
    else {
      return(this.locked_child_menu())
    }
  }

  // // Tech Talked moved into "Big Data Architecture" menu item
  // tech_talk(userPermissions: string) {
  //   if (this.loginHasPermissions(userPermissions)){ 
  //     let child: ChildrenItems = 
  //     {
  //       state:'landing-page',
  //       name: 'Tech Talk',
  //       type: 'super-sub',
  //       subchildren: [
  //         {state: 'big-data-tech-talk/tech-talk', name: 'Tech Talk'}
  //       ]
  //     };
  //     return child;
  //   }
  // }

  high_availability(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions)){ 
      let child: ChildrenItems = 
      {
        state:'landing-page',
        name: 'High Availability',
        type: 'super-sub',
        subchildren: [
          {state: 'big-data-high-availability/HDFS', name: 'HDFS'},
          {state: 'big-data-high-availability/Zookeeper', name: 'Zookeeper'},
          {state: 'big-data-high-availability/YARN', name: 'YARN'},
        ]
      };
      return child;
    }
    else {
      return(this.locked_child_menu())
    }
  }

  big_data_security(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions)){ 
      let child: ChildrenItems = 
      {
        state:'landing-page',
        name: 'Security',
        type: 'super-sub',
        subchildren: [
          {state: 'big-data-security/big-data-security-item-1', name: 'Cloudera Security'},
          {state: 'big-data-security/big-data-security-item-2', name: 'Kerberos'},
        ]
      };
      return child;
    }
    else {
      return(this.locked_child_menu())
    }
  }




  infrastructure_node_config(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions)){ 
      let child: ChildrenItems = 
      {
        state:'landing-page',
        name: 'Infrastructure',
        type: 'super-sub',
        subchildren: [
          {state: 'big-data-infrastructure/node-1', name: 'Node Configuration'},
          {state: 'big-data-development-setup/bill-of-materials', name: 'Bill of Materials'},
          {state: 'big-data-development-setup/deployment-topology', name: 'Deployment Topology'},
          {state: 'big-data-development-setup/cloudera-role-assignment', name: 'Role Assignment'},
          {state: 'big-data-infrastructure/kafka-config', name: 'Kafka Config'},
          {state: 'big-data-infrastructure/zookeeper-config', name: 'Zookeeper Config'},
          // {state: 'big-data-infrastructure/node-2', name: 'Master Node 2'},
          // {state: 'big-data-infrastructure/node-3', name: 'Master Node 3'},
          // {state: 'big-data-infrastructure/node-4', name: 'Worker Node 1'},
          // {state: 'big-data-infrastructure/node-5', name: 'Worker Node 2'},
          // {state: 'big-data-infrastructure/node-6', name: 'Worker Node 3'},
          // {state: 'big-data-infrastructure/node-7', name: 'Worker Node 4'},
          // {state: 'big-data-infrastructure/node-8', name: 'Worker Node 5'},
          // {state: 'big-data-infrastructure/node-9', name: 'Worker Node 6'},
          // {state: 'big-data-infrastructure/node-10', name: 'Worker Node 7'},
          // {state: 'big-data-infrastructure/node-11', name: 'Worker Node 8'},
          // {state: 'big-data-infrastructure/node-12', name: 'Utility Node 1'},
          // {state: 'big-data-infrastructure/node-13', name: 'Utility Node 2'},
          // {state: 'big-data-infrastructure/node-14', name: 'Utility Node 3'},
          // {state: 'big-data-infrastructure/node-15', name: 'GPU Node'},
          // {state: 'big-data-infrastructure/node-16', name: 'Scylla Node 1'},
          // {state: 'big-data-infrastructure/node-17', name: 'Scylla Node 2'},
          // {state: 'big-data-infrastructure/node-18', name: 'Scylla Node 3'},
        ]
      };
      return child;
    }
    else {
      return(this.locked_child_menu())
    }
  }

  prod_install_docs(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions)){ 
      let child: ChildrenItems = 
      {
        state:'landing-page',
        name: 'Production Install',
        type: 'super-sub',
        subchildren: [
          {state: 'big-data-prod-install-docs/cloudera-install', name: 'Cloudera Install'},
          {state: 'big-data-prod-install-docs/golden-gate-install', name: 'GoldenGate Install'},
          {state: 'big-data-prod-install-docs/golden-gate-big-data-install', name: 'OGG4BD Install'},
          // {state: 'big-data-prod-install-docs/grafana-install', name: 'Grafana Install'},
          {state: 'big-data-prod-install-docs/influx-db-install', name: 'InfluxDB Install'},
          {state: 'big-data-prod-install-docs/mysql-install', name: 'MySQL Install'},
          {state: 'big-data-prod-install-docs/neo4j-install', name: 'Neo4j Install'},
          {state: 'big-data-prod-install-docs/scylla-db-install', name: 'ScyllaDB Install'},
        ]
      };
      return child;
    }
    else {
      return(this.locked_child_menu())
    }
  }

  big_data_development_setup(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions)){ 
      let child: ChildrenItems = 
      {
        state:'landing-page',
        name: 'Development Setup',
        type: 'super-sub',
        subchildren: [
          {state: 'big-data-development-setup/development-env-setup', name: 'Cloudera Setup'},
          //{state: 'network-lld', name: 'Network LLD'},
          {state: 'big-data-development-setup/goldengate', name: 'Install GoldenGate'},
          {state: 'big-data-development-setup/goldengate-for-big-data', name: 'Install GoldenGate for Big Data'},
          {state: 'big-data-development-setup/grafana', name: 'Install Grafana'},
          {state: 'big-data-development-setup/influxdb', name: 'Install InfluxDB'},
          {state: 'big-data-development-setup/mysql', name: 'Install MySQL'},
          {state: 'big-data-development-setup/neo4j', name: 'Install Neo4j'},
          {state: 'big-data-development-setup/scylla', name: 'Install Scylla'},
        ]
      };
      return child;
    }
    else {
      return(this.locked_child_menu())
    }
  }
  /***************************************************************************************/
  /**********************   END : menuItem_BigData Sub Components   **********************/
  /***************************************************************************************/ 


  


  
  loadMenuItems(fullClientConfig:IFullClientConfig) {
    //first reset menu before addign items
    this.purgeMenu();
    this.menuItem_InQuest_USSD('inquest_testautomation_administrator,inquest_testautomation_poweruser,inquest_testautomation_user,ReportManager,inquest_developer');   //Also Implicit GodMode
    this.menuItem_Apogee('apogee_poweruser,apogee_viewer,inquest_developer', fullClientConfig.APOGEE_DASHBOARDS.dashboards || []); //Also Implicit GodMode
    this.menuItem_BigData_1('BigData');               //Also Implicit GodMode
    this.menuItem_BigData_2('BigData');               //Also Implicit GodMode
    this.menuItem_INQUEST_InternalUse('inquest_testautomation_administrator,inquest_developer');    //Also Implicit GodMode
  }
  
  menuItem_InQuest_USSD(userPermissions: string) {
    this.fillGroupPolicies();

    if (this.loginHasPermissions(userPermissions))
    { 
    let mainInquestMenu = {
      state:'landing-page',
      name: 'INQUEST USSD',
      type: 'sub',
      icon: 'sim_card',
      children: [
        //this.mobile_hardware('inquest_testautomation_administrator,inquest_testautomation_poweruser'),
      ]
    };
    //-------------------------------------------------------------------------------------------
    // Marwyk - add submenu items based on GPO permissions
    //-------------------------------------------------------------------------------------------
    (this.isGodModeActive || this.iPrimaryGroupPolicy.Menus["SystemConfiguration"]) && mainInquestMenu.children.push(this.mobile_hardware(this.iPrimaryGroupPolicy.Menus["SystemConfiguration"]));
    (this.isGodModeActive || this.iPrimaryGroupPolicy.Menus["TestCollection"]) && mainInquestMenu.children.push(this.mobile_test_collection(this.iPrimaryGroupPolicy.Menus["TestCollection"]));
    (this.isGodModeActive || this.iPrimaryGroupPolicy.Menus["Reports"]) && mainInquestMenu.children.push(this.mobile_test_reports(this.iPrimaryGroupPolicy.Menus["Reports"]));
    (this.isGodModeActive || this.iPrimaryGroupPolicy.Menus["Dashboards"]) && mainInquestMenu.children.push(this.mobile_dashboards(this.iPrimaryGroupPolicy.Menus["Dashboards"]));

    this.add(mainInquestMenu);

    }
  }

  menuItem_BigData_1(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions))
    { 
    this.add(
      {
      state:'landing-page',
      name: 'Big Data Architecture',
      type: 'sub',
      icon: 'grid_on',
      children: [
        this.big_data_architecture('GodMode,BigData'),
        this.master_machine_learning('GodMode,BigData'),
        this.master_data_management('GodMode,BigData'),
        this.information_systems_architecture('GodMode,BigData'),
        this.lambda_architecture('GodMode,BigData'),
        this.data_processing('GodMode,BigData'),
        this.data_analytics('GodMode,BigData'),
        // this.tech_talk('GodMode,BigData'),
        this.high_availability('GodMode,BigData'),
        this.big_data_security('GodMode,BigData'),
        this.infrastructure_node_config('GodMode,BigData'),
        this.prod_install_docs('GodMode,BigData'),
        this.big_data_development_setup('GodMode,BigData'),
      ]
    },);
    }
  }

  menuItem_BigData_2(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions))
    { 
    this.add(
      {
      state:'landing-page',
      name: 'Big Data Viewpoints',
      type: 'sub',
      icon: 'grid_on',
      children: [
        this.oracle_to_grafana_s('GodMode,BigData'),
        this.oracle_to_hdfs_s('GodMode,BigData'),
        this.oracle_to_hive_b('GodMode,BigData'),
        this.oracle_to_influx_s('GodMode,BigData'),
        this.oracle_to_neo4j_s('GodMode,BigData'),
        this.oracle_to_redis_s('GodMode,BigData'),
        this.oracle_to_scylla_s('GodMode,BigData'),
        this.streams_to_grafana_s('GodMode,BigData'),
        this.streams_to_hdfs_s('GodMode,BigData'),
        this.streams_to_influx_s('GodMode,BigData'),
        this.streams_to_neo4j_s('GodMode,BigData'),
        this.streams_to_redis_s('GodMode,BigData'),
        this.streams_to_scylla_s('GodMode,BigData'),
        this.oracle_to_hdfs_b('GodMode,BigData'),
        this.files_to_hdfs_b('GodMode,BigData'),
        this.logs_to_hdfs_b('GodMode,BigData'),
      ]
    },);
    }
  }

  menuItem_INQUEST_InternalUse(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions))
    { 
      let inquestInternalMenu = {
        state:'landing-page',
        name: 'INQUEST Internal',
        type: 'sub',
        icon: 'event_seat',
        children: [
        ]
      };

      //-------------------------------------------------------------------------------------------
      // Marwyk - add submenu items based on GPO permissions
      //-------------------------------------------------------------------------------------------
      (this.isGodModeActive || this.iPrimaryGroupPolicy.Menus["GlobalConfiguration"]) && inquestInternalMenu.children.push(this.global_config(this.iPrimaryGroupPolicy.Menus["GlobalConfiguration"]));
      (this.isGodModeActive || this.iPrimaryGroupPolicy.Menus["CustomerAdministration"]) && inquestInternalMenu.children.push(this.customer_config(this.iPrimaryGroupPolicy.Menus["CustomerAdministration"]));
      //this.iPrimaryGroupPolicy.Menus["InquestDemoVideos"] && inquestInternalMenu.children.push(this.ussd_demo_videos(this.iPrimaryGroupPolicy.Menus["InquestDemoVideos"]));

      this.add(inquestInternalMenu);
    
    }
  }

  mobile_hardware(allowedMenus:string[] = []) {
      try
      {
        let child: ChildrenItems = 
        {
          state: 'landing-page',
          name: 'System Configuration',
          type: 'super-sub',
          subchildren: []
        };

        (this.isGodModeActive || allowedMenus.includes("TokenConfig")) && child.subchildren.push({state: 'mobile-hardware/token-list',  name: 'Token Config'});
        (this.isGodModeActive || allowedMenus.includes("DeviceBank")) && child.subchildren.push({state: 'mobile-hardware/device-list',  name: 'Device Bank'});
        (this.isGodModeActive || allowedMenus.includes("SimPoolConfig")) && child.subchildren.push({state: 'mobile-hardware/sim-pool-config',  name: 'Sim Pool Config'});
        
        return child;
      }
      catch (exception) {
        if(!environment.production){
          console.log('#EXCEPTION::HorizontalMenuItems::mobile_hardware() ', exception);
        }
      }
      
  }

  //------------------------------------------------------------------------------------------------------
  // Marwyk - New Global Menus
  //-----------------------------------------------------------------------------------------------------
  global_config(allowedMenus:string[] = []) {
    try
    {
      let child: ChildrenItems = 
      {
        state: 'landing-page',
        name: 'Global Configuration',
        type: 'super-sub',
        subchildren: []
      };
      (this.isGodModeActive || allowedMenus.includes("ClientConfig")) && child.subchildren.push({state: 'main-app/client-config',  name: 'Client Configuration'});

      return child;
    }
    catch (exception) {
      if(!environment.production){
        console.log('#EXCEPTION::HorizontalMenuItems::global_config() ', exception);
      }
    }  
  }

  //------------------------------------------------------------------------------------------------------
  // Marwyk - Customer Config Menus
  //-----------------------------------------------------------------------------------------------------
  customer_config(allowedMenus:string[] = []) {
    try
    {
      let child: ChildrenItems = 
      {
        state: 'landing-page',
        name: 'Customer Administration',
        type: 'super-sub',
        subchildren: []
      };

      (this.isGodModeActive || allowedMenus.includes("CustomerConfig")) && child.subchildren.push({state: '/mobile-hardware/customer-config',  name: 'Customer Config'});
      (this.isGodModeActive || allowedMenus.includes("MobileNetworkOperators")) && child.subchildren.push({state: '/mobile-hardware/mno-add',  name: 'Mobile Network Operators'});
      (this.isGodModeActive || allowedMenus.includes("RatingGroups")) && child.subchildren.push({state: '/mobile-hardware/rating-group-add',  name: 'Rating Groups'});
      (this.isGodModeActive || allowedMenus.includes("VoiceMsisdnTargets")) && child.subchildren.push({state: '/mobile-hardware/voice-target-list',  name: 'Voice MSISDN Targets'});
      (this.isGodModeActive || allowedMenus.includes("DataDrawDownTargets")) && child.subchildren.push({state: '/mobile-hardware/data-target-list',  name: 'DATA DrawDown Targets'});
      (this.isGodModeActive || allowedMenus.includes("SimPools")) && child.subchildren.push({state: 'mobile-hardware/sim-pool-list',  name: 'Sim Pools'});
      (this.isGodModeActive || allowedMenus.includes("UssdRobots")) && child.subchildren.push({state: 'mobile-hardware/ussd-robot-list',  name: 'USSD Robots'});
      (this.isGodModeActive || allowedMenus.includes("DiameterServers")) && child.subchildren.push({state: '/mobile-hardware/diameter-add',  name: 'Diameter Servers'});
      

      return child;
    }
    catch (exception) {
      if(!environment.production){
        console.log('#EXCEPTION::HorizontalMenuItems::customer_config() ', exception);
      }
    }  
  }

  //------------------------------------------------------------------------------------------------------
  // Marwyk - New Demo Videos Menus
  //-----------------------------------------------------------------------------------------------------
  ussd_demo_videos(allowedMenus:string[] = []) {
    try
    {
      let child: ChildrenItems = 
      {
        state: 'landing-page',
        name: 'Demo Videos',
        type: 'super-sub',
        subchildren: []
      };
      (this.isGodModeActive || allowedMenus.includes("USSDOverview")) && child.subchildren.push({state: 'video-menu/ussd-overview',  name: 'USSD Overview'});
      (this.isGodModeActive || allowedMenus.includes("ReportsOverview")) && child.subchildren.push({state: 'video-menu/report-overview',  name: 'Reports Overview'});
      (this.isGodModeActive || allowedMenus.includes("ReadingSMS")) && child.subchildren.push({state: 'video-menu/reading-sms',  name: 'Reading SMS'});
      (this.isGodModeActive || allowedMenus.includes("ResultSummary")) && child.subchildren.push({state: 'video-menu/result-summary',  name: 'Result Summary'});

      return child;
    }
    catch (exception) {
      if(!environment.production){
        console.log('#EXCEPTION::HorizontalMenuItems::ussd_demo_videos() ', exception);
      }
    }  
  }


  // Apogee Main Menu Item
  async menuItem_Apogee(userPermissions: string, apogeeDashboards:IApogeeDashboard[] = []) {
      this.fillGroupPolicies();
  
      if (this.loginHasPermissions(userPermissions))
      { 
      let mainApogeeMenu = {
        state:'Apogee',
        name: 'Apogee',
        type: 'sub',
        icon: 'query_stats',
        searchable:true,
        children: []
      };

      //add the available apogee dashboards here
      apogeeDashboards.forEach(dash => {
        mainApogeeMenu.children.push({state: `Apogee/dashboard/${dash.uid}`,  name: `${dash.title}`});
      })
  
      this.add(mainApogeeMenu);
  
      }
    }

    

}