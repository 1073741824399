import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { environment } from '../../environments/environment'
import { IMySQLError, IReturnCode, Ipr_AddUser, Ipr_AddSession } from '../interface/i-mobile-test';
import { ISessionLive } from '../interface/i-responses';
import { PageTitleService } from '../core/page-title/page-title.service';

@Injectable({
    providedIn: 'root'
})

export class SlaveService {
    baseURL = "https://twpnqj0d02.execute-api.ap-southeast-1.amazonaws.com/Live/";
    //baseURL = 'assets/data/mobile-test-mock.json';
    urlPostFix = '';
    apiURL = this.baseURL + this.urlPostFix;
    poison: string = 'cyAn1d3'; // 7 character to poison the Token (Cyanide)
    antidote: string;
    iMySQLError: IMySQLError[];
    errorMessage: string;
    tokenAuthorizer = this.hydrateCognitoToken(this.poison);
    iSessionLive: ISessionLive = {
        SessionId: 0,
        UserId: 0,
        FirstName: '',
        LastName: '',
        Email: '',
        ClientId: 0,
        UserName: '',
        CognitoGroups: '',
        IsLoggedIn: false,
        NickName: '',
        CognitoToken: ''
    }
    
    httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': this.iSessionLive.CognitoToken
        })
      };

    //create a constructor
    constructor(
        private http: HttpClient,
        private pageTitleService: PageTitleService
        ){

        }

    // Inquest Appliaiton URL 
    setApiUrl(urlPostFix: string): string{
        var returnValue = this.apiURL + urlPostFix;
        
        if (!environment.production) {
            var baseURL = "https://cbcfmdao2m.execute-api.ap-southeast-1.amazonaws.com/lambora-dev/";
            var apiURL = baseURL + this.urlPostFix;
            returnValue = apiURL + urlPostFix;
        } 
        return returnValue
    }

    hydrateCognitoToken(poison: string): string {
        var poisonToken = sessionStorage.getItem('Frodo');
        var antidote = sessionStorage.getItem('Samwise');
        
        if (poisonToken !== null) {
            var revivedToken = poisonToken.replace(poison, antidote);
            return revivedToken;
        }
        else {
            return 
        }
    }

    //*** SERIVCE CALLS GO BELOW THIS LINES ***//
    //Generic Save is meant to take in the JSON Payload with the defined rules to execute any stored procedure via the AWS Architecture. [Denver Naidoo - 06 Dec 2020]
    postInterface(reqBody: any): Observable<any> {
        //Pass a sessionId along with every call
        reqBody.SessionId = sessionStorage.getItem('MountDoom');
        this.initialise();
        
        return this.http.post<JSON>(this.setApiUrl(''), reqBody, this.httpOptions).pipe(
            // tap(data => {
            //     if (!environment.production) {
            //         console.log("Request: ", reqBody);
            //     }
            // }),
            // tap(data => {
            //     if (!environment.production) {
            //         console.log("Response: ", JSON.stringify(data));
            //     }
            // }),
            catchError(this.handleError)
        );
    }

    
    //Error Handling Mehtod
    private handleError(err: HttpErrorResponse) {

        let errorMessage = '';

        if (err.error instanceof ErrorEvent) {
            errorMessage = `Errors have Occured: ${err.error.message} `;
        } else {
            errorMessage = `Server returned code: ${err.status}. Error Status is ${err.message}`;
        }

        if (!environment.production) {
            console.log('Error Message: [slave-service]: ' + errorMessage);
        }

        return throwError(errorMessage);

    }

    initialise() {
        this.httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.iSessionLive.CognitoToken
            })
          };
    }

    //---------------------------------------------------------------------------------------------
    // Log the User to the DB in the key [Denver Naidoo 17 Nov 2020]
    //---------------------------------------------------------------------------------------------
    pr_AddUser: Ipr_AddUser = {} as any;
    pr_AddUserAlias: string = 'AddUser';
    ipr_AddUser_ReturnCode: IReturnCode[];

    addUser(clientId, userName, givenName, familyName, nickname, email) {
        this.pr_AddUser.ApiAlias = this.pr_AddUserAlias;
        this.pr_AddUser.ClientId = clientId;
        this.pr_AddUser.UserName = userName;
        this.pr_AddUser.GivenName = givenName;
        this.pr_AddUser.FamilyName = familyName;
        this.pr_AddUser.Nickname = nickname;
        this.pr_AddUser.Email = email;

        this.postInterface(this.pr_AddUser).subscribe({
            next: responseData => { 
                this.ipr_AddUser_ReturnCode = responseData;
                sessionStorage.setItem('Tolkien', this.ipr_AddUser_ReturnCode[0].ReturnCode.toString());
                this.addSession(sessionStorage.getItem('Tolkien'));
                this.setSessionLive(this.ipr_AddUser_ReturnCode[0].ReturnCode);
                this.iMySQLError = responseData;
                },
          error: err => this.errorMessage = err
          });
    }

    //---------------------------------------------------------------------------------------------
    // Set the Live Session
    //---------------------------------------------------------------------------------------------
    setSessionLive(UserId: number=0, SessionId: number=0) {
        try {
            let IsSessionChanged: boolean = false;

            if (UserId > 0) {
                this.iSessionLive.UserId = UserId;
                IsSessionChanged = true;
            }
            if (SessionId > 0) {
                this.iSessionLive.SessionId = SessionId;
                IsSessionChanged = true;
            }

            if (IsSessionChanged) {
                this.pageTitleService.setSessionLive(this.iSessionLive);                
            }
        }
        catch (exception) {
            if (!environment.production) {
                console.log('#EXCEPTION: ', exception);
            }
          }
    }

    //---------------------------------------------------------------------------------------------
    // Log the Session [Denver Naidoo 24 Nov 2020]
    //---------------------------------------------------------------------------------------------
    pr_AddSession: Ipr_AddSession = {} as any;
    pr_AddSessionAlias: string = 'AddSession';
    ipr_AddSession_ReturnCode: IReturnCode[];

    addSession(userId) {        
        this.pr_AddSession.ApiAlias = this.pr_AddSessionAlias;
        this.pr_AddSession.UserId = userId;
        this.pr_AddSession.Language = navigator.language;
        this.pr_AddSession.Platform = navigator.platform;
        this.pr_AddSession.UserAgent = navigator.userAgent;
        this.pr_AddSession.StartTime = null;
        this.pr_AddSession.EndTime = null;

      this.postInterface(this.pr_AddSession).subscribe({
          next: responseData => { 
            this.ipr_AddSession_ReturnCode = responseData;
            sessionStorage.setItem('MountDoom', this.ipr_AddSession_ReturnCode[0].ReturnCode.toString()); //MountDoom = SessionId
            this.setSessionLive(0, +this.ipr_AddSession_ReturnCode[0].ReturnCode);
            this.iMySQLError = responseData;
            },
          error: err => this.errorMessage = err
          });
    }

}

