import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { environment } from '../../environments/environment';
import { IGroupPolicy, IPrimaryGroupPolicy, GroupPolicyMenuMap, GroupPolicyLookupMap, AcceptableGroupPolicies, GroupPolicyRibbonMap } from '../interface/i-application';
import { IPermissions } from '../interface/i-responses';
import { InquestDeveloperService } from './inquest-developer.service';

@Injectable()
export class PermissionsService {
    private iPrimaryGPO: IPrimaryGroupPolicy;
    private iGroupPolicies: IGroupPolicy[];

    public iPermissions:BehaviorSubject<IPermissions> = new BehaviorSubject<IPermissions>(null);
    
    constructor (
        private developer: InquestDeveloperService,
    ){}
    
    getSessionPolicies(): IPrimaryGroupPolicy {
        return this.iPrimaryGPO;
    }

    setSessionGPOs()
    {
        this.iGroupPolicies = this.getSessionGPOs();
        this.iPrimaryGPO = this.mergeGPOS(this.getSessionGPOs());

        this.iPermissions.next({GroupPolicies:this.iGroupPolicies, PrimaryGPO:this.iPrimaryGPO});
    }

    //---------------------------------------------------------------------------------------------------------------
    // Marwyk
    //---------------------------------------------------------------------------------------------------------------
    getSessionGPOs()
    {
        let gpoList:IGroupPolicy[] = [];
        let sessionGroups = sessionStorage.getItem("Gollum");

        let groupMenuMap = {...GroupPolicyMenuMap};
        let groupLookupMap = {...GroupPolicyLookupMap}
        let groupRibbonMap = {...GroupPolicyRibbonMap}

        sessionGroups.split(',').forEach(group => {
            if(AcceptableGroupPolicies.includes(group))
            {
              let newGroup:IGroupPolicy = {};
              newGroup.Group = group;
              newGroup.Menus = groupMenuMap[group]
              newGroup.Lookups = groupLookupMap[group];
              newGroup.Ribbons = groupRibbonMap[group];
              gpoList.push(newGroup);
            }
        });
        
        return gpoList;
    }

    mergeGPOS(gpoList:IGroupPolicy[])
    {
        let mergedList:IPrimaryGroupPolicy = {
            Group:[],
            Menus:[],
            Lookups:[],
            Ribbons:[],
        };

        gpoList.forEach(gpo => {
            mergedList.Group.push(gpo.Group);
            //add menu items
            mergedList.Menus = this.deepMerge([mergedList.Menus, gpo.Menus])[1];
            //add lookups
            mergedList.Lookups = this.deepMerge([mergedList.Lookups, gpo.Lookups])[1];
            //add ribbon items
            mergedList.Ribbons = this.deepMerge([mergedList.Ribbons, gpo.Ribbons])[1];
        });

        let newMergedList:IPrimaryGroupPolicy = {
            Group:[],
            Menus:[],
            Lookups:[],
            Ribbons:[],
        };
        gpoList.forEach(gpo => {
            newMergedList.Group.push(gpo.Group);
            //add menu items
            newMergedList.Menus = {...newMergedList.Menus, ...gpo.Menus};
            //add lookups
            newMergedList.Lookups = {...newMergedList.Lookups, ...gpo.Lookups};
            //add ribbon items
            newMergedList.Ribbons = {...newMergedList.Ribbons, ...gpo.Ribbons};
        });

        this.developer.consoleLog("App and Menu permissions => ", newMergedList);
 
        let sessionStoredPrimaryGpo = btoa(JSON.stringify(newMergedList));
        //let sessionStoredPrimaryGpo = btoa(JSON.stringify(mergedList));
        sessionStorage.setItem('GollumPrimary', sessionStoredPrimaryGpo);

        //remove trailing comma in group list
        return newMergedList;
    }


    deepMerge(...objects) {
        const isObject = obj => obj && typeof obj === 'object';
        
        return objects.reduce((prev, obj) => {
            Object.keys(obj).forEach(key => {
                const pVal = prev[key];
                const oVal = obj[key];
                
                if (Array.isArray(pVal) && Array.isArray(oVal)) 
                {
                    prev[key] = pVal.concat(...oVal);
                }
                else if (isObject(pVal) && isObject(oVal)) 
                {
                    prev[key] = this.deepMerge(pVal, oVal);
                }
                else 
                {
                    prev[key] = oVal;
                }
            });
        
            return prev;
        }, {});
    }

}