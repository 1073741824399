import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { IMobileTest, IServicePrerequisite, IGetServiceNames, Ipr_GetServiceNamesWithServiceSteps, IGetTestCases, Ipr_GetTestCases } from '../interface/i-mobile-test'
import { SessionService } from '../service/session-service.service';
import { environment } from '../../environments/environment'

@Injectable({
    providedIn: 'root'
})

export class MasterService {
    //URL Location
    baseURL = "https://twpnqj0d02.execute-api.ap-southeast-1.amazonaws.com/Live/"; // latest value prior to Alabama-dev API added [2021/05/19 - MCoetzee]
    //baseURL = 'assets/data/mobile-test-mock.json';
    urlPostFix = '';
    apiURL = this.baseURL + this.urlPostFix;
    
    httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': this.sessionService.slaveService.iSessionLive.CognitoToken
        })
      };

    //create a constructor
    constructor(private http: HttpClient, private sessionService: SessionService){}

    //create a method to return a list of products
    // getMobileTestList(): Observable<IMobileTest[]> {
    //     return this.http.get<IMobileTest[]>(this.productURL).pipe(
    //         tap(data => console.log('All: ' + JSON.stringify(data))),
    //         catchError(this.handleError)
    //     );

    // }

    // Inquest Appliaiton URL 
    setApiUrl(urlPostFix: string): string{
        var baseURL = '';
        var returnValue = '';

        switch(urlPostFix) {

            case '': {
                if (!environment.production) { 
                    // dev env, no postfix.
                    baseURL = "https://cbcfmdao2m.execute-api.ap-southeast-1.amazonaws.com/lambora-dev/";  // as initially defined by Denver pre INS-128 -> "Call Alabama API from INQUEST"
                    returnValue = baseURL + this.urlPostFix ;
                } else {  
                    // prod env, no postfix.
                    baseURL = "https://twpnqj0d02.execute-api.ap-southeast-1.amazonaws.com/Live/";
                    returnValue = baseURL + this.urlPostFix;
                }
                break;
            }

            case 'alabama' : {
                if (!environment.production) { 
                    // dev env, alabama.
                    returnValue = "https://5ljenwry7j.execute-api.ap-southeast-1.amazonaws.com/Dev/execution"; // last backslash added to match original code format - validated
                } else {
                    // prod env, alabama 
                    returnValue = "https://fba7jj76o6.execute-api.ap-southeast-1.amazonaws.com/Live/execution";
                }
                break;
            }

            case 'florida' : {
                if (!environment.production) { 
                    // dev env
                    returnValue = "https://g2vr0ayom5.execute-api.ap-southeast-1.amazonaws.com/Dev/execution"; // last backslash added to match original code format - validated
                } else {
                    // prod env
                    returnValue = "https://l4340skbq4.execute-api.ap-southeast-1.amazonaws.com/Live/execution";
                }
                break;
            }

            case 'fargo' : {
                //call and response from device
                if (!environment.production) { 
                    // dev env
                    returnValue = "https://6z3my0l46f.execute-api.ap-southeast-1.amazonaws.com/dev/"; 
                } else {
                    // prod env
                    returnValue = "https://2g54elw2n5.execute-api.ap-southeast-1.amazonaws.com/live/";
                }
                break;
            }

            case 'lasvegas' : {
                //call and response from device
                if (!environment.production) { 
                    // dev env
                    returnValue = "https://urta96afe9.execute-api.ap-southeast-1.amazonaws.com/dev/lasvegas"; 
                } else {
                    // prod env
                    returnValue = "https://fbawhg8561.execute-api.ap-southeast-1.amazonaws.com/live/lasvegas";
                }
                break;
            }

            case 'kentucky' : {
                //call and response from device
                if (!environment.production) { 
                    // dev env
                    returnValue = "https://urta96afe9.execute-api.ap-southeast-1.amazonaws.com/dev/kentucky"; 
                } else {
                    // prod env
                    returnValue = "https://fbawhg8561.execute-api.ap-southeast-1.amazonaws.com/live/kentucky";
                }
                break;
            }

            case 'pittsburg' : {
                //call and response from pdf export api
                if (!environment.production) { 
                    // dev env
                    returnValue = "https://z09mu9xw44.execute-api.ap-southeast-1.amazonaws.com/Dev/";
                } else {
                    // prod env
                    returnValue = "https://s63avc9aq2.execute-api.ap-southeast-1.amazonaws.com/Live";
                }
                break;
            }
        }
        // if (!environment.production) {
        //     console.log('*** API Call: ', returnValue);
        // }
        return returnValue;
    }

     // Voyager-Unity URL
     setVoyagerUnityApiUrl(urlPostFix: string): string{
        var returnValue = this.apiURL + urlPostFix;
        
        if (!environment.production) {
            var baseURL = "https://ufxdewn7od.execute-api.ap-southeast-1.amazonaws.com/dev"
            var apiURL = baseURL + this.urlPostFix;
            returnValue = apiURL + urlPostFix;
        } 
        else {
            var baseURL = "https://lqq45eon9d.execute-api.ap-southeast-1.amazonaws.com/LIVE/"
            var apiURL = baseURL + this.urlPostFix;
            returnValue = apiURL + urlPostFix;
        }
        return returnValue
    }


    // Voyager-Serverless-USSD 
    setVoyagerApiUrl(urlPostFix: string): string{
        var returnValue = this.apiURL + urlPostFix;
        
        if (!environment.production) {
            var baseURL = "https://ic113pmmfk.execute-api.ap-southeast-1.amazonaws.com/dev-stage-voyager-trigger/"
            var apiURL = baseURL + this.urlPostFix;
            returnValue = apiURL + urlPostFix;
        } 
        else {
            var baseURL = "https://lqq45eon9d.execute-api.ap-southeast-1.amazonaws.com/LIVE/"
            var apiURL = baseURL + this.urlPostFix;
            returnValue = apiURL + urlPostFix;
        }
        return returnValue
    }

    // Voyager-Serverless-API
    setVoyagerAPIApiUrl(urlPostFix: string): string{
        var returnValue = this.apiURL + urlPostFix;
        
        if (!environment.production) {
            var baseURL = "https://78tx9uwyy6.execute-api.ap-southeast-1.amazonaws.com/Dev/"
            var apiURL = baseURL + this.urlPostFix;
            returnValue = apiURL + urlPostFix;
        } 
        else {
            var baseURL = "https://5yr89z9u1i.execute-api.ap-southeast-1.amazonaws.com/LIVE/"
            var apiURL = baseURL + this.urlPostFix;
            returnValue = apiURL + urlPostFix;
        }
        return returnValue
    }

    triggerVoyagerAPI() {
        this.postVoyagerAPITrigger('{}').subscribe({
            next: responseData => {
                if (!environment.production) {
                    console.log('Voyager API Activated ...');
                }
            },
            error: err => {
                if (!environment.production) {
                    console.log('Errors Occured');
                }
            }
        })
    }

    triggerVoyager() {
        this.postVoyagerTrigger('{}').subscribe({
            next: responseData => {
                if (!environment.production) {
                    console.log('Voyager USSD Activated ...')
                }
            },
            error: err => {
                if (!environment.production) {
                    console.log('Errors Occured');
                }
            }
        })
    }

    triggerVoyagerUnity(ExecutionTypeId) {
        this.postVoyagerUnityTrigger(ExecutionTypeId).subscribe({
            next: responseData => {
                if (!environment.production) {
                    console.log('Voyager Unity Activated ...');
                }
            },
            error: err => {
                if (!environment.production) {
                    console.log('Errors Occured');
                }
            }
        })
    }

    postVoyagerTrigger(reqBody?: any): Observable<any> {
        return this.http.post<JSON>(this.setVoyagerApiUrl(''), reqBody, this.httpOptions).pipe(
            tap(data => {
                if (!environment.production) {
                    console.log("Request: ", reqBody)
                }
            }),
            tap(data => {
                if (!environment.production) {
                    console.log("Response: ", JSON.stringify(data))
                }
            }),
            catchError(this.handleError)
        );
    }

    postVoyagerAPITrigger(reqBody?: any): Observable<any> {
        return this.http.post<JSON>(this.setVoyagerAPIApiUrl(''), reqBody, this.httpOptions).pipe(
            tap(data => {
                if (!environment.production) {
                    console.log("Request: ", reqBody);
                }
            }),
            tap(data => {
                if (!environment.production) {
                    console.log("Response: ", JSON.stringify(data));
                }
            }),
            catchError(this.handleError)
        );
    }

    postVoyagerUnityTrigger(reqBody?: any): Observable<any> {
        return this.http.post<JSON>(this.setVoyagerUnityApiUrl(''), reqBody, this.httpOptions).pipe(
            tap(data => {
                if (!environment.production) {
                    console.log("Request: ", reqBody);
                }
            }),
            tap(data => {
                if (!environment.production) {
                    console.log("Response: ", JSON.stringify(data));
                }
            }),
            catchError(this.handleError)
        );
    }

    initialise() {
        
        this.httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.sessionService.slaveService.iSessionLive.CognitoToken
            })
          };
    }

    //*** SERIVCE CALLS GO BELOW THIS LINES ***//
    //Generic Save is meant to take in the JSON Payload with the defined rules to execute any stored procedure via the AWS Architecture. [Denver Naidoo - 06 Dec 2020]
    postInterface(reqBody: any, urlPostFix?: string): Observable<any> {
        try {
            this.initialise();

            if(!urlPostFix || urlPostFix == '') {
                //Pass a sessionId along with every call
                reqBody.SessionId = sessionStorage.getItem('MountDoom');
                urlPostFix = '';
            }
                        
            switch(urlPostFix) {
                case '' : {
                    return this.http.post<JSON>(this.setApiUrl(urlPostFix), reqBody, this.httpOptions).pipe(
                        tap(data => {
                            if (!environment.production) {
                                console.log("Request: ", reqBody);
                            }
                        }),
                        tap(data => {
                            if (!environment.production) {
                                console.log("Response: ", data);
                            }
                        }),
                        catchError(this.handleError)
                    );
                }

                case 'fargo' : {
                    return this.http.post<JSON>(this.setApiUrl(urlPostFix), reqBody, this.httpOptions).pipe(
                        // tap(data => {
                        //     if (!environment.production) {
                        //         console.log("Request: ", reqBody)
                        //     }
                        // }),
                        // tap(data => {
                        //     if (!environment.production) {
                        //         console.log("Response: ", JSON.stringify(data));
                        //     }
                        // }),
                        catchError(this.handleError)
                    );
                }
                
                case 'alabama': {          
                    var reqBodyString = this.setRequestBody(reqBody,urlPostFix);
                    return this.http.post<JSON>(this.setApiUrl(urlPostFix), reqBodyString, this.httpOptions).pipe(
                        tap(data => {
                            if (!environment.production) {
                                console.log("Request: ", reqBody);
                            }
                        }),
                        tap(data => {
                            if (!environment.production) {
                                console.log("Response: ", JSON.stringify(data));
                            }
                        }),
                        catchError(this.handleError)
                    );
                }

                case 'florida': {          
                    var reqBodyString = this.setRequestBody(reqBody,urlPostFix);
                    return this.http.post<JSON>(this.setApiUrl(urlPostFix), reqBodyString, this.httpOptions).pipe(
                        tap(data => {
                            if (!environment.production) {
                                console.log("Request: ", reqBody);
                            }
                        }),
                        tap(data => {
                            if (!environment.production) {
                                console.log("Response: ", JSON.stringify(data));
                            }
                        }),
                        catchError(this.handleError)
                    );
                }

                case 'pittsburg': {
                    if (!environment.production) {console.log('master-service :: postInterface - pittsburgh :',reqBody, urlPostFix);}
                    var reqBodyString = this.setRequestBody(reqBody,urlPostFix);
                    return this.http.post<JSON>(this.setApiUrl(urlPostFix), reqBody, this.httpOptions).pipe(
                        // tap(data => {
                        //     if (!environment.production) {
                        //         console.log("Request: ", reqBody);
                        //     }
                        // }),
                        // tap(data => {
                        //     if (!environment.production) {
                        //         console.log("Response: ", JSON.stringify(data));
                        //     }
                        // }),
                        catchError(this.handleError)
                    );
                }
            }
        }
        catch (exception) {
            if (!environment.production) {
                console.log('#EXCEPTION: ', exception);
            }
          }
    }



    setRequestBody(requestBody: any ,urlPostFix: string ): any {
        switch(urlPostFix) {
            case 'alabama': {
                var stateMachineArnString: string;
                //var inputString  = '"input": "{\\"ApiAlias\\":\\"ReceiveNextSchedule\\",\\"Hours\\":24}"';
                var inputString  = '"input": "{\\"ApiAlias\\":\\"' + requestBody.ApiAlias + '\\",\\"Hours\\": ' + requestBody.Hours + '}"';
                if (!environment.production) { 
                    stateMachineArnString = '"stateMachineArn":"arn:aws:states:ap-southeast-1:389334704590:stateMachine:Alabama-DEV"';
                    // returns '{"input": "{\\"ApiAlias\\":\\"ReceiveNextSchedule\\",\\"Hours\\":24}","stateMachineArn":"arn:aws:states:ap-southeast-1:389334704590:stateMachine:Alabama-DEV"}'
                } 
                return '{' + inputString + ',' + stateMachineArnString + '}';
            }

            case 'florida': {
                var stateMachineArnString: string;
                //var inputString  = '"input": "{\\"ApiAlias\\":\\"ReceiveNextSchedule\\"}"';
                var inputString  = '"input": "{\\"ApiAlias\\":\\"' + requestBody.ApiAlias + '\\"}"';
                if (!environment.production) { // DEV StateMachine
                    stateMachineArnString = '"stateMachineArn":"arn:aws:states:ap-southeast-1:389334704590:stateMachine:Florida-DEV"';
                } else { // Prod / LIVE StateMachine
                    stateMachineArnString = '"stateMachineArn":"arn:aws:states:ap-southeast-1:389334704590:stateMachine:Florida-LIVE"';
                }
                return '{' + inputString + ',' + stateMachineArnString + '}';
            }
        }
        // Catch All : return unmodified requestBody
        return requestBody;
    }



    post(reqBody: string): Observable<any> {
        return this.http.post<JSON>(this.setApiUrl(''), reqBody, this.httpOptions).pipe(
            tap(data => {
                if (!environment.production) {
                    console.log("Request: ", reqBody);
                }
            }),
            tap(data => {
                if (!environment.production) {
                    console.log("Response: ", JSON.stringify(data));
                }
            }),
            catchError(this.handleError)
        );
    }

    getReportSummary(reqBody: string): Observable<IGetServiceNames[]> {
        return this.http.post<IGetServiceNames[]>(this.setApiUrl(''), reqBody, this.httpOptions).pipe(
            //tap(data => console.log(JSON.stringify(data))),
            catchError(this.handleError)
        );
    }

    getServiceNames(reqBody: string): Observable<IGetServiceNames[]> {
        return this.http.post<IGetServiceNames[]>(this.setApiUrl(''), reqBody, this.httpOptions).pipe(
            // tap(data => console.log(JSON.stringify(data))),
            catchError(this.handleError)
        );
    }

    getMobileTestList(): Observable<IMobileTest[]> {        

        return this.http.get<IMobileTest[]>(this.setApiUrl('')).pipe(
            tap(data => data),
            catchError(this.handleError)
        );
    }

    _getMobileTestList(): Observable<IMobileTest[]> {

        return this.http.post<IMobileTest[]>(this.setApiUrl(''), '{}', this.httpOptions).pipe(
            // tap(data => console.log(JSON.stringify(data))),
            catchError(this.handleError)
        );
    }

    getMobileServiceList(): Observable<IMobileTest[]> {

        return this.http.get<IMobileTest[]>(this.setApiUrl('')).pipe(
            tap(data => data),
            catchError(this.handleError)
        );
    }

    getPrerequisiteList(): Observable<IMobileTest[]> {        

        return this.http.post<IMobileTest[]>(this.setApiUrl(''), '{}', this.httpOptions).pipe(
            tap(data => data),
            catchError(this.handleError)
        );
    }

    postDiagnostics(): Observable<IServicePrerequisite[]> {        

        return this.http.get<IServicePrerequisite[]>(this.setApiUrl('')).pipe(
            tap(data => data),
            catchError(this.handleError)
        );
    }

    //Error Handling Mehtod
    private handleError(err: HttpErrorResponse) {

        let errorMessage = '';

        if (err.error instanceof ErrorEvent) {
            errorMessage = `Errors have Occured: ${err.error.message} `;
        } else {
            errorMessage = `Server returned code: ${err.status}. Error Status is ${err.message}`;
        }

        if (!environment.production) {
            console.log('Error Message: [master-service]: ' + errorMessage);
        }

        return throwError(errorMessage);

    }

    //---------------------------------------------------------------------------------------------
    // Default API for Inquest with multiple paths [Denver Naidoo - 9 Dec 2021]
    //---------------------------------------------------------------------------------------------
    postInquest(reqBody: any, urlPostFix?: string): Observable<any> {
        try {
            this.initialise();
            return this.http.post<JSON>(this.setApiUrl(urlPostFix), reqBody, this.httpOptions).pipe(
                tap(data => {
                    if (!environment.production) {
                        console.log("Request: ", reqBody);
                    }
                }),
                tap(data => {
                    if (!environment.production) {
                        console.log("Response: ", JSON.stringify(data));
                    }
                }),
                catchError(this.handleError)
            );
        }
        catch (exception) {
            if(!environment.production){
              console.log('#EXCEPTION::master-service::postInquest() ', exception);
            }
          } 
    }


    //---------------------------------------------------------------------------------------------
    // Set the INQUEST Generic API URL [Denver Naidoo - 9 Dec 2021]
    //---------------------------------------------------------------------------------------------
    setInquestApiUrl(urlPostFix: string): string{
        var baseURL = '"https://urta96afe9.execute-api.ap-southeast-1.amazonaws.com/';
        var returnValue = '';

        if (!environment.production) { 
            // dev env
            returnValue = `${baseURL}dev/${urlPostFix}`; 
        } else {
            // prod env
            returnValue = returnValue = `${baseURL}live/${urlPostFix}`; 
        }
            
        return returnValue;
    }


}