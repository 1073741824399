import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class InputValidationService {
    private hasDeviceEvent = new BehaviorSubject({'GUID': "", 'hasDevice': false, 'msisdn': ""});

    emitChildEvt(msg) {
        this.hasDeviceEvent.next(msg);
    }

    childEventListener() {
        return this.hasDeviceEvent.asObservable();
    }
}