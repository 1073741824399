import { Routes } from '@angular/router';
import { MainComponent }   from './main/main.component';
import { AuthLayoutComponent } from './auth/auth-layout.component';

export const AppRoutes: Routes = [{
  path: '',
  redirectTo: 'dashboard',
  pathMatch: 'full',
},{
  path: '',
  component: MainComponent,
  children: [{
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'landing-page',
    loadChildren: () => import('./landing-page/landing-page.module').then(m => m.LandingPageModule)
  },
  {
    path: 'main-app',
    loadChildren: () => import('./main-app/main-app.module').then(m => m.MainAppModule)
  },
  {
    path: 'mobile-test-configure',
    loadChildren: () => import('./mobile-test-configure/mobile-test-configure.module').then(m => m.MobileTestConfigureModule)
  },
  {
    path: 'mobile-test-ussd',
    loadChildren: () => import('./mobile-test-ussd/mobile-test-ussd.module').then(m => m.MobileTestUSSDModule)
  },
  {
    path: 'mobile-hardware',
    loadChildren: () => import('./mobile-hardware/mobile-hardware.module').then(m => m.MobileHardwareModule)
  },
  {
    path: 'mobile-test-ussd-api',
    loadChildren: () => import('./mobile-test-ussd-api/mobile-test-ussd-api.module').then(m => m.MobileTestUSSDApiModule)
  },
  {
    path: 'mobile-test-schedule',
    loadChildren: () => import('./mobile-test-schedule/mobile-test-schedule.module').then(m => m.MobileTestScheduleModule)
  },
  {
    path: 'mobile-device',
    loadChildren: () => import('./mobile-device/mobile-device.module').then(m => m.MobileServiceModule)
  },
  {
    path: 'mobile-service',
    loadChildren: () => import('./mobile-service/mobile-service.module').then(m => m.MobileServiceModule)
  },
  {
    path: 'mobile-test-report',
    loadChildren: () => import('./mobile-test-report/mobile-test-report.module').then(m => m.MobileTestReportModule)
  },
  {
    path: 'mobile-dashboards',
    loadChildren: () => import('./mobile-dashboards/mobile-dashboards.module').then(m => m.DashboardsModule)
  },
  {
    path: 'video-menu',
    loadChildren: () => import('./video-menu/video-menu.module').then(m => m.VideoMenuModule)
  },
  {
    path: 'demo-menu',
    loadChildren: () => import('./demo-menu/demo-menu.module').then(m => m.DemoMenuModule)
  },
  //Fraud Menu
  {
    path: 'fraud-config',
    loadChildren: () => import('./fraud-config/fraud-config.module').then(m => m.FraudConfigModule)
  },

  //Big Data Menu
  {
    path: 'big-data-relational-ingestion-hdfs-s',
    loadChildren: () => import('./big-data-oracle-to-hdfs-s/big-data-relational-ingestion-hdfs-s.module').then(m => m.SpeedRelationalIngestionHdfsModule)
  },
  {
    path: 'big-data-relational-ingestion-hdfs-b',
    loadChildren: () => import('./big-data-oracle-to-hdfs-b/big-data-relational-ingestion-hdfs-b.module').then(m => m.BatchRelationalIngestionHdfsModule)
  },
  {
    path: 'big-data-streams-to-hdfs-s',
    loadChildren: () => import('./big-data-streams-to-hdfs-s/big-data-streams-to-hdfs-s.module').then(m => m.StreamsToHDFSSModule)
  },
  {
    path: 'big-data-files-to-hdfs-b',
    loadChildren: () => import('./big-data-files-to-hdfs-b/big-data-files-to-hdfs-b.module').then(m => m.FilesToHDFSBModule)
  },
  {
    path: 'big-data-oracle-to-scylla-s',
    loadChildren: () => import('./big-data-oracle-to-scylla-s/big-data-oracle-to-scylla-s.module').then(m => m.OracleToScyllaSModule)
  },
  {
    path: 'big-data-oracle-to-hive-b',
    loadChildren: () => import('./big-data-oracle-to-hive-b/big-data-oracle-to-hive-b.module').then(m => m.OracleToHive2BModule)
  },
  {
    path: 'big-data-oracle-to-redis-s',
    loadChildren: () => import('./big-data-oracle-to-redis-s/big-data-oracle-to-redis-s.module').then(m => m.OracleToRedisSModule)
  },
  {
    path: 'big-data-streams-to-scylla-s',
    loadChildren: () => import('./big-data-streams-to-scylla-s/big-data-streams-to-scylla-s.module').then(m => m.StreamsToScyllaSModule)
  },
  {
    path: 'big-data-tech-talk',
    loadChildren: () => import('./big-data-tech-talk/big-data-tech-talk.module').then(m => m.TechTalkModule)
  },
  {
    path: 'big-data-streams-to-redis-s',
    loadChildren: () => import('./big-data-streams-to-redis-s/big-data-streams-to-redis-s.module').then(m => m.StreamsToRedisSModule)
  },
  {
    path: 'big-data-streams-to-neo4j-s',
    loadChildren: () => import('./big-data-streams-to-neo4j-s/big-data-streams-to-neo4j-s.module').then(m => m.StreamsToNeo4JSModule)
  },
  {
    path: 'big-data-logs-to-hdfs-b',
    loadChildren: () => import('./big-data-logs-to-hdfs-b/big-data-logs-to-hdfs-b.module').then(m => m.LogsToHDFSBModule)
  },
  {
    path: 'big-data-oracle-to-neo4j-s',
    loadChildren: () => import('./big-data-oracle-to-neo4j-s/big-data-oracle-to-neo4j-s.module').then(m => m.OracleToNeo4JSModule)
  },
  {
    path: 'big-data-oracle-to-influxdb-s',
    loadChildren: () => import('./big-data-oracle-to-influxdb-s/big-data-oracle-to-influxdb-s.module').then(m => m.OracleToInfluxDBSModule)
  },
  {
    path: 'big-data-streams-to-influxdb-s',
    loadChildren: () => import('./big-data-streams-to-influxdb-s/big-data-streams-to-influxdb-s.module').then(m => m.StreamsToInfluxDBSModule)
  },
  {
    path: 'big-data-streams-to-grafana-s',
    loadChildren: () => import('./big-data-streams-to-grafana-s/big-data-streams-to-grafana-s.module').then(m => m.StreamsToGrafanaSSModule)
  },
  {
    path: 'big-data-oracle-to-grafana-s',
    loadChildren: () => import('./big-data-oracle-to-grafana-s/big-data-oracle-to-grafana-s.module').then(m => m.OracleToGrafanaSSModule)
  },
  {
    path: 'big-data-hld',
    loadChildren: () => import('./big-data-hld/big-data-hld.module').then(m => m.HLDModule)
  },
  {
    path: 'big-data-admin',
    loadChildren: () => import('./big-data-admin/big-data-admin.module').then(m => m.BigDataAdminModule)
  },
  {
    path: 'big-data-infrastructure',
    loadChildren: () => import('./big-data-infrastructure/big-data-infrastructure.module').then(m => m.NodeConfigModule)
  },
  {
    path: 'big-data-prod-install-docs',
    loadChildren: () => import('./big-data-prod-install-docs/big-data-prod-install-docs.module').then(m => m.BigDataProdInstallDocsModule)
  },
  {
    path: 'big-data-mdm',
    loadChildren: () => import('./big-data-mdm/big-data-mdm.module').then(m => m.BigDataMasterDataManagementModule)
  },
  {
    path: 'big-data-ml',
    loadChildren: () => import('./big-data-ml/big-data-ml.module').then(m => m.BigDataMachineLearningModule)
  },
  {
    path: 'big-data-security',
    loadChildren: () => import('./big-data-security/big-data-security.module').then(m => m.BigDataSecurityModule)
  },
  {
    path: 'big-data-lambda',
    loadChildren: () => import('./big-data-lambda/big-data-lambda.module').then(m => m.BigDataLambdaModule)
  },
  {
    path: 'big-data-is-architecture',
    loadChildren: () => import('./big-data-is-architecture/big-data-is-architecture.module').then(m => m.BigDataISArchitectureModule)
  },
  {
    path: 'big-data-data-processing',
    loadChildren: () => import('./big-data-data-processing/big-data-data-processing.module').then(m => m.DataProcessingModule)
  },
  {
    path: 'big-data-analytics',
    loadChildren: () => import('./big-data-analytics/big-data-analytics.module').then(m => m.AnalyticsAndQueryModule)
  },
  {
    path: 'big-data-high-availability',
    loadChildren: () => import('./big-data-high-availability/big-data-high-availability.module').then(m => m.HighAvailabilityModule)
  },
  {
    path: 'big-data-development-setup',
    loadChildren: () => import('./big-data-development-setup/big-data-development-setup.module').then(m => m.BigDataInfrastructureModule)
  },
  {
    path: 'dev-utils',
    loadChildren: () => import('./dev-utils/dev-utils.module').then(m => m.DevUtilsModule)
  },
  {
    path: 'mobile-report',
    loadChildren: () => import('./mobile-report/mobile-report.module').then(m => m.MobileReportModule)
  },
  {
    path: 'alerts',
    loadChildren: () => import('./alerts/alerts.module').then(m => m.AlertsModule)
  },

  // /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Marwyk - Apogee Pages
  // /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  {
    path: 'Apogee',
    loadChildren: () => import('./apogee/apogee.module').then(m => m.ApogeeModule)
  },

  // { //// removed folder ////
  //   path: 'emails',
  //   loadChildren: () => import('./emails/emails.module').then(m => m.EmailsModule)
  // },{ //// removed folder ////
  //   path: 'chat',
  //   loadChildren: () => import('./chat/chat.module').then(m => m.ChatModule)
  // },{ //// removed folder ////
  //   path: 'features',
  //   loadChildren: () => import('./features/features.module').then(m => m.FeaturesModule)
  // },{ //// COMPLETE FOLDER RETAINED -- /components/popover/popover.component REFERENCED IN de-utils.module.ts ////
  //   path: 'components',
  //   loadChildren: () => import('./components/components.module').then(m => m.ComponentsModule)
  // },{ //// removed folder ////
  //   path: 'icons',
  //   loadChildren: () => import('./icons/icons.module').then(m => m.IconsModule)
  // },{ //// removed folder ////
  //   path: 'cards',
  //   loadChildren: () => import('./cards/cards.module').then(m => m.CardsModule)
  // },{ //// removed folder ////
  //   path: 'forms',
  //   loadChildren: () => import('./forms/forms.module').then(m => m.FormsDemoModule)
  // },{ //// removed folder ////
  //   path: 'tables',
  //   loadChildren: () => import('./tables/tables.module').then(m => m.TablesDemoModule)
  // },{ //// removed folder ////
  //   path: 'data-tables',
  //   loadChildren: () => import('./data-tables/datatables.module').then(m => m.DataTablesDemoModule)
  // },{ //// COMPLETE FOLDER RETAINED -- /mobile-test-report/dashboard-live/dashboard-live.component.ts ////
  //   path: 'chart',
  //   loadChildren: () => import('./chart/charts.module').then(m => m.ChartsDemoModule)
  // },{ //// removed folder ////
  //   path: 'maps',
  //   loadChildren: () => import('./maps/maps.module').then(m => m.MapsDemoModule)
  // },{ //// removed folder ////
  //   path: 'pages',
  //   loadChildren: () => import('./custom-pages/pages.module').then(m => m.PagesDemoModule)
  // },{ //// removed folder ////
  //   path: 'user-pages',
  //   loadChildren: () => import('./user-pages/users.module').then(m => m.UsersModule)
  // },{ //// removed folder ////
  //   path: 'calendar',
  //   loadChildren: () => import('./calendar/calendar.module').then(m => m.CalendarDemoModule)
  // },{ //// removed folder ////
  //   path: 'media',
  //   loadChildren: () => import('./media/media.module').then(m => m.MediaModule)
  // },{ //// removed folder ////
  //   path: 'editor',
  //   loadChildren: () => import('./editor/editor.module').then(m => m.EditorDemoModule)
  // }
  //
  // More folders deleted -- not in routes...
  // 1) data


]
}, {
  path: '',
  component: AuthLayoutComponent,
  children: [
    {
    path: 'authentication',
    loadChildren: () => import('./session/session.module').then(m => m.SessionModule)
    },
    {
      path: 'error',
      loadChildren: () => import('./error/error.module').then(m => m.ErrorModule)
    }
  ]
}];


