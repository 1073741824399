<!-- Inqest Header -->
<div class="inquest-header">
   <!-- Menu Button -->
   <ng-container *ngIf="isLoggedIn && innerWidth < 1024">
      <a class="inquest-header-menu-btn" (click)="toggleIsMenuOpen()">
         <i class="fa fa-bars fa-3x" aria-hidden="true"></i>
      </a>
   </ng-container>

   <!-- Brand Logo / Home -->
   <a routerLink="/landing-page" class="no-link">
      <img src="../../assets/rebrand/Inquest_rebrand_white.png" alt="inquest brand logo" class="inquest-logo">
   </a>

   <!-- Login -->
   <ng-container *ngIf="!isLoggedIn">
      <a mat-flat-button href="{{authenticator}}" color="warn">
         {{ loginDisplay }}
      </a>
   </ng-container>

   <!-- Horizontal Menu -->
   <ng-container *ngIf="isLoggedIn && innerWidth >= 1024">
      <div class="inquest-horizontal-menu">
         <ng-container *ngFor="let menuitem of horizontalMenuItems.getAll(isLoggedIn) | slice:1">
            <button mat-button [matMenuTriggerFor]="Menu" class="inquest-menu-item nav-menu-btn">{{ menuitem.name | translate }}</button>
            <mat-menu #Menu="matMenu" xPosition="after">
               <ng-container *ngFor="let childitem of menuitem.children">
                  <a [routerLink]="['/'+childitem.state ]" mat-menu-item *ngIf="childitem.type !== 'super-sub'">
                     {{ childitem.name | translate }}
                  </a>
                  <button mat-menu-item [matMenuTriggerFor]="subMenu" *ngIf="childitem.type === 'super-sub'">
                     {{ childitem.name | translate }}
                  </button>
                  <mat-menu #subMenu="matMenu" xPosition="after">
                     <a mat-menu-item [routerLink]="['/'+subchild.state ]" *ngFor="let subchild of childitem.subchildren">
                        {{ subchild.name | translate }}
                     </a>
                  </mat-menu>
               </ng-container>
            </mat-menu>
         </ng-container>

         <div class="ml-auto d-flex">
            <img 
               src="../../assets/images/breakpoint_logo_512.png"
               class="user-settings-btn"
               role="button"
               width="45"
               height="45"
               [matMenuTriggerFor]="userMenu"
            />

            <mat-menu #userMenu="matMenu">
               <div class="d-flex flex-column user-session-information">
                  <span class="username">{{ iSessionLive.FirstName }} {{ iSessionLive.LastName }}</span>
                  <span class="email">{{ iSessionLive.Email }}</span>
               </div>
               <button mat-menu-item color="warn" (click)="logOff()">
                  Log Out
               </button>
            </mat-menu>
         </div>
      </div>
   </ng-container>
</div>

<!-- Main window, with side navigation menu -->
<mat-sidenav-container class="inquest-base-container">
   <!-- Inquest Menu -->
   <mat-sidenav [mode]="innerWidth >= 1024 ? 'side' : 'over'" [opened]="isMenuOpen" class="sidenav" [class.mat-elevation-z4]="true" *ngIf="innerWidth < 1024">
      <!-- Session Information -->
      <ng-container *ngIf="iSessionLive">
         <div class="session-info">
            <span class="username">{{ iSessionLive.FirstName }}</span>
            <span class="email">{{ iSessionLive.Email }}</span>
         </div>
         <mat-divider></mat-divider>
      </ng-container>

      <!-- Menu Items -->
      <mat-list class="inquest-menu-container">
         <mat-accordion multi="false">
            <ng-container *ngFor="let menuitem of horizontalMenuItems.getAll(isLoggedIn)">
               <div mat-subheader>{{ menuitem.name | translate }}</div>
               <ng-container *ngFor="let childitem of menuitem.children">
                  <!-- No Children-->
                  <mat-nav-list *ngIf="childitem.type !== 'super-sub'">
                     <a mat-list-item [routerLink]="['/'+childitem.state ]">
                        <mat-icon matListIcon>{{ menuitem.icon }}</mat-icon>
                        <span matLine>{{ childitem.name }}</span>
                     </a>
                  </mat-nav-list>
                  <!-- Has Children -->
                  <mat-expansion-panel *ngIf="childitem.type === 'super-sub'" [class.mat-elevation-z0]="true">
                     <mat-expansion-panel-header class="mat-expansion-panel-header-overwrite">
                        <mat-panel-title>
                           <mat-list-item>
                              <mat-icon matListIcon>{{ menuitem.icon }}</mat-icon>
                              <span matLine>{{ childitem.name | translate }}</span>
                           </mat-list-item>
                        </mat-panel-title>
                     </mat-expansion-panel-header>
                     <!-- Panel Content -->
                     <!-- TODO: Make Chilren More Presentable -->
                     <mat-nav-list>
                        <a mat-list-item [routerLink]="['/'+subchild.state ]" *ngFor="let subchild of childitem.subchildren">
                           {{ subchild.name | translate }}
                        </a>
                     </mat-nav-list>
                  </mat-expansion-panel>  
               </ng-container>
               <mat-divider class="accent"></mat-divider>
            </ng-container>
         </mat-accordion>

         <!-- Logoff Button -->
         <mat-list-item class="inquest-logout-container">
            <button mat-flat-button color="warn" (click)="logOff()" class="ml-auto">Log Out</button>
         </mat-list-item>
      </mat-list>
   </mat-sidenav>

   <!-- Inquest Content -->
   <mat-sidenav-content role="main" class="rb-bg-image">
      <!-- Inquest -->
      <router-outlet></router-outlet>
   </mat-sidenav-content>
</mat-sidenav-container>