import { Injectable, isDevMode } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InquestDeveloperService {

  constructor() { }

  // TODO: update to proper permission fetching if required
  // Avoid using other services, so that they can make use of the logging function,
  // without creating a circular dependancy
  loadGroups() {
    let sessionGroups = sessionStorage.getItem("Gollum");
    return sessionGroups;
  }

  // log output to console when in dev env/dev mode
  userGroups: string[] = [];
  consoleLog(...options: any[]): void {
    // load user groups if empty
    if (this.userGroups.length === 0) {
      this.userGroups = this.loadGroups().split(',');
    }
    
    // Allow logging in development
    if (isDevMode()) {
      console.log(...options);
      return;
    }

    // Allow logging in prod for inquest_developer
    if (!isDevMode() && this.userGroups.includes("inquest_developer")) {
      console.log(...options);
    } 
    // else {
    //   console.log("This log was hidden. Please enable Developer Permissions to view this log.");
    // }
  }
}