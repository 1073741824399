import { NgModule} from '@angular/core';
import { BrowserModule} from '@angular/platform-browser';
import { BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { CommonModule } from '@angular/common';
import { HttpModule, Http } from '@angular/http';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
// import { TourNgBootstrapModule } from 'ngx-tour-ng-bootstrap';

import { DemoMaterialModule } from './material-demo.module';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateService} from '@ngx-translate/core';

import { inQuestAppComponent} from './app.component';
import { AppRoutes} from "./app-routing.module";
import { MainComponent }   from './main/main.component';
import { AuthLayoutComponent } from './auth/auth-layout.component';
import { MenuToggleModule } from './core/menu/menu-toggle.module';
import { MenuItems } from './core/menu/menu-items/menu-items';
import { HorizontalMenuItems } from './core/menu/menu-items/horizontal-menu-items';
import { PageTitleService } from './core/page-title/page-title.service';
import { PermissionsService } from './service/permissions.service';
import { FrontEndService } from './service/front-end.service';
import { FilterPipe } from './filter.pipe';
import { ClipboardModule } from 'ngx-clipboard';
import { MobileDataService } from './service/mobile-data.service';
import { MobileDeviceService } from './service/mobile-device.service';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { InputValidationService } from './service/input-validation.service';
import { DevicesService } from './service/devices.service';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
	imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        DemoMaterialModule,
        RouterModule.forRoot(AppRoutes, { relativeLinkResolution: 'legacy' }),
        PerfectScrollbarModule,
        MenuToggleModule,
        HttpModule,
        HttpClientModule,
        ClipboardModule,
        // TourNgBootstrapModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        NgMultiSelectDropDownModule.forRoot(),
	],
	declarations: [
		inQuestAppComponent, 
		MainComponent,
        AuthLayoutComponent,
        FilterPipe,
	],
	bootstrap: [inQuestAppComponent],
	providers:[
		MenuItems,
        HorizontalMenuItems,
        TranslateService,
		PageTitleService,
        PermissionsService,
        FrontEndService,
        MobileDataService,
        MobileDeviceService,
        DevicesService,
        InputValidationService,
      {
         provide: PERFECT_SCROLLBAR_CONFIG,
         useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
      }
	]
})
export class inQuestAppModule { }
