import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filterAll' })
export class FilterPipe implements PipeTransform {
  transform(value: any, searchText: any): any {
    if(!searchText) {
      return value;
    }
    return value.filter((data) => this.matchValue(data,searchText)); 
  }

  matchValue(data, value) {
    return Object.keys(data).map((key) => {
       return new RegExp(value, 'gi').test(data[key]);
    }).some(result => result);
  }
 }


@Pipe({name: 'hasProp'})
export class HasPropPipe implements PipeTransform {
  transform(object: object, prop: string): boolean {
    return object.hasOwnProperty(prop);
  }
}

@Pipe({name: 'testRunStatus'})
export class TestRunStatus implements PipeTransform {
  transform(object: string='TEST_RUN_STATUS#Pending', prop: string='#'): string {
    return object.split(prop)[1]
  }
}

@Pipe({name: 'testType'})
export class TestType implements PipeTransform {
  transform(object: string='1#POSITIVE_TEST_CASE', prop: string='#'): string {
    return object.split(prop)[1]
  }
}