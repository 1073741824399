import { ITestCaseFormula } from "./i-application";
import { ISearch, ISessionLite } from "./i-responses";
import { ISessionLive } from "./i-responses"

export interface IDynamoKey {
    pk: string;
    sk?: string;
    GUID?: string;
    sort_key?: ISortKey;
  }

  export interface IDynamoKeyLite {
    pk?: string;
    sk?: string;
  }

  export interface ISortKey {  
    sort_key_name?: string;
    sort_key_order?: string;
    sort_key_date_conversion?: boolean;
}

export interface IDynamoLSI {
    lsi_tag?: string;
    lsi_search?: string;
    lsi_date?: string;
    lsi_id?: number; 
    lsi_sk?: string;
}

export interface IDynamoGet extends IDynamoKey, IDynamoLSI {
    ColumnList?: string;
    lsi_name?: string;
    lsi_value?: string;
    key_condition?: string;
    sort_key_date_conversion?: boolean;
}

export interface IRemote extends IDynamoGet, ISearch, IDynamoLSI {
    ModemCount?: string;
    MSISDN?: string;
    RemoteName?: string;
    RemoteURL?: string;
    RemoteLocation?: string;
    DeviceFriendlyName?: string;
    RemoteNote?: string;
    MNO?: string;
    ExecutionType?: string;
    ModemNo?: string;
    DeviceComments?: string;
    DeviceStatus?: string;
    DeviceId?: string;
    CurrentPIN?: string;
    PreviousPIN?: string;
    ProdShortCode?: string;
    UATShortCode?: string;
    CurrentUATPIN?: string;
    PreviousUATPIN?: string;
    DeviceCapabilities?:IDeviceCapabilities;
    IsSimPool?:boolean,
    SimPool?:ISimPool,
    SimPoolSlot?:ISimPoolSlot,
    IMSI?:string,
    ICCID?:string,
    PORT?:string,
    DeviceReadyState?:DeviceReadyState,
    product_pins?:{},
    product_prev_pins?:{}
}

export enum DeviceReadyState{
    Ready = "READY",
    Unready ="UNREADY",
    Reject = "REJECT",
}

export interface IDeviceCapabilities {
    DataDrawdown?:boolean;
    VoiceDrawdown?:boolean;
    UatSms?:boolean;
    ApiExecution?:boolean;
    DataDrawdownSynthetic?:boolean;
    VoiceDrawdownSynthetic?:boolean;
    SyntheticSms?:boolean;
}

export interface IExecuteSelectedDevice {
    pk: string;
    sk: string;
    SessionId?: string;
    DeviceId?: string;
    TestBatchId?: string;
    DeviceRequest?: string;
    TestBatchGUID?: string;
    SessionLive?: ISessionLive;
    lsi_id: string;
  }

  export interface ISaveRecording extends IDynamoKey {    
    TestBatchGUID?: string;
    SessionLive?: ISessionLive;
    TestCaseHeader?: string;
    lsi_search?: string;
  }

  export interface IToken extends IDynamoGet, ISearch {
    TokenName?: string;
    TokenPrefix?: string;
    RegularExpression?: string;
    Notes?: string;
  }


  export interface IGetReportTestRunLite extends ISearch {
    TestCaseId?: number;
    TestChainId?: number;
    TestSuiteId?: number;
    TestBatchId?: number;
    StartDate?: Date;
    EndDate?:Date;
    TestRunStatusId?: number;
    TestRunStatus?: string;
    ExecutionTypeId?: number;
    ExecutionType?: string;
    CreatedByUserId?: number;
    CreatedByUserName?: string;
    RunByUserId?: number;
    RunByUserName?: string;
    TestTypeId?: number;
    TestCaseName?: string;
    lsi_date?: string;
}

export interface IGetReportTestRunDetailLite extends IDynamoLSI{
}

export interface ITestType extends IDynamoGet, ISearch {
  TestType?: string;
  TestTypeKey?: string;
  Description?: string;
}

export interface IPostTestChainDetail extends IDynamoKey {
  Sequence: number;
}

export interface ITestCaseHeader {
  pk: string;
  sk: string;
  TestCaseName?,Name?: string;
  TestCaseNotes?,Notes?: string;
  TestTypeKey?: string;
  Hashtag?: string;
  DeviceList?: IDynamoKey[];
  index?: number;
  Alerts?: IAlerts;
}

export interface ITestChainHeader {
  Name?: string;
  Notes?: string;
  Hashtag?: string;
}

export interface ITestChainDetail extends IDynamoKey, IDynamoLSI {
  GUID?: string;
  SessionLive?: ISessionLite;
  TestBatchGUID?: string;
  TestCaseHeader?: ITestCaseHeader;
  iRemoteList?: IRemote[];
}

export interface ISaveTestChain extends IDynamoKey, IDynamoLSI {
  TestChainHeader?: ITestChainHeader;
  SessionLive?: ISessionLite;
  TestCaseHeader?: ITestCaseHeader[];
}

export interface IGetTestChainHeader extends IDynamoKey, IDynamoLSI {
  TestChainHeader?: ITestChainHeader;
  SessionLive?: ISessionLite;
}

export interface IGetTestChainDetail extends IDynamoKey, IDynamoLSI {
  TestCaseHeader?: ITestCaseHeader;
}

export interface ITestRunQueue extends IDynamoKey, IDynamoLSI {
  Header?: IDynamoKey;
  SessionLive?: ISessionLite;
  ExecutionTypeRequested?: string;

}

export interface ISessionLogin extends IDynamoKey {
  SessionLive?: ISessionLite
}

export interface ISMSLite {
  index?: number;
  stat?: string;
  sender?: string;
  time?: string;
  message?: string;
}

export interface IEventPattern {
  Source?: string;
  DetailType?: string;
}

export interface IUATSMS {
  pk?: string; 
  sk: string; 
  msg?: string; 
  enduser?: string; 
  lsi_date?: string; 
  sms_system?: string; 
  sms_path?: string; 
  sms_host?: string; 
  sms_time?: string; 
  sms_environment?: string; 
  msisdn?: string; 
  sms_role?: string; 
  sms_location?: string;
}

export interface IGlobalDebug {
  lsi_tag?: string;
  lsi_date?: string;
  MessageTitle?: string;
  LogText?: string;
}


export interface ITestSuiteHeader {
  Name?: string;
  Notes?: string;
  Hashtag?: string;
}

export interface ISaveTestSuite extends IDynamoKey, IDynamoLSI {
  TestSuiteHeader?: ITestSuiteHeader;
  SessionLive?: ISessionLite;
  TestCaseHeader?: ITestCaseHeader[];
}

export interface INewTestSuiteHelper extends IDynamoKey, IDynamoLSI {
  Index?: string;
  GUID?: string;
  SessionLive?: ISessionLite;
  TestBatchGUID?: string;
  TestCaseHeader: ITestCaseHeader;
  lsi_search?: string;
  SingleDevice?: IDynamoKey
}

export interface ITestSuiteHeaderList extends IDynamoKey, IDynamoLSI {
 iTestSuiteHeader: ITestSuiteHeader;
 iSessionLite: ISessionLite;
 ExecutionTypeRequested?: string;
}

export interface IPostiveTestCase {
  pk?: string;
  sk?: string;
  Name?: string;
  Hashtag?: string;
  Notes?: string;
  selected?: boolean;
  dateCreated?: any;
}

export interface ITokenisedDeviceResponse {
  TokenisedDeviceResponse?: string;
  TestCasePK?: string;
  TestCaseSK?: string;
}

export interface ITokenisedDeviceResponsePayload extends IDynamoKey {
  TokenisedDeviceResponse: ITokenisedDeviceResponse
}

export interface ITokenisedDeviceRequest {
  TokenisedDeviceRequest?: string;
  TestCasePK?: string;
  TestCaseSK?: string;
}

export interface ITokenisedDeviceRequestPayload extends IDynamoKey {
  TokenisedDeviceRequest: ITokenisedDeviceRequest
}

export interface ITestStepFormula {
  TestCaseFormula?: ITestCaseFormula[]
  TestCasePK?: string;
  TestCaseSK?: string;
}

export interface ITestStepFormulaPayload extends IDynamoKey {
  TestStepFormula: ITestStepFormula
}

export interface ITestChainTemplate extends IDynamoKey {
  TokenisedDeviceResponse?: ITokenisedDeviceResponse[],
  TokenisedDeviceRequest?: ITokenisedDeviceRequest[],
  TestStepFormula?: ITestStepFormula[]
}

export interface IUseSMS {
  SMSMessage?: string;
  RequestType?: string;
}

export interface IAlerts extends IDynamoKey  {
  Threshold?: string;
  Status?: string;
  DeliveryMethod?: string;
  CoolingOff?: string;
}


export interface ICustomerAlertStatus{
  pk?:string;
  sk?:string;
  DisplayName?:string;
  Status?:boolean;
}

export interface ICustomerAlertDelivery{
  pk?:string;
  sk?:string;
  DisplayName?:string;
  Status?:boolean;
}

export interface ICustomerScheduleFrequency{
  pk?:string;
  sk?:string;
  DisplayName?:string;
  Status?:boolean;
}

export interface ICustomerTestType{
  pk?:string;
  sk?:string;
  Description?:string;
  Status?:boolean;
  TestType?:string;
  TestTypeKey?:string;
}

export interface IVoiceDrawDownTarget{
  pk?:string;
  sk?:string;
  MSISDN?:string;
  Description?:string;
  Notes?:string;
}

export interface IMNO{
  pk?:string;
  sk?:string;
  MNOName?:string;
  Product?:IMNOProduct[];
}

export interface IMNOProduct{
  APIConfig?:string;
  APIShortCode?:string;
  APIURL?:string;
  Environment?:string;
  ProductDescription?:string;
  ProductName?:string;
  USSDShortCode?:string;
  sk?:string;
}

export interface IInquestConfig{
  pk?:string,
  sk?:string,
  Clients?:[IInquestConfigClient]
}

export interface IInquestConfigClient{
  InquestClientId?:number,
  ClientName?:string,
  OnboardingDate?:Date,
  tzDatabaseName?:string,
  WebsocketDetails?:string,
  pk?:string,
  sk?:string
}

export interface IClientTimezone{
  pk?:string,
  sk?:string,
  CountryCode?:string,
  CountryName?:string,
  tzDatabaseName?:string,
  GmtOffset?:string,
}

export interface IInquestTestAutomationConfig{
  pk?:string,
  sk?:string,
  OnboardingDate?:Date,
  SubscriptionExpirationDate?:Date
}

export interface IApogeeConfig{
  pk?:string,
  sk?:string,
  OnboardingDate?:Date,
  SubscriptionExpirationDate?:Date,
  vantage_console?:IVantageConsoleConfig,
  dashboards?:IApogeeDashboard[],
  last_updated?:Date,
  lsi_date?:Date,
  org_info?:IApogeeOrgInfo,
  UTCDateCreated?:Date,
  UTCDateUpdated?:Date,
}

export interface IApogeeOrgInfo{
  id?:number,
  name?:string,
}

export interface IVantageConsoleConfig{
  api_header?:string,
  api_key?:string,
  endpoint?:string,
  username?:string,
}

export interface IApogeeDashboard{
  title?:string,
  uid?:string,
  uri?:string,
  url?:string
}

export interface IFullClientConfig{
  INQUEST_CONFIG?:IInquestConfigClient
  APOGEE_DASHBOARDS?:IApogeeConfig
}

export interface ISimPool{
  pk?:string,
  sk?:string,
  base_url?:string,
  Notes?:string
  slot_num?:number,
  slots?:ISimPoolSlot[],
}

export interface ISimPoolSlot
{
  bal?:string,
  cst?:string,
  dur?:number,
  goip_slot?:string,
  iccid?:string,
  imei?:string,
  imsi?:string,
  num?:string,
  opr?:string,
  sig?:number,
  slot?:string,
  stat?:string,
  type?:string,
  SimPoolSK?:string,
  UssdRobotLink?:IUssdRobot,
  UssdRobotPortLink?:IUssdRobotSlot,
  UssdRobotLinkData?:string,
  UssdRobotPortLinkData?:string,
}

export interface IUssdRobot{
  pk?:string,
  sk?:string,
  base_url?:string,
  grpc_address?:string,
  Notes?:string,
  slot_num?:number,
  slots?:IUssdRobotSlot[],
  DeviceCapabilities?:IDeviceCapabilities;
  disabled_slots?:string[],
}

export interface IUssdRobotSlot{
  pk?:string,
  sk?:string,
  SlotIndex?:number,
  SimPoolLink?:ISimPool,
  SimPoolSlotLink?:ISimPoolSlot,
  SimPoolLinkData?:any,
  bal?:string,
  cst?:string,
  dur?:number,
  sim_slot?:string,
  iccid?:string,
  imei?:string,
  imsi?:string,
  num?:string,
  opr?:string,
  sig?:number,
  slot?:string,
  stat?:string,
  type?:string,
  disabled?:boolean,
}

export interface IDataDrawDownTarget{
  pk?:string;
  sk?:string;
  download_url?:string;
  notes?:string;
}

export interface ITestScheduleHeader extends IDynamoKey, IDynamoLSI {
  Name?: string;
  Notes?: string;
  Hashtag?: string;
  TestType?:string,
  TestName?:string,
  TestPK?:string,
  TestSK?:string,
  DeviceSK?:string,
  Frequency?:number,
  Interval?:string,
  UTCStartDate?:string,
  UTCEndDate?:string,
  LatestExecution?:string,
  UserTTL?:number;
  SessionLive?: ISessionLive;
}

export interface ITestSchedule extends IDynamoKey, IDynamoLSI {
  pk:string;
  sk:string;
  DevicePayload:IRemote;
  schedule:IScheduleHeader;
  ExecutionTypeRequested?:string;
  ScheduleName?:string;
  Notes?:string;
  SessionLive?: ISessionLive;
  TestCaseName?:string;
  TestCaseNotes?:string;
  TestTypeKey?:string;
}

export interface IScheduleHeader{
  interval?:number;
  interval_type?:string;
  ttl?:number;
  not_before?:string;
  not_after?:string;
  name?:string;
}

export interface IRatingGroup{
  pk?:string;
  sk?:string;
  RGName?:string;
  Description?:string;
  Config?:string
}

export interface IDiameter{
  pk?:string;
  sk?:string;
  service_context?:string;
  origin_host?:string;
  origin_realm?:string;
  diameter_src_ip?:string;
}