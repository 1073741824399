import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import * as TextDiff from 'text-diff'
import { MenuItems } from '../core/menu/menu-items/menu-items';
import { HorizontalMenuItems } from '../core/menu/menu-items/horizontal-menu-items';
import { environment } from '../../environments/environment'
import { SlaveService } from '../service/slave-service.service'
import { PageTitleService } from '../core/page-title/page-title.service';
import { PermissionsService } from './permissions.service';
import { IGroupPolicy, IPrimaryGroupPolicy, GroupPolicyMenuMap, GroupPolicyLookupMap, AcceptableGroupPolicies, GroupPolicyRibbonMap } from '../interface/i-application';
import { env } from 'process';
import { mergeAll } from 'rxjs/operators';
import { IFullClientConfig } from '../interface/i-dynamo';

@Injectable({
    providedIn: 'root'
})

export class SessionService {
    //Session UserId
    public topicUserId: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public sessionUserId: string;
    public connectionId:string;

    setUserId(value: string) {
        this.topicUserId.next(value);
    };

    public topicNickName: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public sessionNickName: string;

    setNickname(value: string) {
        this.topicNickName.next(value);
    };

    public topicFamilyName: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public sessionFamilyName: string;

    setFamilyName(value: string) {
        this.topicFamilyName.next(value);
    };

    public topicEmail: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public sessionEmail: string;
    setEmail(value: string) {
        this.topicEmail.next(value);
    };

    public topicIsLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    public sessionIsLoggedIn: boolean;
    setIsLoggedIn(value: boolean) {
        this.topicIsLoggedIn.next(value);
    };

    public topicCollapseSideMenu: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public sessionIsSideMenu: string;
    setPrintArea(value: string) {
        this.topicCollapseSideMenu.next(value);
    };
    
    cognitoToken: string;
    poisonToken: string;
    poison: string = 'cyAn1d3'; // 7 character to poison the Token (Cyanide)
    antidote: string;
    locationHref: string;
    jwt: string;

    //create a constructor
    constructor(
        public menuItems: MenuItems,
        public horizontalMenuItems: HorizontalMenuItems,
        public slaveService: SlaveService,
        private pageTitleService: PageTitleService,
        private permissionsService: PermissionsService,
    ){}

    getCognitoToken(locationHref: string): string {
        var validLocation = locationHref.split('#');
        if (validLocation.length > 1) {
            var token = /id_token=([^#]+)/.exec(locationHref)[1];
            var cognitoToken = token.split('&')[0]; //Actual Cognito Token
            return cognitoToken
        }
        else {
            sessionStorage.removeItem('Frodo');
            sessionStorage.removeItem('Samwise');
            return ''
        }
    }

    getJWT(locationHref: string): string {
        var validLocation = locationHref.split('#');
        if (validLocation.length > 1) {
            var token = /id_token=([^#]+)/.exec(locationHref)[1];
            return token
        }
        else {
            return ''
        }
    }

    getPoisonedCognitoToken(cognitoToken: string, poison: string): string {
        var antidote = cognitoToken.substr(73, poison.length); //the token antidote to remove the poison
        var poisonToken = cognitoToken.replace(antidote, poison); //poison the token with Cyanide
        return poisonToken;
    }

    getAntidote(cognitoToken: string, poison: string): string {
        var antidote = cognitoToken.substr(73, poison.length); //the token antidote to remove the poison
        return antidote;
    }

    hydrateCognitoToken(poison: string): string {
        var poisonToken = sessionStorage.getItem('Frodo');
        var antidote = sessionStorage.getItem('Samwise');
        
        if (poisonToken !== null) {
            var revivedToken = poisonToken.replace(poison, antidote);
            return revivedToken;
        }
        else {
            return 
        }
    }

    getLoginPayload(locationHref: string): JSON {
        var loginPayload = this.parseJwt(locationHref.split('&')[0]);
        return loginPayload;
    }


    async startSession(locationHref) {
        var loginPayload: JSON;
        var permittedGroups: string[];
        var cognitoUserName: string;
        var companyId: string;

        this.locationHref = locationHref;
        this.cognitoToken = this.getCognitoToken(this.locationHref);
        this.slaveService.iSessionLive.CognitoToken = this.cognitoToken;
        

        if (this.cognitoToken.length > 0) {
            //successful Login
            this.poisonToken = this.getPoisonedCognitoToken(this.cognitoToken, this.poison);
            sessionStorage.setItem('Frodo', this.poisonToken); //Frodo Baggins his carrying the ring, but he is poisoned.
            this.antidote = this.getAntidote(this.cognitoToken, this.poison);
            sessionStorage.setItem('Samwise', this.antidote); //Samwise Gamgee is carrying the atidote that has poisened Frodo.
            this.jwt = this.getJWT(this.locationHref);
            
            loginPayload = this.getLoginPayload(this.locationHref);
            
            permittedGroups = loginPayload['cognito:groups']; //these are the groups the user has access to. New Users will not belong to any group and therefore will not have access to anything.            
            
            cognitoUserName = loginPayload['cognito:username']; //The Users Cognito User Name used to log into the application
            sessionStorage.setItem('Bilbo', cognitoUserName); //Cognito UserName
            this.slaveService.iSessionLive.UserName = cognitoUserName;
            
            sessionStorage.setItem('Gollum', permittedGroups.toString()); //Gollum has knowledge of all the User Groups that the successfull login has permissions to.
            this.slaveService.iSessionLive.CognitoGroups = permittedGroups.toString();
            
            sessionStorage.setItem('Gandalf', '1'); //If Gandalf is 1,then that is a successfull login that will allow access to the application.
            this.sessionIsLoggedIn = true;
            this.slaveService.iSessionLive.IsLoggedIn = true;

            //Store the session variables locally
            sessionStorage.setItem('Saruman', loginPayload['nickname']); //Saruman = Nickname
            this.sessionNickName = loginPayload['nickname'];
            this.slaveService.iSessionLive.NickName = loginPayload['nickname'];

            sessionStorage.setItem('Baggins', loginPayload['family_name']); //Baggins = Family Name
            this.sessionFamilyName = loginPayload['family_name'];
            this.slaveService.iSessionLive.LastName = loginPayload['family_name'];
            
            sessionStorage.setItem('Aragorn', loginPayload['given_name']); //Aragorn = given_name
            this.slaveService.iSessionLive.FirstName = loginPayload['given_name'];

            sessionStorage.setItem('Pippin', loginPayload['email']); //Pippin = Email Address
            this.sessionEmail = loginPayload['email'];
            this.slaveService.iSessionLive.Email = loginPayload['email'];

            companyId = this.getValueFromUserGroup('CompanyId');
            sessionStorage.setItem('WingNut', companyId); // WingNut = CompanyId
            this.slaveService.iSessionLive.ClientId = +companyId;

            this.pageTitleService.setSessionLive(this.slaveService.iSessionLive);
            // --------------------------------------------------------------------------------
            // Marwyk - Set new permission 
            // --------------------------------------------------------------------------------
            this.permissionsService.setSessionGPOs();

             //Log the User to the Database
            this.slaveService.addUser(
                sessionStorage.getItem('WingNut'),
                sessionStorage.getItem('Bilbo'),
                sessionStorage.getItem('Aragorn'),
                sessionStorage.getItem('Baggins'),
                sessionStorage.getItem('Saruman'),
                sessionStorage.getItem('Pippin')
            );
        }
      };

    loadMenuItems(fullClientConfig:IFullClientConfig)
    {
        //Do not attempt to load menus unless JWT exists ie. only after login
        if (this.jwt){
            if(this.getCompanyIds()) {
                this.menuItems.loadMenuItems();
                this.horizontalMenuItems.loadMenuItems(fullClientConfig);
            } else {
                alert('Incorrect Client Conifg. Please Contact Breakpoint Support. [CompanyId]');
            }
        }
    }
    
    parseJwt (token) {
        var base64Url = token.split('.')[1];

        if (environment.production) {
            base64Url = token.split('.')[2];
        }
        
        var base64 = base64Url.replace('-', '+').replace('_', '/');
        
        return JSON.parse(window.atob(base64));
    };

    getCompanyIds() {
        try
        {
            var base64Url = this.jwt.split('.')[1];
            var base64 = base64Url.replace('-', '+').replace('_', '/');
            let newjwt = JSON.parse(window.atob(base64));

            let assignedGroups = newjwt['cognito:groups'];

            let assignedCompanyIds = assignedGroups.filter(group => {
                return group.includes('CompanyId') === true;
            });

            if(assignedCompanyIds.length > 1 || assignedCompanyIds.length === 0) {
                return false;
            } else {
                return true;
            }
        }
        catch(exception)
        { 

            if (!environment.production) {
                console.log(exception);
            }
            return false;
        }
    }

    //---------------------------------------------------------------------------------------------
    // Get any value from the UserGroup by passing in the key [Denver Naidoo 23 Sep 2020]
    //---------------------------------------------------------------------------------------------
    getValueFromUserGroup(key: string) {
        var userGroups = sessionStorage.getItem('Gollum');
        var userGroupsList = userGroups.split(',');
        var returnValue: string = ''
        var keyLenght: number = key.length;
        var seekValue: string
        var valueFromUserGroup: string

        userGroupsList.forEach(userGroup => {
                
            seekValue = userGroup.substr(0, keyLenght);

            if (seekValue === key) {
                valueFromUserGroup = userGroup.replace(key, '') 
                returnValue = valueFromUserGroup.trim();
            }
        });

        return returnValue;

    }

    loginHasPermissions(userPermissions: string, skipGodMode:boolean=false): boolean {

        if (!skipGodMode) {
            //Turn GodMode into a Mortal User by not assigning God Mode to anything that happens here. It's good to test what the Mortal Users would see.
            userPermissions = 'GodMode,' + userPermissions;
        }
    
        var userGroups = sessionStorage.getItem('Gollum');
        var userGroupsList = userGroups.split(',');
        var userPermissionsList = userPermissions.split(',');
        var returnValue: boolean = false;
    
        userPermissionsList.forEach(userPermission => {
            userGroupsList.forEach(userGroup => {
                
                if (userPermission === userGroup) {
                    returnValue = true;
                }
            });
            
        });
    
        return returnValue;
    }

    //Error Handling Mehtod
    private handleError(err: HttpErrorResponse) {

        let errorMessage = '';

        if (err.error instanceof ErrorEvent) {
            errorMessage = `Errors have Occured: ${err.error.message} `;
        } else {
            errorMessage = `Server returned code: ${err.status}. Error Status is ${err.message}`;
        }

        

        return throwError(errorMessage);

    };


/**********************************************************************************************
 Return HTML Highligted Text Differences
 *********************************************************************************************/
htmlTextDiff(sourceString, changedString): string {
    

    var textDiff = new TextDiff();
    var textDifferencesPretty: string = '';
    var textDifference: string = '';
    
    if (sourceString == null) {
        sourceString = '';
    }

    if (changedString == null) {
        changedString = '';
    }

    if (sourceString == changedString ) {
        textDifferencesPretty = '';
    }
    else 
    {
        textDifference = textDiff.main(sourceString, changedString);
        textDifferencesPretty = textDiff.prettyHtml(textDifference);
    }
    return textDifferencesPretty;
}

/**********************************************************************************************
 BEGIN - Function Extract Token. The Tokens have Rules:
    1. Must start and end with <{{>. 
        Example: {{START_WITH_HASH_END_WITH_HASH}}
    2. Must be all capital letters with underscores seperating words. 
        Example: {{CAPITAL_LETTERS_WITH_UNDERSCORES}}
    3. Must NOT contain any spaces.
        Example: {{TOKEN_MUST_HAVE_NO_SPACES}}

Example of a Token in the BKash Context:
    {{ACCOUNT_BALANCE}}
  **********************************************************************************************/
  getTokenList(stringWithTokens: string): string[] {
    let tokenExpression: RegExp = /{{[A-Z_}}]+[}}]+/g
    let tokenList = stringWithTokens.match(tokenExpression)
    return tokenList
  }

  removeTokens(stringWithTokens: string): string {
   let tokenList: string[] = this.getTokenList(stringWithTokens);
   let tokenExpression: RegExp = /{{[A-Z_}}]+[}}]+/g;
   let stringWithoutTokens = stringWithTokens.replace(tokenExpression, '');
   
   return stringWithoutTokens
  }

  getCurrencyMask(actualResponse: string): string[] {
    let tokenExpression: RegExp = /Tk[0-9]+[.]+[0-9]{2}/g
    let tokenValue = actualResponse.match(tokenExpression)
    
    return tokenValue
  }

  replaceToken(stringWithToken, token, replaceWith): string {
    let stringReplaced = stringWithToken.split(token).join(replaceWith)
    return stringReplaced
  }

  getTokenValue(exptectedResponse: string, actualResponse: string, token: string): string[] {
    //split the string based on the token
    let expectedArray: string[] = exptectedResponse.split(token);
    let tempTokenString: string = '';
    let delimiter = '{{}}'

    tempTokenString = actualResponse;
    expectedArray.forEach(element => {
      tempTokenString = tempTokenString.replace(element, delimiter);
    });

    let tokenValueList = tempTokenString.split(delimiter);
    tokenValueList = tokenValueList.filter(item => item);
    
    return tokenValueList;
  }

  /**********************************************************************************************
  END - Functin Extract Token
  **********************************************************************************************/

  
 

}
