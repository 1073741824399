import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { environment } from '../../environments/environment';
import { MobileDataService } from './mobile-data.service';
import { PageTitleService } from '../core/page-title/page-title.service';
import { Observable, Subject } from 'rxjs';
import { ISessionLive, IEventHandler } from '../interface/i-responses';
import { IDynamoKey, IRemote, DeviceReadyState } from '../interface/i-dynamo';

enum DeviceStatus {
    Available = "Available",
    "In Use" = "In Use",
    "Device in Use" = "Device in Use",
    Reserved = "Reserved",
    Unavailable = "Unavailable",
    Disabled = "Disabled",
    Unbound = "Unbound",
}

export interface IVerifyDevicesAvailability
{
    Ready?:IRemote[];
    Unready?:IRemote[];
    Reject?:IRemote[];
}


@Injectable(
    {
        providedIn: "root",
    }
)
export class DevicesService{

    FullDeviceList: IRemote[] = [];
    AvailableDeviceList: IRemote[] = [];
    SelectedDeviceList: IRemote[] = [];
    ReadyStates: any[] = [DeviceStatus.Available];
    UnreadyStates: any[] = [DeviceStatus['In Use'], DeviceStatus.Reserved, DeviceStatus['Device in Use']];
    RejectStates: any[] = [DeviceStatus.Disabled, DeviceStatus.Unavailable, DeviceStatus.Unbound];


    DevicePk = '#Device';
    iSessionLive: ISessionLive;

    FullDeviceListUpdates:BehaviorSubject<IRemote[]> = new BehaviorSubject<IRemote[]>(this.FullDeviceList);
    AvailableDeviceListUpdates:BehaviorSubject<IRemote[]> = new BehaviorSubject<IRemote[]>(this.AvailableDeviceList);
    SelectedDeviceListUpdates:BehaviorSubject<IRemote[]> = new BehaviorSubject<IRemote[]>(this.SelectedDeviceList);
    VerifySelectedDeviceListUpdates:BehaviorSubject<any> = new BehaviorSubject<any>({Ready:[],Unready:[],Reject:[],});
    
    constructor
    (
        private pageTitleService: PageTitleService,
        private mobileDataService: MobileDataService,
    )
    {

    }

    initialize()
    {
        try 
        {
            this.pageTitleService.iEventHandler.subscribe((iEventHandler: IEventHandler) => {
                this.handleAsyncUpdates(iEventHandler);
            })
            this.FullDeviceList = [];
            this.AvailableDeviceList = [];
            this.SelectedDeviceList = [];

            this.getSessionLive();
            this.getAllDevices();
        }
        catch (exception) {
          if (!environment.production) {
            console.log('#EXCEPTION: DevicesService: initialize()', exception);
          }
        }
    }

    private handleAsyncUpdates(iEventHandler:IEventHandler)
    {
        try 
        {
            if (iEventHandler) 
            {
                if (iEventHandler.Event) 
                {
                    let events = iEventHandler.Event.split(',')
                    for (let action in events)  
                    {   
                        //---------------------------------------------------------------------------------------------------------
                        //Marwyk - handle async update of devices and device status
                        //---------------------------------------------------------------------------------------------------------
                        if (events[action].split('#')[0] === 'DEVICE_STATUS') 
                        {
                            this.getAllDevices();
                        }
                    }  
                }
                }
        }
        catch (exception) {
            if(!environment.production){
            console.log('#EXCEPTION::DevicesService::handleAsyncUpdates() ', exception);
            }
        }
    }

    private getSessionLive() {
        try {
            this.pageTitleService.sessionLive.subscribe((iSessionLive: ISessionLive) => {
                this.iSessionLive = iSessionLive;
                this.DevicePk = `${this.iSessionLive.ClientId}#Device`;
            });
        }
        catch (exception) {
            if(!environment.production){
            console.log('#EXCEPTION::DevicesService::getSessionLive() ', exception);
            }
        }
    }


    getAllDevices()
    {
        try 
        {
            let iRemoteRequest: IDynamoKey = {pk: this.DevicePk};
            this.mobileDataService.fargo_dynamo('get_dynamo_by_pk', iRemoteRequest).subscribe((response) => {
                if (response) 
                {
                    this.FullDeviceList = response;
                    this.sanitizeDeviceLists();
                }
            });
        }
        catch (exception) {
          if (!environment.production) {
            console.log('#EXCEPTION: DevicesService: getAllDevices()', exception);
          }
        }
    }

    fakeWebsocketPing(device:IRemote, op:String)
    {
        this.updateSelectedDevicesFromDynamo();
    }

    
    async updateSelectedDevicesFromDynamo(): Promise<any> 
    {
        try 
        {
            //replace all selected devices with new ones from dynamo
            for(let device of this.SelectedDeviceList)
            {
                let deviceIndex = this.SelectedDeviceList.findIndex(dev => dev.sk == device.sk);
                let iRemoteRequest: IDynamoKey = {pk: device.pk, sk:device.sk};
                await this.mobileDataService.fargo_dynamo('get_dynamo_by_pk_sk', iRemoteRequest).toPromise().then(async response => {
                    if(response)
                    {
                        response.DeviceReadyState = this.checkDeviceAvailability(response);
                        this.SelectedDeviceList[deviceIndex] = response;
                    }
                });
            };
            this.SelectedDeviceListUpdates.next(this.SelectedDeviceList);
            this.verifySelectedDeviceAvailability();
            return this.SelectedDeviceList;
        }
        catch (error) 
        {
            if(!environment.production){
                console.log('#EXCEPTION::DevicesService::updateAllDevicesFromDynamo() ', error);
            }
        }
    }

    getDeviceUpdateFromDynamo(device:IRemote): Observable<any>
    {
        try 
        {
            let iRemoteRequest: IDynamoKey = {pk: device.pk, sk:device.sk};
            return this.mobileDataService.fargo_dynamo('get_dynamo_by_pk_sk', iRemoteRequest);
        }
        catch (exception) {
          if (!environment.production) {
            console.log('#EXCEPTION: DevicesService: getDeviceUpdateFromDynamo()', exception);
          }
        }
    }

    getDeviceUpdate(device:IRemote):IRemote
    {
        try 
        {
            return this.FullDeviceList.filter(dev => dev.sk == device.sk)[0];
        }
        catch (exception) {
          if (!environment.production) {
            console.log('#EXCEPTION: DevicesService: getDeviceUpdate()', exception);
          }
        }
    }

    async addSelectedDevice(device:IRemote)
    {
        try 
        {
            let addingDevice:IRemote = this.FullDeviceList.filter(dev => dev.pk == device.pk && dev.sk == device.sk)[0];
            if(addingDevice)
            {
                this.SelectedDeviceList.push(device)
                this.SelectedDeviceListUpdates.next(this.SelectedDeviceList);
                return true;
            }
            else
            {
                return false
            }
        }
        catch (error) 
        {
            if(!environment.production){
                console.log('#EXCEPTION::DevicesService::addSelectedDevice() ', error);
            }
            return false;
        }
    }

    async addSelectedDeviceByPKSK(pk, sk)
    {
        try 
        {
            let addingDevice:IRemote = this.FullDeviceList.filter(dev => dev.pk == pk && dev.sk == sk)[0];
            if(addingDevice)
            {
                this.SelectedDeviceList.push(addingDevice)
                this.SelectedDeviceListUpdates.next(this.SelectedDeviceList);
                return true;
            }
            else
            {
                return false;
            }
            
        }
        catch (error) 
        {
            if(!environment.production){
                console.log('#EXCEPTION::DevicesService::addSelectedDevice() ', error);
            }
            return false;
        }
    }

    async removeFromSelectedDevice(device:IRemote)
    {
        try 
        {
            let removingIndex;
            this.SelectedDeviceList.forEach((selDevice, selIndex) => {
                if(selDevice.sk == device.sk)
                {
                    removingIndex = selIndex;
                }
            })
            if(removingIndex)
            {
                this.SelectedDeviceList.splice(removingIndex, 1);
                return true;
            }
            this.SelectedDeviceListUpdates.next(this.SelectedDeviceList);
            return this.SelectedDeviceList;            
        }
        catch (error) 
        {
            if(!environment.production){
                console.log('#EXCEPTION::DevicesService::addSelectedDevice() ', error);
            }
        }
    }

    async replaceSelectedDevice(ReplacingDevice:IRemote, NewDevice:IRemote)
    {
        try 
        {
            let replacingIndex = this.SelectedDeviceList.findIndex(dev => dev.sk == ReplacingDevice.sk);
            if(replacingIndex)
            {
                this.SelectedDeviceList[replacingIndex] = NewDevice;
            }
            this.SelectedDeviceListUpdates.next(this.SelectedDeviceList);
            return this.SelectedDeviceList;            
        }
        catch (error) 
        {
            if(!environment.production){
                console.log('#EXCEPTION::DevicesService::addSelectedDevice() ', error);
            }
        }
    }

    async resetSelectedDevices()
    {
        try 
        {
            this.SelectedDeviceList = [];
            this.SelectedDeviceListUpdates.next(this.SelectedDeviceList);
        }
        catch (error) 
        {
            if(!environment.production){
                console.log('#EXCEPTION::DevicesService::resetSelectedDeviced() ', error);
            }
        }
    }

    checkDeviceAvailability(device:IRemote):DeviceReadyState
    {
        try 
        {
            if(this.ReadyStates.includes(device.DeviceStatus))
            {
                return DeviceReadyState.Ready
            }
            
            if(this.UnreadyStates.includes(device.DeviceStatus))
            {
                return DeviceReadyState.Unready
            }

            if(this.RejectStates.includes(device.DeviceStatus))
            {
                return DeviceReadyState.Reject
            }
        }
        catch (error) 
        {
            if(!environment.production){
                console.log('#EXCEPTION::DevicesService::checkDeviceAvailability() ', error);
            }
        }
    }

    verifySelectedDeviceAvailability():IVerifyDevicesAvailability
    {
        try 
        {
            let deviceCheckObject:IVerifyDevicesAvailability = {
                Ready:[],
                Unready:[],
                Reject:[],
            };
            for(let device of this.SelectedDeviceList)
            {
                device.DeviceReadyState = this.checkDeviceAvailability(device);
                if(device.DeviceReadyState == DeviceReadyState.Ready)
                {
                    //the device is seen as ready
                    deviceCheckObject.Ready.push(device);
                }
                if(device.DeviceReadyState == DeviceReadyState.Unready)
                {
                    //is seen as unready
                    deviceCheckObject.Unready.push(device);
                }
                if(device.DeviceReadyState == DeviceReadyState.Reject)
                {
                    //is seen as unready
                    deviceCheckObject.Reject.push(device);
                }
            }
            this.VerifySelectedDeviceListUpdates.next(deviceCheckObject);
            return deviceCheckObject;
        }
        catch (error) 
        {
            if(!environment.production){
                console.log('#EXCEPTION::DevicesService::verifySelectedDeviceAvailability() ', error);
            }
        }
    }

    async sanitizeDeviceLists()
    {
        try 
        {
            this.AvailableDeviceList = [];
            for(let device of this.FullDeviceList)
            {
                if(device.SimPool)
                {
                    //device is part of simpool and unbound
                    if(device.PORT == '')
                    {
                        device.DeviceStatus = DeviceStatus.Unbound;
                    }
                }
                device.DeviceReadyState = this.checkDeviceAvailability(device);
                if(device.DeviceReadyState != DeviceReadyState.Reject)
                {
                    //device is available even though it may be unready, reserved or in use at the moment
                    this.AvailableDeviceList.push(device);
                }
            }
            this.FullDeviceListUpdates.next(this.FullDeviceList);
            this.AvailableDeviceListUpdates.next(this.AvailableDeviceList);
            this.updateSelectedDevicesFromDynamo();
        } 
        catch (error) 
        {
            if(!environment.production){
                console.log('#EXCEPTION::DevicesService::sanitizeDeviceLists() ', error);
            }
        }
    }


}