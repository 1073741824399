import * as internal from "stream";
import { IDynamoKey, IRemote } from "./i-dynamo";

export interface DiffContent {
    leftContent: string;
    rightContent: string;
  }

export interface IDevicePayload {
    SessionId?: number;
    DeviceId?: number;
    TestTemplateId?: number;
    TestRunId?: number; 
    DeviceRequest?: string;
    DeviceResponse?: string;
    OriginalDeviceResponse?: string;
}

//Search functionality within an interface
export interface IWidget {
    Name?: string;
    IsVisible?: boolean;
}

export interface IDataDrawDown {
    DrawDownSize: number;
    ATCommandId: number;
    DrawDownUnit?:string;
    Synthetic?:boolean;
}

export interface IVoiceDrawDown {
    DrawDownSize: number;
    DrawDownType: string;
    ATCommandId: number;
    MsisdnList: string[]
    TargetMSISDN:string;
    Synthetic?:boolean;
}

export interface ISmsSend {
    CopiesPerMSISDN?:number;
    MsisdnList:string[];
    Synthetic?:boolean;
}

export interface IFormula {
    FormulaIndex: number;
    Type: string;
    FormulaValue: string; 
}

export interface IFormulaList {
    Index?: number;
    iFormula?: IFormula[];
}

export interface IUITrackerbySK extends IDynamoKey {
    RunButtonDisabled?: boolean;
    iRemote?: IRemote[];
    BeenHere?: boolean;
}

export interface ITestCaseByDevices extends IDynamoKey {  
    iRemote?: IRemote[];
    GUID?: string;
}

export interface IRequestBody {  
    MethodName?: string;
    MethodParams?: {}
}

export interface IGenericDialog {  
    Title?: string;
    Question?: string;
    Message?: string;
    ConfirmButtonText?: string;
    CancelButtonText?: string;
}

export interface IActiveTestRun {  
    IsActiveTestRun?: boolean;
    ShowMasterTab?: boolean;
    ShowHistoricResults?: boolean;
}

export interface ICreateCalculationComponent {
    Id?: string;
    SingleTokenisedResponse?: string;
    Index?: string;
}

export interface ITestCaseFormula {
    Index?: string;
    Formula?: string;    
}

export interface IInputTestChain {
    pk?: string;
    sk?: string;
}

export interface IInputTestSuite {
    pk?: string;
    sk?: string;
}

export interface IInputTestSchedule{
    pk?: string;
    sk?: string;
}

export interface IGrafanaConfig {
    url?: string;
    width?: number;
    height?: number;
}



export interface IGroupPolicy {
    Group?:string;
    Menus?:string[];
    Lookups?:string[];
    Ribbons?:string[];
}

export interface IPrimaryGroupPolicy{
    Group?:string[];
    Menus?:{};
    Lookups?:{};
    Ribbons?:{};
}

//------------------------------------------------------------------------------------------------------------------
// Marwyk - temporary central location for GPO Menu map. Will be removed once this info is passed down with GPO info
//------------------------------------------------------------------------------------------------------------------
export const AcceptableGroupPolicies = [
    'inquest_testautomation_user', // 'Old BkashUser',
    'inquest_testautomation_poweruser', // 'Old PowerUser',
    'inquest_testautomation_administrator', // 'Old Administrator',
    'inquest_developer'
]

const groupPolicyMenuMap = {
    inquest_testautomation_administrator:{
        SystemConfiguration:['SimPoolConfig','DeviceBank', 'TokenConfig'],
        GlobalConfiguration:['ClientConfig'],
        InquestDemoVideos:['USSDOverview', 'ReportsOverview', 'ReadingSMS', 'ResultSummary'],
        CustomerAdministration:['SimPools', 'UssdRobots', "CustomerConfig", "MobileNetworkOperators", "VoiceMsisdnTargets", "DataDrawDownTargets", "RatingGroups"],
        TestCollection:['FunctionalTesting', 'PositiveTestCase', 'NegativeTestCase', 'TestChains', 'TestSuites', 'TestSchedules'],
        // TestSchedule:['CreateTestSchedule'],
        // Reports:['Past24Hours', 'UserInvokceUSSD', 'TestCases', 'NegativeTestCases', 'TestChains', 'TestSuites', 'CurrentAndPendingTests'],
        Dashboards:['PositiveCaseSuccessRates', 'PositiveCaseExecutionTimes'],
    },
    inquest_testautomation_poweruser:{
            SystemConfiguration:['DeviceBank','SimPoolConfig','TokenConfig'],
            TestCollection:['FunctionalTesting', 'PositiveTestCase', 'NegativeTestCase', 'TestChains', 'TestSuites', 'TestSchedules'],
            Dashboards:['PositiveCaseSuccessRates', 'PositiveCaseExecutionTimes'],
    },
    inquest_testautomation_user:{
            TestCollection:['FunctionalTesting', 'PositiveTestCase', 'NegativeTestCase', 'TestChains', 'TestSuites', 'TestSchedules'],
            Dashboards:['PositiveCaseSuccessRates', 'PositiveCaseExecutionTimes'],
    },
}

export const GroupPolicyMenuMap = {
    ...groupPolicyMenuMap,
    inquest_developer:{ 
                        ...groupPolicyMenuMap.inquest_testautomation_administrator, 
                        ...groupPolicyMenuMap.inquest_testautomation_poweruser, 
                        ...groupPolicyMenuMap.inquest_testautomation_user
                    },
}

//------------------------------------------------------------------------------------------------------------------
// Marwyk - temporary central location for GPO Lookup map. Will be removed once this info is passed down with GPO info
//------------------------------------------------------------------------------------------------------------------
const groupPolicyLookupMap = {
    inquest_testautomation_administrator:{
        DeviceList:{
            Crud:['New', 'Edit', 'Delete'],
            Inputs:{
                DeviceDetails:['DeviceId', 'DeviceFriendlyName', 'DeviceComments', 'DeviceStatus'],
                MsisdnDetails:['MSISDN', 'MNO', 'CurrentPIN', 'PreviousPIN', 'ProdShortCode', 'UATShortCode', 'CurrentUATPIN', 'PreviousUATPIN', 'ModemNo', 'ExecutionType'],
                DeviceConfiguration:['DeviceStatus'],
                RemoteDetails:['RemoteURL', 'RemoteLocation', 'ModemCount', 'RemoteNote'],
                DeviceCapabilities:['DataDrawdown', 'VoiceDrawdown', 'ApiExecution', 'UatSms', 'DataDrawdownSynthetic', 'VoiceDrawdownSynthetic', 'SyntheticSms']
            }
        },
        SimPools:{
            Crud:['New', 'Edit', 'Delete'],
            Inputs:{
                SimPoolDetails:['SimPoolName', 'BaseUrl', 'Notes'],
            }
        },
        UssdRobots:{
            Crud:['New', 'Edit', 'Delete'],
            Inputs:{
                RobotDetails:['RobotName', 'BaseUrl', 'Notes'],
            }
        }
    },
    inquest_testautomation_poweruser:{
        DeviceList:{
            Crud:['Edit'],
            Inputs:{
                MsisdnDetails:['CurrentPIN', 'PreviousPIN', 'CurrentUATPIN', 'PreviousUATPIN'],
                DeviceConfiguration:['DeviceStatus'],
            }
        }
    },
    inquest_testautomation_user:{
        
    },
}
export const GroupPolicyLookupMap = {
    ...groupPolicyLookupMap,
    inquest_developer:{
                            ...groupPolicyLookupMap.inquest_testautomation_administrator,
                            ...groupPolicyLookupMap.inquest_testautomation_poweruser,
                            ...groupPolicyLookupMap.inquest_testautomation_user,
                    }
}


//------------------------------------------------------------------------------------------------------------------
// Marwyk - temporary central location for GPO Lookup map. Will be removed once this info is passed down with GPO info
//------------------------------------------------------------------------------------------------------------------
const groupPolicyRibbonMap = {
    inquest_testautomation_administrator:{
        Lookups:['TokenList', 'CustomerConfig', 'MobileNetworkOperators', 'VoiceMsisdnTargets', 'DataDrawDownTargets', "RatingGroups"],
        Devices:['DeviceBank','SimPoolConfig'],
    },
    inquest_testautomation_poweruser:{
        Lookups:['TokenList'],
        Devices:['DeviceBank','SimPoolConfig'],
    },
    inquest_testautomation_user:{

    },
}
export const GroupPolicyRibbonMap = {
    ...groupPolicyRibbonMap,
    inquest_developer:{
                            ...groupPolicyRibbonMap.inquest_testautomation_administrator,
                            ...groupPolicyRibbonMap.inquest_testautomation_poweruser,
                            ...groupPolicyRibbonMap.inquest_testautomation_user,
                        }
}