import { MasterService } from  './master-service.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IFargoRequest } from '../interface/i-requests';

import { environment } from '../../environments/environment';

@Injectable()
export class MobileDeviceService {

    constructor(private masterService: MasterService) {}
    errorMessage: string;

    //Get SMSs by DeviceId
    getSMSByDeviceId(deviceId): Observable<any> {
      try {
        const urlPostFix: string = 'fargo'
        let methodName: string = "i_sms.save_all_sms_by_device_id"
        let methodParams: string = `device_id=${deviceId}`
        let iFargoRequest: IFargoRequest = {
          MethodName: methodName,
          MethodParams: methodParams
        }
        return(this.masterService.postInterface(iFargoRequest, urlPostFix));
      }
      catch (exception) {
        if (!environment.production) {
          console.log('#EXCEPTION::MobileDeviceService::sendRequestToDevice() ', exception);
        }
      }
    }

    //Send request to device and get a response
    sendRequestToDevice(deviceId, testBatchId, userResponse, sessionId=0, atCommandId=1, drawDownSize): Observable<any> {
      try {
        const urlPostFix: string = 'fargo'
        let methodName: string = "execute_device_response"
        let methodParams: string = `device_id=${deviceId}, test_batch_id=${testBatchId} ,user_response='${userResponse}',session_id=${sessionId}, at_command_id=${atCommandId}, draw_down_size=${drawDownSize}`
        let iFargoRequest: IFargoRequest = {
          MethodName: methodName,
          MethodParams: methodParams
        }
        return(this.masterService.postInterface(iFargoRequest, urlPostFix));
      }
      catch (exception) {
        if (!environment.production) {
          console.log('#EXCEPTION::MobileDeviceService::sendRequestToDevice() ', exception);
        }
      }
    }

    //Send request to device and get a response
    sendDeviceRequest(payload): Observable<any> {
      try {
        const urlPostFix: string = 'fargo'
        let methodName: string = "execute_selected_device"
        let methodParams: string = payload
        let iFargoRequest: IFargoRequest = {
          MethodName: methodName,
          MethodParams: methodParams
        }
        return(this.masterService.postInterface(iFargoRequest, urlPostFix));
      }
      catch (exception) {
        if (!environment.production) {
          console.log('#EXCEPTION::MobileDeviceService::sendRequestToDevice() ', exception);
        }
      }
    }

}