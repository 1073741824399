import { Injectable } from '@angular/core';


export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  children?: ChildrenItems[];
}

const MENUITEMS : Menu[] = [
  {
    state: 'dashboard',
    name: 'HOME',
    type: 'link',
    icon: 'home'
  },
  /*
  Begin: Demo Menu. PLEASE REMOVE
  */
  // {
  //   state: 'dashboard',
  //   name: 'Mobile Test',
  //   type: 'link',
  //   icon: 'speaker_phone'
  // },
  // {
  //   state: 'dashboard',
  //   name: 'View Mobile Test',
  //   type: 'link',
  //   icon: 'speaker_phone'
  // },
  // {
  //   state: 'dashboard',
  //   name: 'View Remotes',
  //   type: 'link',
  //   icon: 'speaker_phone'
  // },
  // {
  //   state: 'dashboard',
  //   name: 'Edit Service',
  //   type: 'link',
  //   icon: 'speaker_phone'
  // },
  // {
  //   state: 'dashboard',
  //   name: 'Edit Sequence',
  //   type: 'link',
  //   icon: 'speaker_phone'
  // },
  // {
  //   state: 'dashboard',
  //   name: 'Mobile Service',
  //   type: 'link',
  //   icon: 'speaker_phone'
  // },
  // {
  //   state: 'dashboard',
  //   name: 'Transaction Sequence',
  //   type: 'link',
  //   icon: 'speaker_phone'
  // },
  // {
  //   state: 'dashboard',
  //   name: 'Mobile Service Steps',
  //   type: 'link',
  //   icon: 'speaker_phone'
  // },
  // {
  //   state: 'dashboard',
  //   name: 'Execute Tests',
  //   type: 'link',
  //   icon: 'speaker_phone'
  // },
  // {
  //   state: 'dashboard',
  //   name: 'Detailed Plan',
  //   type: 'link',
  //   icon: 'speaker_phone'
  // },
  // {
  //   state: 'dashboard',
  //   name: 'Summary Report',
  //   type: 'link',
  //   icon: 'speaker_phone'
  // },
  // {
  //   state: 'dashboard',
  //   name: 'Detailed Report',
  //   type: 'link',
  //   icon: 'speaker_phone'
  // },
  // {
  //   state: 'dashboard',
  //   name: 'Exception Report',
  //   type: 'link',
  //   icon: 'speaker_phone'
  // },
  // {
  //   state: 'dashboard',
  //   name: 'Schedule',
  //   type: 'link',
  //   icon: 'speaker_phone'
  // },
  // {
  //   state: 'dashboard',
  //   name: 'Negative Testing',
  //   type: 'link',
  //   icon: 'speaker_phone'
  // },
  // {
  //   state: 'dashboard',
  //   name: 'Live Dashboard',
  //   type: 'link',
  //   icon: 'speaker_phone'
  // },
  // {
  //   state: 'dashboard',
  //   name: 'Run Tests',
  //   type: 'link',
  //   icon: 'speaker_phone'
  // },
  /*
  End: Demo Menu. PLEASE REMOVE
  */
  // {
  //   state: 'dev-utils',
  //   name: 'Code Engine',
  //   type: 'sub',
  //   icon: 'security',
  //   children: [
  //     {state: 'dev-utils-create', name: 'Create Magic'},
  //     {state: 'dev-utils-get', name: 'Get Magic'},
  //     {state: 'dev-utils-sandbox', name: 'Sandbox'}
  //   ]
  // },
  // {
  //   state: 'emails',
  //   name: 'Emails',
  //   type: 'link',
  //   icon: 'mail'
  // },
  // {
  //   state: 'chat',
  //   name: 'CHAT',
  //   type: 'link',
  //   icon: 'chat_bubble_outline'
  // },
  // {
  //   state: 'mobile-device',
  //   name: 'Configure Devices',
  //   type: 'sub',
  //   icon: 'format_shapes',
  //   children: [
  //     {state: 'mobile-device-config', name: 'Configure Devices'},

  //     //{state: 'view-service', name: 'View Service'},
  //   ]
  // },
  // {
  //   state: 'mobile-service',
  //   name: 'Configure Services',
  //   type: 'sub',
  //   icon: 'format_shapes',
  //   children: [
  //     {state: 'mobile-service-create', name: 'New Service'},
  //     {state: 'mobile-service-edit', name: 'Edit Service'},
  //     {state: 'mobile-service-step-create', name: 'New Service Step'},
  //     {state: 'mobile-service-step-edit', name: 'Edit Service Step'}
  //     //{state: 'view-service', name: 'View Service'},
  //   ]
  // },
  // {
  //   state: 'mobile-test',
  //   name: 'Tests',
  //   type: 'sub',
  //   icon: 'local_activity',
  //   children: [
  //     {state: 'mobile-test-invoke-command',name: 'Invoke Commands'}
  //     ,{state: 'mobile-test-run',           name: 'Run Service Tests'}
  //     ,{state: 'mobile-test-edit',          name: 'Edit Service Tests'}
  //     ,{state: 'mobile-test-create',        name: 'Create Service Tests'}
  //     ,{state: 'mobile-test-tran-run',      name: 'Run Transactions'}
  //     ,{state: 'mobile-test-tran-edit',     name: 'Edit Transactions'}
  //     ,{state: 'mobile-test-tran-create',   name: 'Create Transactions'}
  //     // ,{state: 'mobile-test-edit',        name: 'Edit Service Test'}
  //     // ,{state: 'mobile-test-tran-edit',   name: 'Edit Transaction Sequence'}
  //   ]
  // },
  // {
  //   state: 'authentication',
  //   name: 'Schedule',
  //   type: 'sub',
  //   icon: 'security',
  //   children: [
  //     {state: 'create-service', name: 'New Schedule'},
  //     {state: 'edit-service', name: 'Edit Schedule'},
  //     {state: 'view-service', name: 'View Schedule'},
  //   ]
  // },
  // {
  //   state: 'mobile-test-report',
  //   name: 'Reports',
  //   type: 'sub',
  //   icon: 'perm_media',
  //   children: [
  //     {state: 'report-summary', name: 'Summary Report'},
  //     {state: 'report-24hr',  name: 'Past 24 Hours'},
  //     {state: 'test-batch', name: 'Batch Report'},
  //     {state: 'view-service', name: 'Failed Tests'},
  //     // {state: 'view-service', name: 'Past 24 Hours'},
  //   ]
  // },
  // {
  //   state: 'authentication',
  //   name: 'Dashboards',
  //   type: 'sub',
  //   icon: 'security',
  //   children: [
  //     {state: 'create-service', name: 'Live Test Summary'},
  //     {state: 'edit-service', name: 'Live Details'},
  //     {state: 'view-service', name: 'Live Schedules'},
  //     {state: 'view-service', name: 'This Month'},
  //     {state: 'view-service', name: 'This Week'},
  //     {state: 'view-service', name: 'Today'},
  //   ]
  // },
  // {
  //   state: 'authentication',
  //   name: 'Settings',
  //   type: 'sub',
  //   icon: 'security',
  //   children: [
  //     {state: 'create-service', name: 'View Settings'},
  //   ]
  // },
  // {
  //   state: 'hld',
  //   name: 'High Level',
  //   type: 'sub',
  //   icon: 'library_books',
  //   children: [
  //     {state: 'high-level-design', name: 'High Level Design'},
  //   ]
  // },

  //--- START COMMENTED MENU ITMES ---
  // {
  //   state: 'features',
  //   name: 'FEATURES',
  //   type: 'sub',
  //   icon: 'library_books',
  //   children: [
  //     {state: 'colorpicker', name: 'COLOR PICKER'},
  //     {state: 'cropper', name: 'CROPPER'},
  //     {state: 'dragula', name: 'DRAGULA'},
  //     {state: 'sortable', name: 'SORTABLE'}
  //   ]
  // },
  // {
  //   state: 'components',
  //   name: 'COMPONENTS',
  //   type: 'sub',
  //   icon: 'local_activity',
  //   children: [
  //     {state: 'accordion', name: 'ACCORDION'},
  //     {state: 'alerts', name: 'ALERTS'},
  //     {state: 'buttons', name: 'BUTTONS'},
  //     {state: 'carousel', name: 'CAROUSEL'},
  //     {state: 'collapse', name: 'COLLAPSE'},
  //     {state: 'datepicker', name: 'DATEPICKER'},
  //     {state: 'dropdown', name: 'DROPDOWN'},
  //     {state: 'modal', name: 'MODAL'},
  //     {state: 'pagination', name: 'PAGINATION'},
  //     {state: 'popover', name: 'POPOVER'},
  //     {state: 'progressbar', name: 'PROGRESS BAR'},
  //     {state: 'rating', name: 'RATING'},
  //     {state: 'tabs', name: 'TABS'},
  //     {state: 'timepicker', name: 'TIMEPICKER'},
  //   ]
  // },
  // {
  //   state: 'icons',
  //   name: 'ICONS',
  //   type: 'sub',
  //   icon: 'center_focus_weak',
  //   children: [
  //     {state: 'linea', name: 'LINEA'},
  //     {state: 'font-awesome', name: 'FONT AWESOME'},
  //     {state: 'simple-line-icons', name: 'SIMPLE LINE ICONS'},
  //     {state: 'material-icons', name: 'MATERIAL ICONS'},
  //   ]
  // },
  // {
  //   state: 'cards',
  //   name: 'CARDS',
  //   type: 'sub',
  //   icon: 'grid_on',
  //   children: [
  //     {state: 'basic-cards', name: 'BASIC CARDS'},
  //     {state: 'advance-cards', name: 'ADVANCE CARDS'}
  //   ]
  // },
  // {
  //   state: 'forms',
  //   name: 'FORMS',
  //   type: 'sub',
  //   icon: 'format_align_justify',
  //   children: [
  //     {state: 'form-wizard', name: 'FORM CONTROLS'},
  //     {state: 'form-validation', name: 'FORM VALIDATION'},
  //     {state: 'form-upload', name: 'UPLOAD'},
  //     {state: 'form-tree', name: 'TREE'}
  //   ]
  // },
  // {
  //   state: 'tables',
  //   name: 'TABLES',
  //   type: 'sub',
  //   icon: 'grid_on',
  //   children: [
  //     {state: 'basic-tables', name: 'BASIC TABLES'},
  //     {state: 'responsive-tables', name: 'RESPONSIVE TABLES'}
  //   ]
  // },
  // {
  //   state: 'data-tables',
  //   name: 'DATA TABLES',
  //   type: 'sub',
  //   icon: 'format_line_spacing',
  //   children: [
  //     {state: 'selection', name: 'SELECTION'},
  //     {state: 'pinning', name: 'PINNING'},
  //     {state: 'sorting', name: 'SORTING'},
  //     {state: 'paging', name: 'PAGING'},
  //     {state: 'editing', name: 'EDITING'},
  //     {state: 'filter', name: 'FILTER'}
  //   ]
  // },
  // {
  //   state: 'chart',
  //   name: 'CHARTS',
  //   type: 'sub',
  //   icon: 'insert_chart',
  //   children: [
  //     {state: 'bar-charts', name: 'BAR'},
  //     {state: 'pie-charts', name: 'PIE'},
  //     {state: 'line-charts', name: 'LINE'},
  //     {state: 'mixed-charts', name: 'MIXED'},
  //     {state: 'ng2-charts', name: 'NG2 CHARTS'},
  //   ]
  // },
  //   {
  //   state: 'maps',
  //   name: 'MAPS',
  //   type: 'sub',
  //   icon: 'map',
  //   children: [
  //     {state: 'googlemap', name: 'GOOGLE MAP'},
  //     {state: 'leafletmap', name: 'LEAFLET MAP'}
  //   ]
  // },
  //   {
  //   state: 'pages',
  //   name: 'PAGES',
  //   type: 'sub',
  //   icon: 'web',
  //   children: [
  //     {state: 'about', name: 'ABOUT'},
  //     {state: 'contact', name: 'CONTACT'},
  //     {state: 'timeline', name: 'TIMELINE'},
  //     {state: 'pricing', name: 'PRICING'},
  //     {state: 'blank', name: 'BLANK'},
  //   ]
  // },
  // {
  //   state: 'user-pages',
  //   name: 'USERS',
  //   type: 'sub',
  //   icon: 'person',
  //   children: [
  //     {state: 'userlist', name: 'USER LIST'},
  //     {state: 'userprofile', name: 'USER PROFILE'},
  //   ]
  // },
  // {
  //   state: 'authentication',
  //   name: 'AUTHENTICATION',
  //   type: 'sub',
  //   icon: 'security',
  //   children: [
  //     {state: 'login', name: 'LOGIN'},
  //     {state: 'register', name: 'REGISTER'},
  //     {state: 'forgot-password', name: 'FORGOT'},
  //     {state: 'lockscreen', name: 'LOCKSCREEN'}
  //   ]
  // },
  // {
  //   state: 'error',
  //   name: 'ERROR',
  //   type: 'sub',
  //   icon: 'error_outline',
  //   children: [
  //     {state: '404', name: '404'},
  //     {state: '503', name: '503'}
  //   ]
  // },
  // {
  //   state: 'calendar',
  //   name: 'CALENDAR',
  //   type: 'link',
  //   icon: 'date_range'
  // },
  // {
  //   state: 'media',
  //   name: 'MEDIA',
  //   type: 'sub',
  //   icon: 'perm_media',
  //   children: [
  //     {state: 'grid', name: 'GRID'},
  //     {state: 'list', name: 'LIST'}
  //   ]
  // },
  // {
  //   state: 'editor',
  //   name: 'EDITOR',
  //   type: 'sub',
  //   icon: 'format_shapes',
  //   children: [
  //     {state: 'ace-editor', name: 'ACE EDITOR'},
  //     {state: 'ckeditor', name: 'CKEDITOR'},
  //     {state: 'wysiwyg', name: 'WYSIWYG EDITOR'}
  //   ]
  // }
  //--- END COMMENTED MENU ITMES ---
];

@Injectable()
export class MenuItems {
  getAll(isLoggedIn: boolean=false): Menu[] {
    if (isLoggedIn) {
      return MENUITEMS;
    }
    else {
      return
    }
    
  }
  add(menu: Menu) {
    MENUITEMS.push(menu);
  }

  loginHasPermissions(userPermissions: string): boolean {
    userPermissions = 'GodMode,' + userPermissions;

    var userGroups = sessionStorage.getItem('Gollum');
    var userGroupsList = userGroups.split(',');
    var userPermissionsList = userPermissions.split(',');
    var returnValue: boolean = false;

    userPermissionsList.forEach(userPermission => {
        userGroupsList.forEach(userGroup => {
            
            if (userPermission === userGroup) {
                returnValue = true;
            }
        });
        
    });

    return returnValue;
}
  // ----------------------------------------------------------------------------------------------
  // setup each high-level menu
  // ----------------------------------------------------------------------------------------------
  menuItem_VideoMenu(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions))
    { 
    this.add( 
      {
        state: 'video-menu',
        name: 'Demo Videos',
        type: 'sub',
        icon: 'ondemand_video',
        children: [
          {state: 'ussd-overview', name: 'USSD Overview'},
          {state: 'report-overview', name: 'Reports Overview'},
          {state: 'reading-sms', name: 'Reading SMS'},
          {state: 'result-summary', name: 'Result Summary'}
          // {state: 'con-call', name: 'Conference Call'}
        ]
      }
    );
    }
  }

  menuItem_BKashVideo(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions))
    { 
    this.add( 
      {
        state: 'video-menu',
        name: 'BKash Videos',
        type: 'sub',
        icon: 'ondemand_video',
        children: [
          {state: 'ussd-overview', name: 'USSD Overview'},
          {state: 'report-overview', name: 'Reports Overview'},
          {state: 'reading-sms', name: 'Reading SMS'},
          {state: 'result-summary', name: 'Result Summary'},
          {state: 'con-call', name: 'Conference Call'}
        ]
      }
    );
    }
  }

  menuItem_DemoMenu(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions))
    { 
    this.add( 
      {
        state: 'demo-menu',
        name: 'Documentation',
        type: 'sub',
        icon: 'pages',
        children: [
          {state: 'app-architecture', name: 'App Architecture'},
          {state: 'item-1', name: 'Configure Services'},
          {state: 'item-2', name: 'Service Tests'},
          {state: 'item-3', name: 'Transactions'}
        ]
      }
    );
    }
  }

  menuItem_CodeEngine(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions))
    { 
    this.add( {
        state: 'dev-utils',
        name: 'Code Engine',
        type: 'sub',
        icon: 'memory',
        children: [
          {state: 'global-jad', name: 'Global JAD'},
          {state: 'using-bitbucket', name: 'Using BitBucket'},
          {state: 'sql-workbench', name: 'SQL Workbench'},
          {state: 'angular-knowledgebase', name: 'Angualr Help'},
          {state: 'dev-utils-create', name: 'Create Magic'},
          {state: 'dev-utils-get', name: 'Get Magic'},
          {state: 'dev-utils-sandbox', name: 'Sandbox'}
        ]
      });
    }
  }

  menuItem_ConfigureDevice(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions))
    { 
    this.add( {
      state: 'mobile-device',
      name: 'Configure Devices',
      type: 'sub',
      // icon: 'perm_device_information',
      icon: 'sim_card',
      children: [
        {state: 'mobile-device-config', name: 'Configure Devices'},
  
        //{state: 'view-service', name: 'View Service'},
      ]
    });
    }
  }

  menuItem_TestCollection(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions))
    { 
    this.add( 
      {
        state: 'mobile-test-configure',
        name: 'Test Collection',
        type: 'sub',
        icon: 'local_laundry_service',
        children: [
          {state: 'mobile-test-free-form-testing', name: 'Record Test'},
        ]
      }
    );
    }
  }

  menuItem_ConfigureServices(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions))
    { 
    this.add( 
      {
        state: 'mobile-service',
        name: 'Configure Services',
        type: 'sub',
        icon: 'local_laundry_service',
        children: [
          {state: 'mobile-service-edit', name: 'Edit Service'},
          {state: 'mobile-service-step-edit', name: 'Edit Service Step'},
          {state: 'mobile-service-create', name: 'New Service'},
          {state: 'mobile-service-step-create', name: 'New Service Step'},
          //{state: 'view-service', name: 'View Service'},
        ]
      }
    );
    }
  }

  menuItem_MobileTestConfigure(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions))
    { 
    this.add( 
      {
        state: 'mobile-test-configure',
        name: 'Configure Tests - USSD',
        type: 'sub',
        icon: 'bug_report',
        children: [
          {state: 'mobile-test-edit',           name: 'Edit Negative Test Case'}
          ,{state: 'mobile-test-tran-edit',     name: 'Edit Test Chain'}
          ,{state: 'mobile-test-edit-grouped',  name: 'Edit Test Suite'}
          ,{state: 'mobile-test-create',        name: 'New Negative Tests Case'}
          ,{state: 'mobile-test-tran-create',   name: 'New Test Chain'}
          ,{state: 'mobile-test-create-grouped',name: 'New Test Suite'}
        ]
      }
    );
    }
  }

  menuItem_MobileTestUSSD(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions))
    { 
    this.add( 
      {
        state: 'mobile-test-ussd',
        name: ' Tests - USSD Device',
        type: 'sub',
        icon: 'bug_report',
        children: [
          {state: 'mobile-test-ussd-invoke-command',          name: 'Invoke USSD'}
          ,{state: 'mobile-test-ussd-run-tran-simple',        name: 'Run Test Case'}
          ,{state: 'mobile-test-ussd-run',                    name: 'Run Negative Test Case'}
          ,{state: 'mobile-test-ussd-run-tran',               name: 'Run Test Chain'}
          ,{state: 'mobile-test-ussd-run-grouped',            name: 'Run Test Suite'}
        ]
      }
    );
    }
  }

  menuItem_MobileTestUSSDApi(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions))
    { 
    this.add( 
      {
        state: 'mobile-test-ussd-api',
        name: ' Tests - USSD API',
        type: 'sub',
        icon: 'bug_report',
        children: [
          // {state: 'mobile-test-ussd-api-invoke-command',       name: 'Invoke USSD'}
          {state: 'mobile-test-ussd-api-run-tran-simple',         name: 'Run Test Case'}
          ,{state: 'mobile-test-ussd-api-run',                    name: 'Run Negative Test Case'}
          ,{state: 'mobile-test-ussd-api-run-tran',               name: 'Run Test Chain'}
          ,{state: 'mobile-test-ussd-api-run-grouped',            name: 'Run Test Suite'}
        ]
      }
    );
    }
  }

  menuItem_MobileTestSchedule(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions))
    { 
    this.add( 
      {
        state: 'mobile-test-schedule',
        name: 'Test Schedule',
        type: 'sub',
        // icon: 'bug_report',
        icon: 'query_builder',
        children: [
          {state: 'mobile-test-schedule-create',           name: 'Create Test Schedule'}
        ]
      }
    );
    }
  }

  menuItem_Reports(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions))
    { 
    this.add( 
      {
        state: 'mobile-test-report',
        name: 'Reports',
        type: 'sub',
        icon: 'assessment',
        children: [
          {state: 'report-24hr',  name: 'Past 24 Hours'},
          {state: 'report-userInvokedUSSD',  name: 'User Invoked USSD'},
          {state: 'report-simpletransactiontest',  name: 'Test Cases'},
          {state: 'report-servicetest',  name: 'Negative Test Cases'},
          {state: 'report-complextest',  name: 'Test Chains'},
          {state: 'report-groupedtest',  name: 'Test Suites'},
          {state: 'dashboard-live',  name: 'Live Dashboard'},
          {state: 'dashboard-account-balance',  name: 'Latest Balances'},
          {state: 'report-currentexecutions',  name: 'Current and Pending Tests'},
        ]
      }
    );
    }
  }

  menuItem_Dashboards(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions))
    { 
    this.add( 
      {
        state: 'mobile-dashboards',
        name: 'Dashboards',
        type: 'sub',
        icon: 'assessment',
        children: [
          {state: 'dashboard-mobile-test-positive-case-success-rate',  name: 'Positive Case - Success Rates'},
          {state: 'dashboard-mobile-test-positive-case-execution-time',  name: 'Positive Case - Execution Times'},
          {state: 'dashboard-mobile-test-negative-case-success-rate',  name: 'Negative Test - Success Rates'},
          {state: 'dashboard-mobile-test-negative-case-execution-time',  name: 'Negative Test - Execution Times'},
          {state: 'dashboard-mobile-test-test-chain-success-rate',  name: 'Test Chain - Success Rates'},
          {state: 'dashboard-mobile-test-test-chain-execution-time',  name: 'Test Chain - Execution Times'},
          {state: 'dashboard-mobile-test-test-suite-success-rate',  name: 'Test Suite - Success Rates'},
          {state: 'dashboard-mobile-test-test-suite-execution-time',  name: 'Test Suite - Execution Times'},

        ]
      }
    );
    }
  }


  // ///////////////////////////////////////////////////////////
  // /////////////////////// START OF BIG DATA /////////////////
  // ///////////////////////////////////////////////////////////

  menuItem_BigData(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions))
    { 
    this.add( 
      {
        state: 'hld',
        name: 'Big Data',
        type: 'sub',
        icon: 'business',
        children: [
          {state: 'requirements-analysis', name: 'Requirements'},
          {state: 'source-data-schema', name: 'Data Schema'},
          {state: 'high-level-design', name: 'High Level Design'},
          {state: 'low-level-design', name: 'Low Level Design'},
          {state: 'ingestion-relational-data', name: 'Ingest Relational Data'},
          {state: 'bulk-ingest-data', name: 'Bulk Ingest Data'},
          {state: 'hdfs-storage', name: 'HDFS Storage'}
        ]
      }
    );
    }
  }

  // Technology Stack
  menuItem_TechTalk(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions))
    { 
    this.add( 
      {
        state: 'tech-talk',
        name: 'Tech Talk',
        type: 'sub',
        icon: 'business',
        children: [
          {state: 'tech-talk', name: 'Tech Talk'}
        ]
      }
    );
    }
  }

  // Big Data Menu Items
  menuItem_HighAvailability(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions))
    { 
    this.add( 
      {
        state: 'hdfs',
        name: 'High Availability',
        type: 'sub',
        icon: 'business',
        children: [
          {state: 'HDFS', name: 'HDFS'},
          {state: 'Zookeeper', name: 'Zookeeper'},
          {state: 'YARN', name: 'YARN'},

        ]
      }
    );
    }
  }

  // Big Data Menu Items
  menuItem_NodeConfig(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions))
    { 
    this.add( 
      {
        state: 'node-config',
        name: 'Node Configuration',
        type: 'sub',
        icon: 'business',
        children: [
          {state: 'node-1', name: 'Master Node 1'},
          {state: 'node-2', name: 'Master Node 2'},
          {state: 'node-3', name: 'Master Node 3'},
          {state: 'node-4', name: 'Worker Node 1'},
          {state: 'node-5', name: 'Worker Node 2'},
          {state: 'node-6', name: 'Worker Node 3'},
          {state: 'node-7', name: 'Worker Node 4'},
          {state: 'node-8', name: 'Worker Node 5'},
          {state: 'node-9', name: 'Worker Node 6'},
          {state: 'node-10', name: 'Worker Node 7'},
          {state: 'node-11', name: 'Worker Node 8'},
          {state: 'node-12', name: 'Utility Node 1'},
          {state: 'node-13', name: 'Utility Node 2'},
          {state: 'node-14', name: 'Utility Node 3'},
          {state: 'node-15', name: 'GPU Node'},
          {state: 'node-16', name: 'Scylla Node 1'},
          {state: 'node-17', name: 'Scylla Node 2'},
          {state: 'node-18', name: 'Scylla Node 3'},

        ]
      }
    );
    }
  }

  // Big Data Menu Items
  menuItem_BigDataProdInstallDocs(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions))
    { 
    this.add( 
      {
        state: 'big-data-prod-install-docs',
        name: 'Production Install',
        type: 'sub',
        icon: 'business',
        children: [
          {state: 'cloudera-install', name: 'Cloudera Install'},
          {state: 'golden-gate-install', name: 'GoldenGate Install'},
          {state: 'golden-gate-big-data-install', name: 'OGG4BD Install'},
          // {state: 'grafana-install', name: 'Grafana Install'},
          {state: 'influx-db-install', name: 'InfluxDB Install'},
          {state: 'mysql-install', name: 'MySQL Install'},
          {state: 'neo4j-install', name: 'Neo4j Install'},
          {state: 'scylla-db-install', name: 'ScyllaDB Install'},
        ]
      }
    );
    }
  }

  // Big Data Menu Items
  menuItem_BigDataMasterDataManagement(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions))
    { 
    this.add( 
      {
        state: 'big-data-mdm',
        name: 'Master Data Management',
        type: 'sub',
        icon: 'business',
        children: [
          {state: 'mdm-item-1', name: 'MDM Objectives'},
          {state: 'mdm-item-2', name: 'Classification'},
          {state: 'mdm-item-3', name: 'Maturity Model'},
          {state: 'mdm-item-4', name: 'MDM Building Blocks'}
        ]
      }
    );
    }
  }

  // Big Data Menu Items
  menuItem_BigDataLambda(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions))
    { 
    this.add( 
      {
        state: 'big-data-lambda',
        name: 'Lambda Architecture',
        type: 'sub',
        icon: 'call_split',
        children: [
          {state: 'item-1', name: 'Lambda Architecture'},
          {state: 'item-2', name: 'Batch Layer'},
          {state: 'item-3', name: 'Speed Layer'},
          {state: 'item-4', name: 'Serving Layer'}
        ]
      }
    );
    }
  }

  // Big Data Menu Items
  menuItem_BigDataDataProcessing(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions))
    { 
    this.add( 
      {
        state: 'data-processing',
        name: 'Data Processing',
        type: 'sub',
        icon: 'call_split',
        children: [
          {state: 'data-processing-patterns', name: 'Data Processing Patterns'},

        ]
      }
    );
    }
  }

  menuItem_BigDataInfrastructure(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions))
    { 
    this.add( 
      {
        state: 'big-data-infrastructure',
        name: 'Infrastructure',
        type: 'sub',
        icon: 'location_city',
        children: [
          {state: 'development-env-setup', name: 'Development Setup'},
          {state: 'bill-of-materials', name: 'Bill of Materials'},
          //{state: 'network-lld', name: 'Network LLD'},
          {state: 'deployment-topology', name: 'Deployment Topology'},
          {state: 'cloudera-role-assignment', name: 'Role Assignment'},
          {state: 'goldengate', name: 'Install GoldenGate'},
          {state: 'goldengate-for-big-data', name: 'Install GoldenGate for Big Data'},
          {state: 'grafana', name: 'Install Grafana'},
          {state: 'influxdb', name: 'Install InfluxDB'},
          {state: 'mysql', name: 'Install MySQL'},
          {state: 'neo4j', name: 'Install Neo4j'},
          {state: 'scylla', name: 'Install Scylla'},
        ]
      }
    );
    }
  }

  

  menuItem_BigAdmin(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions))
    { 
    this.add( 
      {
        state: 'big-data-admin',
        name: 'Big Data Admin',
        type: 'sub',
        icon: 'person',
        children: [
          {state: 'big-data-timeline', name: 'Timelines'},
          {state: 'big-data-sow', name: 'SOW'}
        ]
      }
    );
    }
  }


  //////////////////////////////////////////////////////////////
  //////////////// START INGESTION STREAMS /////////////////////
  //////////////////////////////////////////////////////////////

  menuItem_SpeedRelationalIngestHdfs(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions))
    { 
    this.add( 
      {
        state: 'speed-relational-ingestion-hdfs',
        name: 'Oracle to HDFS (S)',
        type: 'sub',
        icon: 'storage',
        children: [
          {state: 'system-viewpoint', name: 'System Viewpoint'},
          {state: 'technology-viewpoint', name: 'Technology Viewpoint'},
          {state: 'building-blocks', name: 'Architecture Blocks'},
          {state: 'block-1', name: 'Building Block 1'},
          {state: 'block-2', name: 'Building Block 2'},
          {state: 'block-3', name: 'Building Block 3'},
          {state: 'block-4', name: 'Building Block 4'},
          {state: 'block-5', name: 'Building Block 5'},
          {state: 'block-6', name: 'Building Block 6'},
          {state: 'block-7', name: 'Building Block 7'},
          {state: 'block-8', name: 'Building Block 8'},
          {state: 'block-9', name: 'Building Block 9'},
          {state: 'hl-dfd', name: 'High Level DFD'},
          {state: 'dfd-1', name: 'DFD - Level 1'},
          {state: 'dfd-2', name: 'DFD - Level 2'},
          {state: 'dfd-3', name: 'DFD - Level 3'},
          {state: 'detailed-data-source', name: 'Detailed Data Source'}
        ]
      }
    );
    }
  }


  menuItem_BatchRelationalIngestHdfs(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions))
    { 
    this.add( 
      {
        state: 'batch-relational-ingestion-hdfs',
        name: 'Oracle to HDFS (B)',
        type: 'sub',
        icon: 'access_alarm',
        children: [
          {state: 'system-viewpoint', name: 'System Viewpoint'},
          {state: 'technology-viewpoint', name: 'Technology Viewpoint'},
          {state: 'building-blocks', name: 'Architecture Blocks'},
          {state: 'block-1', name: 'Building Block 1'},
          {state: 'block-2', name: 'Building Block 2'},
          {state: 'block-3', name: 'Building Block 3'},
          {state: 'block-4', name: 'Building Block 4'},
          {state: 'hl-dfd', name: 'High Level DFD'},
          {state: 'dfd-1', name: 'DFD - Level 1'},
          {state: 'dfd-2', name: 'DFD - Level 2'},
          {state: 'dfd-3', name: 'DFD - Level 3'},
          {state: 'detailed-data-source', name: 'Detailed Data Source'}
        ]
      }
    );
    }
  }

  menuItem_OracleToHive2B(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions))
    { 
    this.add( 
      {
        state: 'oracle-to-hive-b',
        name: 'Oracle to Hive2 (B)',
        type: 'sub',
        icon: 'access_alarm',
        children: [
          {state: 'system-viewpoint', name: 'System Viewpoint'},
          {state: 'technology-viewpoint', name: 'Technology Viewpoint'},
          {state: 'building-blocks', name: 'Architecture Blocks'},
          {state: 'block-1', name: 'Building Block 1'},
          {state: 'block-2', name: 'Building Block 2'},
          {state: 'block-3', name: 'Building Block 3'},
          {state: 'block-4', name: 'Building Block 4'},
          {state: 'block-5', name: 'Building Block 5'},
          {state: 'block-6', name: 'Building Block 6'},
          {state: 'hl-dfd', name: 'High Level DFD'},
          {state: 'dfd-1', name: 'DFD - Level 1'},
          {state: 'dfd-2', name: 'DFD - Level 2'},
          {state: 'dfd-3', name: 'DFD - Level 3'},
          {state: 'detailed-data-source', name: 'Detailed Data Source'}
        ]
      }
    );
    }
  }

  menuItem_OracleToGrafana(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions))
    { 
    this.add( 
      {
        state: 'oracle-to-grafana-s',
        name: 'Oracle to Grafana (S)',
        type: 'sub',
        icon: 'access_alarm',
        children: [
          {state: 'system-viewpoint', name: 'System Viewpoint'},
          {state: 'technology-viewpoint', name: 'Technology Viewpoint'},
          {state: 'building-blocks', name: 'Architecture Blocks'},
          {state: 'block-1', name: 'Building Block 1'},
          {state: 'block-2', name: 'Building Block 2'},
          {state: 'block-3', name: 'Building Block 3'},
          {state: 'block-4', name: 'Building Block 4'},
          {state: 'block-5', name: 'Building Block 5'},
          {state: 'block-6', name: 'Building Block 6'},
          {state: 'block-7', name: 'Building Block 7'},
          {state: 'block-8', name: 'Building Block 8'},
          {state: 'block-9', name: 'Building Block 9'},
          {state: 'block-10', name: 'Building Block 10'},
          {state: 'block-11', name: 'Building Block 11'},
          {state: 'block-12', name: 'Building Block 12'},
          {state: 'block-13', name: 'Building Block 13'},
          {state: 'hl-dfd', name: 'High Level DFD'},
          {state: 'dfd-1', name: 'DFD - Level 1'},
          {state: 'dfd-2', name: 'DFD - Level 2'},
          {state: 'dfd-3', name: 'DFD - Level 3'},
          {state: 'detailed-data-source', name: 'Detailed Data Source'}
        ]
      }
    );
    }
  }


  menuItem_StreamsToHDFSS(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions))
    { 
    this.add( 
      {
        state: 'streams-to-hdfs-s',
        name: 'Streams to HDFS (S)',
        type: 'sub',
        icon: 'blur_linear',
        children: [
          {state: 'system-viewpoint', name: 'System Viewpoint'},
          {state: 'technology-viewpoint', name: 'Technology Viewpoint'},
          {state: 'building-blocks', name: 'Architecture Blocks'},
          {state: 'block-1', name: 'Building Block 1'},
          {state: 'block-2', name: 'Building Block 2'},
          {state: 'block-3', name: 'Building Block 3'},
          {state: 'block-4', name: 'Building Block 4'},
          {state: 'block-5', name: 'Building Block 5'},
          {state: 'block-6', name: 'Building Block 6'},
          {state: 'block-7', name: 'Building Block 7'},
          // {state: 'block-8', name: 'Building Block 8'},
          // {state: 'block-9', name: 'Building Block 9'},
          {state: 'hl-dfd', name: 'High Level DFD'},
          {state: 'dfd-1', name: 'DFD - Level 1'},
          {state: 'dfd-2', name: 'DFD - Level 2'},
          {state: 'dfd-3', name: 'DFD - Level 3'},
          {state: 'detailed-data-source', name: 'Detailed Data Source'}
        ]
      }
    );
    }
  }

  menuItem_FilesToHDFSB(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions))
    { 
    this.add( 
      {
        state: 'files-to-hdfs-b',
        name: 'Files to HDFS (B)',
        type: 'sub',
        icon: 'insert_drive_file',
        children: [
          {state: 'system-viewpoint', name: 'System Viewpoint'},
          {state: 'technology-viewpoint', name: 'Technology Viewpoint'},
          {state: 'building-blocks', name: 'Architecture Blocks'},
          {state: 'block-1', name: 'Building Block 1'},
          {state: 'block-2', name: 'Building Block 2'},
          {state: 'block-3', name: 'Building Block 3'},
          {state: 'block-4', name: 'Building Block 4'},
          // {state: 'block-5', name: 'Building Block 5'},
          // {state: 'block-6', name: 'Building Block 6'},
          // {state: 'block-7', name: 'Building Block 7'},
          // {state: 'block-8', name: 'Building Block 8'},
          // {state: 'block-9', name: 'Building Block 9'},
          {state: 'hl-dfd', name: 'High Level DFD'},
          {state: 'dfd-1', name: 'DFD - Level 1'},
          {state: 'dfd-2', name: 'DFD - Level 2'},
          {state: 'dfd-3', name: 'DFD - Level 3'},
          {state: 'detailed-data-source', name: 'Detailed Data Source'}
        ]
      }
    );
    }
  }

  menuItem_OracleToScyllaS(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions))
    { 
    this.add( 
      {
        state: 'oracle-to-scylla-s',
        name: 'Oracle to Scylla (S)',
        type: 'sub',
        icon: 'radio_button_checked',
        children: [
          {state: 'system-viewpoint', name: 'System Viewpoint'},
          {state: 'technology-viewpoint', name: 'Technology Viewpoint'},
          {state: 'building-blocks', name: 'Architecture Blocks'},
          {state: 'block-1', name: 'Building Block 1'},
          {state: 'block-2', name: 'Building Block 2'},
          {state: 'block-3', name: 'Building Block 3'},
          {state: 'block-4', name: 'Building Block 4'},
          {state: 'block-5', name: 'Building Block 5'},
          {state: 'block-6', name: 'Building Block 6'},
          {state: 'block-7', name: 'Building Block 7'},
          {state: 'block-8', name: 'Building Block 8'},
          {state: 'block-9', name: 'Building Block 9'},
          {state: 'hl-dfd', name: 'High Level DFD'},
          {state: 'dfd-1', name: 'DFD - Level 1'},
          {state: 'dfd-2', name: 'DFD - Level 2'},
          {state: 'dfd-3', name: 'DFD - Level 3'},
          {state: 'detailed-data-source', name: 'Detailed Data Source'}
        ]
      }
    );
    }
  }

  menuItem_OracleToRedisS(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions))
    { 
    this.add( 
      {
        state: 'oracle-to-redis-s',
        name: 'Oracle to Redis (S)',
        type: 'sub',
        icon: 'select_all',
        children: [
          {state: 'system-viewpoint', name: 'System Viewpoint'},
          {state: 'technology-viewpoint', name: 'Technology Viewpoint'},
          {state: 'building-blocks', name: 'Architecture Blocks'},
          {state: 'block-1', name: 'Building Block 1'},
          {state: 'block-2', name: 'Building Block 2'},
          {state: 'block-3', name: 'Building Block 3'},
          {state: 'block-4', name: 'Building Block 4'},
          {state: 'block-5', name: 'Building Block 5'},
          {state: 'block-6', name: 'Building Block 6'},
          {state: 'block-7', name: 'Building Block 7'},
          {state: 'block-8', name: 'Building Block 8'},
          {state: 'block-9', name: 'Building Block 9'},
          {state: 'hl-dfd', name: 'High Level DFD'},
          {state: 'dfd-1', name: 'DFD - Level 1'},
          {state: 'dfd-2', name: 'DFD - Level 2'},
          {state: 'dfd-3', name: 'DFD - Level 3'},
          {state: 'detailed-data-source', name: 'Detailed Data Source'}
        ]
      }
    );
    }
  }

  menuItem_OracleToNeo4JS(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions))
    { 
    this.add( 
      {
        state: 'oracle-to-neo4j-s',
        name: 'Oracle to Neo4j (S)',
        type: 'sub',
        icon: 'settings_input_antenna',
        children: [
          {state: 'system-viewpoint', name: 'System Viewpoint'},
          {state: 'technology-viewpoint', name: 'Technology Viewpoint'},
          {state: 'building-blocks', name: 'Architecture Blocks'},
          {state: 'block-1', name: 'Building Block 1'},
          {state: 'block-2', name: 'Building Block 2'},
          {state: 'block-3', name: 'Building Block 3'},
          {state: 'block-4', name: 'Building Block 4'},
          {state: 'block-5', name: 'Building Block 5'},
          {state: 'block-6', name: 'Building Block 6'},
          {state: 'block-7', name: 'Building Block 7'},
          {state: 'block-8', name: 'Building Block 8'},
          {state: 'block-9', name: 'Building Block 9'},
          {state: 'hl-dfd', name: 'High Level DFD'},
          {state: 'dfd-1', name: 'DFD - Level 1'},
          {state: 'dfd-2', name: 'DFD - Level 2'},
          {state: 'dfd-3', name: 'DFD - Level 3'},
          {state: 'detailed-data-source', name: 'Detailed Data Source'}
        ]
      }
    );
    }
  }



  menuItem_OracleToInfluxDBS(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions))
    { 
    this.add( 
      {
        state: 'oracle-to-influxdb-s',
        name: 'Oracle to Influx (S)',
        type: 'sub',
        icon: 'settings_input_antenna',
        children: [
          {state: 'system-viewpoint', name: 'System Viewpoint'},
          {state: 'technology-viewpoint', name: 'Technology Viewpoint'},
          {state: 'building-blocks', name: 'Architecture Blocks'},
          {state: 'block-1', name: 'Building Block 1'},
          {state: 'block-2', name: 'Building Block 2'},
          {state: 'block-3', name: 'Building Block 3'},
          {state: 'block-4', name: 'Building Block 4'},
          {state: 'block-5', name: 'Building Block 5'},
          {state: 'block-6', name: 'Building Block 6'},
          {state: 'block-7', name: 'Building Block 7'},
          {state: 'block-8', name: 'Building Block 8'},
          {state: 'block-9', name: 'Building Block 9'},
          {state: 'block-10', name: 'Building Block 10'},
          {state: 'hl-dfd', name: 'High Level DFD'},
          {state: 'dfd-1', name: 'DFD - Level 1'},
          {state: 'dfd-2', name: 'DFD - Level 2'},
          {state: 'dfd-3', name: 'DFD - Level 3'},
          {state: 'detailed-data-source', name: 'Detailed Data Source'}
        ]
      }
    );
    }
  }

  menuItem_StreamsToInfluxDBS(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions))
    { 
    this.add( 
      {
        state: 'streams-to-influxdb-s',
        name: 'Streams to Influx (S)',
        type: 'sub',
        icon: 'settings_input_antenna',
        children: [
          {state: 'system-viewpoint', name: 'System Viewpoint'},
          {state: 'technology-viewpoint', name: 'Technology Viewpoint'},
          {state: 'building-blocks', name: 'Architecture Blocks'},
          {state: 'block-1', name: 'Building Block 1'},
          {state: 'block-2', name: 'Building Block 2'},
          {state: 'block-3', name: 'Building Block 3'},
          {state: 'block-4', name: 'Building Block 4'},
          {state: 'block-5', name: 'Building Block 5'},
          {state: 'block-6', name: 'Building Block 6'},
          {state: 'block-7', name: 'Building Block 7'},
          // {state: 'block-8', name: 'Building Block 8'},
          // {state: 'block-9', name: 'Building Block 9'},
          // {state: 'block-10', name: 'Building Block 10'},
          {state: 'hl-dfd', name: 'High Level DFD'},
          {state: 'dfd-1', name: 'DFD - Level 1'},
          {state: 'dfd-2', name: 'DFD - Level 2'},
          {state: 'dfd-3', name: 'DFD - Level 3'},
          {state: 'detailed-data-source', name: 'Detailed Data Source'}
        ]
      }
    );
    }
  }


  menuItem_StreamsToGrafanaS(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions))
    { 
    this.add( 
      {
        state: 'streams-to-grafana-s',
        name: 'Streams to Grafana (S)',
        type: 'sub',
        icon: 'settings_input_antenna',
        children: [
          {state: 'system-viewpoint', name: 'System Viewpoint'},
          {state: 'technology-viewpoint', name: 'Technology Viewpoint'},
          {state: 'building-blocks', name: 'Architecture Blocks'},
          {state: 'block-1', name: 'Building Block 1'},
          {state: 'block-2', name: 'Building Block 2'},
          {state: 'block-3', name: 'Building Block 3'},
          {state: 'block-4', name: 'Building Block 4'},
          {state: 'block-5', name: 'Building Block 5'},
          {state: 'block-6', name: 'Building Block 6'},
          {state: 'block-7', name: 'Building Block 7'},
          {state: 'block-8', name: 'Building Block 8'},
          {state: 'block-9', name: 'Building Block 9'},
          {state: 'block-10', name: 'Building Block 10'},
          {state: 'hl-dfd', name: 'High Level DFD'},
          {state: 'dfd-1', name: 'DFD - Level 1'},
          {state: 'dfd-2', name: 'DFD - Level 2'},
          {state: 'dfd-3', name: 'DFD - Level 3'},
          {state: 'detailed-data-source', name: 'Detailed Data Source'}
        ]
      }
    );
    }
  }

  menuItem_StreamsToScyllaS(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions))
    { 
    this.add( 
      {
        state: 'streams-to-scylla-s',
        name: 'Streams to Scylla (S)',
        type: 'sub',
        icon: 'sort',
        children: [
          {state: 'system-viewpoint', name: 'System Viewpoint'},
          {state: 'technology-viewpoint', name: 'Technology Viewpoint'},
          {state: 'building-blocks', name: 'Architecture Blocks'},
          {state: 'block-1', name: 'Building Block 1'},
          {state: 'block-2', name: 'Building Block 2'},
          {state: 'block-3', name: 'Building Block 3'},
          {state: 'block-4', name: 'Building Block 4'},
          {state: 'block-5', name: 'Building Block 5'},
          {state: 'block-6', name: 'Building Block 6'},
          {state: 'block-7', name: 'Building Block 7'},
          // {state: 'block-8', name: 'Building Block 8'},
          // {state: 'block-9', name: 'Building Block 9'},
          {state: 'hl-dfd', name: 'High Level DFD'},
          {state: 'dfd-1', name: 'DFD - Level 1'},
          {state: 'dfd-2', name: 'DFD - Level 2'},
          {state: 'dfd-3', name: 'DFD - Level 3'},
          {state: 'detailed-data-source', name: 'Detailed Data Source'}
        ]
      }
    );
    }
  }

  menuItem_StreamsToRedisS(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions))
    { 
    this.add( 
      {
        state: 'streams-to-redis-s',
        name: 'Streams to Redis (S)',
        type: 'sub',
        icon: 'surround_sound',
        children: [
          {state: 'system-viewpoint', name: 'System Viewpoint'},
          {state: 'technology-viewpoint', name: 'Technology Viewpoint'},
          {state: 'building-blocks', name: 'Architecture Blocks'},
          {state: 'block-1', name: 'Building Block 1'},
          {state: 'block-2', name: 'Building Block 2'},
          {state: 'block-3', name: 'Building Block 3'},
          {state: 'block-4', name: 'Building Block 4'},
          {state: 'block-5', name: 'Building Block 5'},
          {state: 'block-6', name: 'Building Block 6'},
          {state: 'block-7', name: 'Building Block 7'},
          // {state: 'block-8', name: 'Building Block 8'},
          // {state: 'block-9', name: 'Building Block 9'},
          {state: 'hl-dfd', name: 'High Level DFD'},
          {state: 'dfd-1', name: 'DFD - Level 1'},
          {state: 'dfd-2', name: 'DFD - Level 2'},
          {state: 'dfd-3', name: 'DFD - Level 3'},
          {state: 'detailed-data-source', name: 'Detailed Data Source'}
        ]
      }
    );
    }
  }

  menuItem_StreamsToNeo4JS(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions))
    { 
    this.add( 
      {
        state: 'streams-to-neo4j-s',
        name: 'Streams to Neo4j (S)',
        type: 'sub',
        icon: 'texture',
        children: [
          {state: 'system-viewpoint', name: 'System Viewpoint'},
          {state: 'technology-viewpoint', name: 'Technology Viewpoint'},
          {state: 'building-blocks', name: 'Architecture Blocks'},
          {state: 'block-1', name: 'Building Block 1'},
          {state: 'block-2', name: 'Building Block 2'},
          {state: 'block-3', name: 'Building Block 3'},
          {state: 'block-4', name: 'Building Block 4'},
          {state: 'block-5', name: 'Building Block 5'},
          {state: 'block-6', name: 'Building Block 6'},
          {state: 'block-7', name: 'Building Block 7'},
          // {state: 'block-8', name: 'Building Block 8'},
          // {state: 'block-9', name: 'Building Block 9'},
          {state: 'hl-dfd', name: 'High Level DFD'},
          {state: 'dfd-1', name: 'DFD - Level 1'},
          {state: 'dfd-2', name: 'DFD - Level 2'},
          {state: 'dfd-3', name: 'DFD - Level 3'},
          {state: 'detailed-data-source', name: 'Detailed Data Source'}
        ]
      }
    );
    }
  }

  menuItem_LogsToHDFSB(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions))
    { 
    this.add( 
      {
        state: 'logs-to-hdfs-b',
        name: 'Logs to HDFS (B)',
        type: 'sub',
        icon: 'clear_all',
        children: [
          {state: 'system-viewpoint', name: 'System Viewpoint'},
          {state: 'technology-viewpoint', name: 'Technology Viewpoint'},
          {state: 'building-blocks', name: 'Architecture Blocks'},
          {state: 'block-1', name: 'Building Block 1'},
          {state: 'block-2', name: 'Building Block 2'},
          {state: 'block-3', name: 'Building Block 3'},
          {state: 'block-4', name: 'Building Block 4'},
          // {state: 'block-5', name: 'Building Block 5'},
          // {state: 'block-6', name: 'Building Block 6'},
          // {state: 'block-7', name: 'Building Block 7'},
          // {state: 'block-8', name: 'Building Block 8'},
          // {state: 'block-9', name: 'Building Block 9'},
          {state: 'hl-dfd', name: 'High Level DFD'},
          {state: 'dfd-1', name: 'DFD - Level 1'},
          {state: 'dfd-2', name: 'DFD - Level 2'},
          {state: 'dfd-3', name: 'DFD - Level 3'},
          {state: 'detailed-data-source', name: 'Detailed Data Source'}
        ]
      }
    );
    }
  }

  //Fraud
  menuItem_FraudConfig(userPermissions: string) {
    if (this.loginHasPermissions(userPermissions))
    { 
    this.add( 
      {
        state: 'fraud-config',
        name: 'Fraud Config',
        type: 'sub',
        icon: 'clear_all',
        children: [
          {state: 'fraud-config', name: 'Fraud Config'},
          {state: 'fraud-network', name: 'Fraud Network'},
          {state: 'user-profile', name: 'User Profile'}
        ]
      }
    );
    }
  }

  

  // ----------------------------------------------------------------------------------------------
  // Grant permissions to each menu item
  // ----------------------------------------------------------------------------------------------
  loadMenuItems() {
    this.menuItem_FraudConfig('inquest_testautomation_administrator, Fraud');
    this.menuItem_VideoMenu('USSDDemo');
    this.menuItem_DemoMenu('USSDDemo');
    this.menuItem_BKashVideo('BkashDemo');
    this.menuItem_CodeEngine('inquest_testautomation_administrator');
    
    this.menuItem_ConfigureDevice('inquest_testautomation_administrator,inquest_testautomation_poweruser');
    this.menuItem_TestCollection('inquest_testautomation_administrator,inquest_testautomation_poweruser');
    this.menuItem_ConfigureServices('inquest_testautomation_administrator,inquest_testautomation_poweruser');
    this.menuItem_MobileTestConfigure('inquest_testautomation_administrator,inquest_testautomation_poweruser');
    this.menuItem_MobileTestUSSD('inquest_testautomation_administrator,inquest_testautomation_poweruser');
    this.menuItem_MobileTestUSSDApi('inquest_testautomation_administrator,inquest_testautomation_poweruser');
    this.menuItem_MobileTestSchedule('inquest_testautomation_administrator,inquest_testautomation_poweruser');
    this.menuItem_Reports('inquest_testautomation_administrator,inquest_testautomation_poweruser,ReportManager');
    this.menuItem_Dashboards('inquest_testautomation_administrator,inquest_testautomation_poweruser,ReportManager');

    // Big Data Menu Items
    this.menuItem_BigDataLambda('inquest_testautomation_administrator,BigData');
    this.menuItem_BigDataDataProcessing('inquest_testautomation_administrator,BigData');

    this.menuItem_OracleToGrafana('inquest_testautomation_administrator,BigData');
    this.menuItem_SpeedRelationalIngestHdfs('inquest_testautomation_administrator,BigData'); // Oracle to HDFS (S)
    this.menuItem_OracleToHive2B('inquest_testautomation_administrator,BigData');            // streams to InfluxDB (S)
    this.menuItem_OracleToInfluxDBS('inquest_testautomation_administrator,BigData');         // Oracle to InfluxDB (S)
    this.menuItem_OracleToNeo4JS('inquest_testautomation_administrator,BigData');
    this.menuItem_OracleToRedisS('inquest_testautomation_administrator,BigData');
    this.menuItem_OracleToScyllaS('inquest_testautomation_administrator,BigData');

    this.menuItem_StreamsToGrafanaS('inquest_testautomation_administrator,BigData');         // streams to InfluxDB (S)
    this.menuItem_StreamsToHDFSS('inquest_testautomation_administrator,BigData');            // Streams to HDFS (S)
    this.menuItem_StreamsToInfluxDBS('inquest_testautomation_administrator,BigData');         // streams to InfluxDB (S)
    this.menuItem_StreamsToNeo4JS('inquest_testautomation_administrator,BigData');
    this.menuItem_StreamsToRedisS('inquest_testautomation_administrator,BigData');
    this.menuItem_StreamsToScyllaS('inquest_testautomation_administrator,BigData');

    this.menuItem_BatchRelationalIngestHdfs('inquest_testautomation_administrator,BigData'); // Oracle to DHFS (B)
    this.menuItem_FilesToHDFSB('inquest_testautomation_administrator,BigData');
    this.menuItem_LogsToHDFSB('inquest_testautomation_administrator,BigData');

    this.menuItem_TechTalk('inquest_testautomation_administrator,BigData'); //Technology Stack

    this.menuItem_BigData('inquest_testautomation_administrator,BigData');
    this.menuItem_HighAvailability('inquest_testautomation_administrator,BigData');
    this.menuItem_NodeConfig('inquest_testautomation_administrator,BigData');
    this.menuItem_BigDataProdInstallDocs('inquest_testautomation_administrator,BigData');   
    this.menuItem_BigDataMasterDataManagement('inquest_testautomation_administrator,BigData');   
    this.menuItem_BigDataInfrastructure('inquest_testautomation_administrator,BigData');
    this.menuItem_BigAdmin('');
  }

}
